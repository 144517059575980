import img3 from "../pages/TestPage/img/img-3.png";

const allCareers = [
  {
    img: img3,
    jobTitle: "Accountants & Auditors",
    jobTitleAlternatives: ["Accountant", "Auditor", "Certified Public Accountant (CPA)", "Financial Auditor"],
    educationRequired: "Master's Degree",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical"],
    salary: "$78,000",
  },
  {
    img: img3,
    jobTitle: "Actors",
    jobTitleAlternatives: ["Actor", "Actress", "Comedian", "Performer"],
    educationRequired: "No High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Media & Communication", "Arts & Entertainment", "Education"],
    salary: "$17.94",
  },
  {
    img: img3,
    jobTitle: "Actuaries",
    jobTitleAlternatives: ["Actuarial Analyst", "Actuary", "Pricing Actuary", "Product Development Actuary"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Finance & Insurance", "Professional, Science, & Technical"],
    salary: "$113,990",
  },
  {
    img: img3,
    jobTitle: "Acupuncturists",
    jobTitleAlternatives: ["Acupuncture Physician", "Acupuncturist", "Herbalist", "Licensed Acupuncturist (LAC)"],
    educationRequired: "Master's Degree",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling"],
    salary: "$72,220",
  },
  {
    img: img3,
    jobTitle: "Acute Care Nurses",
    jobTitleAlternatives: [
      "Cardiac Interventional Care Nurse",
      "Charge Nurse",
      "Progressive Care Unit RN (Progressive Care Unit Registered Nurse)",
      "Staff Nurse",
    ],
    educationRequired: "Associate's Degree",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling"],
    salary: "$81,220",
  },
  {
    img: img3,
    jobTitle: "Adapted Physical Education Specialists",
    jobTitleAlternatives: [
      "Adapted Physical Education Specialist (APE Specialist)",
      "Adapted Physical Education Teacher (Adapted PE Teacher)",
      "Adapted Physical Educator",
      "DAPE Specialist (Developmental Adapted Physical Education Specialist)",
    ],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Education"],
    salary: "$63,950",
  },
  {
    img: img3,
    jobTitle: "Adhesive Bonding Machine Operators & Tenders",
    jobTitleAlternatives: ["Coater Operator", "Glue Line Operator", "Machine Operator", "Utility Worker"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing"],
    salary: "$38,780",
  },
  {
    img: img3,
    jobTitle: "Administrative Law Judges, Adjudicators, & Hearing Officers",
    jobTitleAlternatives: ["Administrative Hearings Officer", "Administrative Judge", "Administrative Law Judge", "Hearings Officer"],
    educationRequired: "Doctoral Degree",
    jobOutlook: "Salary:",
    industry: ["Government"],
    salary: "$94,990",
  },
  {
    img: img3,
    jobTitle: "Administrative Services Managers",
    jobTitleAlternatives: ["Administrative Coordinator", "Administrative Manager", "Administrative Officer", "Business Administrator"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Government", "Education", "Professional, Science, & Technical", "Health & Counseling"],
    salary: "$103,330",
  },
  {
    img: img3,
    jobTitle: "Adult Basic Education, Adult Secondary Education, & English as a Second Language Instructors",
    jobTitleAlternatives: [
      "Adult Basic Education Instructor (ABE Instructor)",
      "ESL Instructor (English as a Second Language Instructor)",
      "GED Instructor (General Educational Development Instructor)",
      "Teacher",
    ],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Education"],
    salary: "$58,590",
  },
  {
    img: img3,
    jobTitle: "Advanced Practice Psychiatric Nurses",
    jobTitleAlternatives: [
      "Advanced Practice Nurse Psychotherapist (APN Psychotherapist)",
      "Advanced Practice Psychiatric Nurse",
      "Psychiatric Clinical Nurse Specialist",
      "Psychiatric Mental Health Nurse (PMH Nurse)",
    ],
    educationRequired: "Master's Degree",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling"],
    salary: "$81,220",
  },
  {
    img: img3,
    jobTitle: "Advertising & Promotions Managers",
    jobTitleAlternatives: ["Advertising Manager (Ad Manager)", "Communications Manager", "Promotions Director", "Promotions Manager"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Media & Communication"],
    salary: "$127,830",
  },
  {
    img: img3,
    jobTitle: "Advertising Sales Agents",
    jobTitleAlternatives: [
      "Advertising Account Representative",
      "Advertising Representative",
      "Advertising Sales Representative (Ad Sales Representative)",
      "Sales Representative",
    ],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Media & Communication", "Professional, Science, & Technical"],
    salary: "$58,450",
  },
  {
    img: img3,
    jobTitle: "Aerospace Engineering & Operations Technologists & Technicians",
    jobTitleAlternatives: ["Avionics Technician", "Engineering Technician", "Instrumentation Technician", "Test Technician"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing", "Professional, Science, & Technical"],
    salary: "$74,410",
  },
  {
    img: img3,
    jobTitle: "Aerospace Engineers",
    jobTitleAlternatives: ["Aerospace Engineer", "Design Engineer", "Flight Test Engineer", "Systems Engineer"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Manufacturing", "Professional, Science, & Technical", "Government"],
    salary: "$126,880",
  },
  {
    img: img3,
    jobTitle: "Agents & Business Managers of Artists, Performers, & Athletes",
    jobTitleAlternatives: ["Agent", "Booking Agent", "Talent Agent", "Theatrical Agent"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Arts & Entertainment"],
    salary: "$82,530",
  },
  {
    img: img3,
    jobTitle: "Agricultural Engineers",
    jobTitleAlternatives: ["Agricultural Engineer", "Engineer", "Project Engineer", "Research Agricultural Engineer"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Government", "Professional, Science, & Technical", "Manufacturing", "Education"],
    salary: "$83,260",
  },
  {
    img: img3,
    jobTitle: "Agricultural Equipment Operators",
    jobTitleAlternatives: ["Equipment Operator", "Farm Equipment Operator", "Loader Operator", "Rake Operator"],
    educationRequired: "No High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Farming, Forestry, Fishing, & Hunting", "Wholesale/Commercial Sales"],
    salary: "$37,780",
  },
  {
    img: img3,
    jobTitle: "Agricultural Inspectors",
    jobTitleAlternatives: ["Brand Inspector", "Consumer Safety Inspector (CSI)", "Grain Inspector", "Inspector"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Government", "Manufacturing"],
    salary: "$44,720",
  },
  {
    img: img3,
    jobTitle: "Agricultural Sciences Teachers, Postsecondary",
    jobTitleAlternatives: ["Agriculture Professor", "Associate Professor", "Instructor", "Professor"],
    educationRequired: "Doctoral Degree",
    jobOutlook: "Salary:",
    industry: ["Education"],
    salary: "$85,860",
  },
  {
    img: img3,
    jobTitle: "Agricultural Technicians",
    jobTitleAlternatives: ["Agricultural Research Technician", "Laboratory Technician (Lab Tech)", "Research Associate", "Seed Analyst"],
    educationRequired: "Master's Degree",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Education", "Farming, Forestry, Fishing, & Hunting"],
    salary: "$41,760",
  },
  {
    img: img3,
    jobTitle: "Air Traffic Controllers",
    jobTitleAlternatives: [
      "Air Traffic Control Specialist (ATCS)",
      "Air Traffic Controller (ATC)",
      "Certified Professional Controller (CPC)",
      "Enroute Air Traffic Controller (Enroute ATC)",
    ],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Government"],
    salary: "$132,250",
  },
  {
    img: img3,
    jobTitle: "Aircraft Cargo Handling Supervisors",
    jobTitleAlternatives: ["Cargo Supervisor", "Line Service Supervisor (LSS)", "Loadmaster", "Ramp Supervisor"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Transportation & Storage"],
    salary: "$53,490",
  },
  {
    img: img3,
    jobTitle: "Aircraft Mechanics & Service Technicians",
    jobTitleAlternatives: ["Aircraft Mechanic", "Aircraft Technician", "Airframe and Powerplant Mechanic (A and P Mechanic)", "Aviation Mechanic"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Transportation & Storage", "Manufacturing", "Government"],
    salary: "$70,010",
  },
  {
    img: img3,
    jobTitle: "Aircraft Service Attendants",
    jobTitleAlternatives: [[]],
    educationRequired: "Associate's Degree",
    jobOutlook: "Salary:",
    industry: ["Transportation & Storage"],
    salary: "$37,050",
  },
  {
    img: img3,
    jobTitle: "Aircraft Structure, Surfaces, Rigging, & Systems Assemblers",
    jobTitleAlternatives: ["Assembler", "Sheet Metal Assembler and Riveter (SMAR)", "Sheet Metal Mechanic", "Structures Technician"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing"],
    salary: "$58,430",
  },
  {
    img: img3,
    jobTitle: "Airfield Operations Specialists",
    jobTitleAlternatives: [
      "Airport Operations Agent",
      "Airport Operations Coordinator",
      "Airport Operations Officer",
      "Airport Operations Specialist",
    ],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Transportation & Storage", "Government"],
    salary: "$49,600",
  },
  {
    img: img3,
    jobTitle: "Airline Pilots, Copilots, & Flight Engineers",
    jobTitleAlternatives: ["Airline Captain", "Captain", "First Officer", "Pilot"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Transportation & Storage"],
    salary: "$211,790",
  },
  {
    img: img3,
    jobTitle: "Allergists & Immunologists",
    jobTitleAlternatives: ["Allergist", "Immunologist", "MD (Medical Doctor)", "Physician"],
    educationRequired: "Doctoral Degree",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling", "Government"],
    salary: "$223,410",
  },
  {
    img: img3,
    jobTitle: "Ambulance Drivers & Attendants",
    jobTitleAlternatives: ["Ambulance Driver", "Driver", "EMS Driver (Emergency Medical Services Driver)", "First Responder"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling", "Government", "Transportation & Storage"],
    salary: "$30,380",
  },
  {
    img: img3,
    jobTitle: "Amusement & Recreation Attendants",
    jobTitleAlternatives: ["Golf Course Ranger", "Recreation Attendant", "Ride Operator", "Ski Lift Operator"],
    educationRequired: "No High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Arts & Entertainment", "Government"],
    salary: "$27,780",
  },
  {
    img: img3,
    jobTitle: "Anesthesiologist Assistants",
    jobTitleAlternatives: ["Anesthesia Assistant", "Anesthesia Technician", "Anesthesiologist Assistant", "Certified Anesthesiologist Assistant"],
    educationRequired: "Master's Degree",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling"],
    salary: "$126,010",
  },
  {
    img: img3,
    jobTitle: "Anesthesiologists",
    jobTitleAlternatives: ["Medical Doctor (MD)", "Obstetrical Anesthesiologist", "Staff Anesthesiologist", "Staff Anesthetist"],
    educationRequired: "Doctoral Degree",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling"],
    salary: "$239,200+",
  },
  {
    img: img3,
    jobTitle: "Animal Breeders",
    jobTitleAlternatives: ["Animal Technician", "Artificial Insemination Technician (AI Technician)", "Breeder", "Dog Breeder"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Farming, Forestry, Fishing, & Hunting"],
    salary: "$45,320",
  },
  {
    img: img3,
    jobTitle: "Animal Caretakers",
    jobTitleAlternatives: ["Aquarist", "Dog Groomer", "Groomer", "Kennel Technician (Kennel Tech)"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Service", "Retail", "Professional, Science, & Technical"],
    salary: "$29,530",
  },
  {
    img: img3,
    jobTitle: "Animal Control Workers",
    jobTitleAlternatives: [
      "Animal Control Officer",
      "Animal Enforcement Officer",
      "Animal Park Code Enforcement Officer",
      "Community Service Officer",
    ],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Government"],
    salary: "$39,320",
  },
  {
    img: img3,
    jobTitle: "Animal Scientists",
    jobTitleAlternatives: ["Animal Nutritionist", "Animal Scientist", "Beef Cattle Nutritionist", "Research Scientist"],
    educationRequired: "Doctoral Degree",
    jobOutlook: "Salary:",
    industry: [
      "Education",
      "Professional, Science, & Technical",
      "Farming, Forestry, Fishing, & Hunting",
      "Manufacturing",
      "Wholesale/Commercial Sales",
    ],
    salary: "$69,390",
  },
  {
    img: img3,
    jobTitle: "Animal Trainers",
    jobTitleAlternatives: ["Dog Trainer", "Guide Dog Mobility Instructor (GDMI)", "Horse Trainer", "Trainer"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Farming, Forestry, Fishing, & Hunting", "Service"],
    salary: "$35,620",
  },
  {
    img: img3,
    jobTitle: "Anthropologists & Archeologists",
    jobTitleAlternatives: ["Applied Cultural Anthropologist", "Archaeologist", "Forensic Anthropologist", "Researcher"],
    educationRequired: "Master's Degree",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Government"],
    salary: "$63,940",
  },
  {
    img: img3,
    jobTitle: "Anthropology & Archeology Teachers, Postsecondary",
    jobTitleAlternatives: ["Anthropology Professor", "Assistant Professor", "Associate Professor", "Professor"],
    educationRequired: "Doctoral Degree",
    jobOutlook: "Salary:",
    industry: ["Education"],
    salary: "$85,000",
  },
  {
    img: img3,
    jobTitle: "Appraisers & Assessors of Real Estate",
    jobTitleAlternatives: ["Appraiser", "Assessor", "Real Estate Appraiser", "Valuation Consultant"],
    educationRequired: "Master's Degree",
    jobOutlook: "Salary:",
    industry: ["Government", "Real Estate & Rentals"],
    salary: "$61,560",
  },
  {
    img: img3,
    jobTitle: "Appraisers of Personal & Business Property",
    jobTitleAlternatives: [[]],
    educationRequired: "Master's Degree",
    jobOutlook: "Salary:",
    industry: ["Government", "Real Estate & Rentals"],
    salary: "$61,560",
  },
  {
    img: img3,
    jobTitle: "Arbitrators, Mediators, & Conciliators",
    jobTitleAlternatives: ["Alternative Dispute Resolution Coordinator (ADR Coordinator)", "Arbitrator", "Labor Arbitrator", "Mediator"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Government", "Finance & Insurance"],
    salary: "$64,030",
  },
  {
    img: img3,
    jobTitle: "Architects",
    jobTitleAlternatives: ["Architect", "Design Architect", "Planner", "Project Architect"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical"],
    salary: "$82,840",
  },
  {
    img: img3,
    jobTitle: "Architectural & Civil Drafters",
    jobTitleAlternatives: ["Architectural Drafter", "Civil Drafter", "Computer-Aided Drafting Designer (CAD Designer)", "Draftsman"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Construction"],
    salary: "$59,820",
  },
  {
    img: img3,
    jobTitle: "Architectural & Engineering Managers",
    jobTitleAlternatives: ["Engineering Director", "Engineering Program Manager", "Project Engineering Manager", "Project Manager"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Manufacturing"],
    salary: "$159,920",
  },
  {
    img: img3,
    jobTitle: "Architecture Teachers, Postsecondary",
    jobTitleAlternatives: ["Architecture Professor", "Assistant Professor", "Associate Professor", "Professor"],
    educationRequired: "Doctoral Degree",
    jobOutlook: "Salary:",
    industry: ["Education"],
    salary: "$93,220",
  },
  {
    img: img3,
    jobTitle: "Archivists",
    jobTitleAlternatives: ["Archivist", "Museum Archivist", "Records Manager", "University Archivist"],
    educationRequired: "Master's Degree",
    jobOutlook: "Salary:",
    industry: ["Education", "Government", "Service", "Arts & Entertainment", "Media & Communication"],
    salary: "$58,640",
  },
  {
    img: img3,
    jobTitle: "Area, Ethnic, & Cultural Studies Teachers, Postsecondary",
    jobTitleAlternatives: ["Assistant Professor", "Associate Professor", "Professor", "Women's Studies Professor"],
    educationRequired: "Doctoral Degree",
    jobOutlook: "Salary:",
    industry: ["Education"],
    salary: "$80,910",
  },
  {
    img: img3,
    jobTitle: "Art Directors",
    jobTitleAlternatives: ["Art Director", "Creative Director", "Group Art Supervisor", "Production Manager"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical"],
    salary: "$105,180",
  },
  {
    img: img3,
    jobTitle: "Art Therapists",
    jobTitleAlternatives: ["Art Psychotherapist", "Art Therapist", "Board Certified Art Therapist (ATR-BC)", "Registered Art Therapist (ATR)"],
    educationRequired: "Master's Degree",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling"],
    salary: "$60,800",
  },
  {
    img: img3,
    jobTitle: "Art, Drama, & Music Teachers, Postsecondary",
    jobTitleAlternatives: ["Art Professor", "Music Professor", "Professor", "Theater Professor"],
    educationRequired: "Master's Degree",
    jobOutlook: "Salary:",
    industry: ["Education"],
    salary: "$77,280",
  },
  {
    img: img3,
    jobTitle: "Astronomers",
    jobTitleAlternatives: ["Astronomer", "Astrophysicist", "Research Scientist", "Scientist"],
    educationRequired: "Doctoral Degree",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Education", "Government"],
    salary: "$128,330",
  },
  {
    img: img3,
    jobTitle: "Athletes & Sports Competitors",
    jobTitleAlternatives: ["Baseball Player", "Golf Professional", "Hockey Player", "Race Car Driver"],
    educationRequired: "No High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Arts & Entertainment"],
    salary: "$94,270",
  },
  {
    img: img3,
    jobTitle: "Athletic Trainers",
    jobTitleAlternatives: ["Athletic Instructor", "Athletic Trainer", "Certified Athletic Trainer", "Personal Trainer"],
    educationRequired: "Master's Degree",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling", "Education", "Arts & Entertainment"],
    salary: "$53,840",
  },
  {
    img: img3,
    jobTitle: "Atmospheric & Space Scientists",
    jobTitleAlternatives: ["Forecaster", "General Forecaster", "Meteorologist", "Warning Coordination Meteorologist"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Government", "Professional, Science, & Technical", "Media & Communication", "Education"],
    salary: "$83,780",
  },
  {
    img: img3,
    jobTitle: "Atmospheric, Earth, Marine, & Space Sciences Teachers, Postsecondary",
    jobTitleAlternatives: ["Assistant Professor", "Associate Professor", "Geology Professor", "Professor"],
    educationRequired: "Master's Degree",
    jobOutlook: "Salary:",
    industry: ["Education"],
    salary: "$97,770",
  },
  {
    img: img3,
    jobTitle: "Audio & Video Technicians",
    jobTitleAlternatives: [
      "Audio Visual Specialist (AV Specialist)",
      "AV Tech (Audio Visual Technician)",
      "Media Technician",
      "Operations Technician",
    ],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Media & Communication", "Education", "Real Estate & Rentals", "Service", "Professional, Science, & Technical"],
    salary: "$50,660",
  },
  {
    img: img3,
    jobTitle: "Audiologists",
    jobTitleAlternatives: ["Audiologist", "Audiology Doctor (AUD)", "Clinical Audiologist", "Educational Audiologist"],
    educationRequired: "Doctoral Degree",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling", "Retail", "Education"],
    salary: "$82,680",
  },
  {
    img: img3,
    jobTitle: "Audiovisual Equipment Installers & Repairers",
    jobTitleAlternatives: ["Electronic Technician", "Field Service Technician", "Home Theater Installer", "Satellite Installer"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Construction", "Retail"],
    salary: "$44,960",
  },
  {
    img: img3,
    jobTitle: "Automotive & Watercraft Service Attendants",
    jobTitleAlternatives: ["Attendant", "Fuel Dock Attendant", "Gas Attendant", "Service Station Attendant"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Retail", "Service"],
    salary: "$30,850",
  },
  {
    img: img3,
    jobTitle: "Automotive Body & Related Repairers",
    jobTitleAlternatives: ["Auto Body Man", "Automotive Body Technician (Auto Body Tech)", "Body Man", "Body Technician (Body Tech)"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Service", "Retail"],
    salary: "$47,670",
  },
  {
    img: img3,
    jobTitle: "Automotive Engineering Technicians",
    jobTitleAlternatives: ["Laboratory Technician (Lab Technician)", "Research Technician"],
    educationRequired: "Associate's Degree",
    jobOutlook: "Salary:",
    industry: ["Manufacturing", "Professional, Science, & Technical"],
    salary: "$61,990",
  },
  {
    img: img3,
    jobTitle: "Automotive Engineers",
    jobTitleAlternatives: ["Engineer", "Product Engineer", "Project Engineer", "Research Engineer"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Manufacturing", "Professional, Science, & Technical"],
    salary: "$96,310",
  },
  {
    img: img3,
    jobTitle: "Automotive Glass Installers & Repairers",
    jobTitleAlternatives: [
      "Automotive Glass Installer (Auto Glass Installer)",
      "Automotive Glass Technician (Auto Glass Technician)",
      "Glass Installer Technician",
      "Glass Technician",
    ],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Service", "Retail"],
    salary: "$43,700",
  },
  {
    img: img3,
    jobTitle: "Automotive Service Technicians & Mechanics",
    jobTitleAlternatives: [
      "Automotive Mechanic (Auto Mechanic)",
      "Automotive Technician (Auto Tech)",
      "Mechanic",
      "Service Technician (Service Tech)",
    ],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Retail", "Service"],
    salary: "$46,970",
  },
  {
    img: img3,
    jobTitle: "Aviation Inspectors",
    jobTitleAlternatives: ["Aircraft Inspector", "Aviation Safety Inspector (ASI)", "Inspector", "Quality Inspector"],
    educationRequired: "Associate's Degree",
    jobOutlook: "Salary:",
    industry: ["Government", "Transportation & Storage"],
    salary: "$79,570",
  },
  {
    img: img3,
    jobTitle: "Avionics Technicians",
    jobTitleAlternatives: ["Aviation Electrical Technician", "Avionics Electronics Technician", "Avionics Installer", "Avionics Technician"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Transportation & Storage", "Manufacturing"],
    salary: "$75,450",
  },
  {
    img: img3,
    jobTitle: "Baggage Porters & Bellhops",
    jobTitleAlternatives: ["Bell Captain", "Bellman", "Skycap", "Valet"],
    educationRequired: "No High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Hotel & Food", "Transportation & Storage"],
    salary: "$31,510",
  },
  {
    img: img3,
    jobTitle: "Bailiffs",
    jobTitleAlternatives: ["Bailiff", "Court Bailiff", "Court Officer", "Court Security Officer"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Government"],
    salary: "$49,100",
  },
  {
    img: img3,
    jobTitle: "Bakers",
    jobTitleAlternatives: ["Baker", "Dough Mixer", "Mixer", "Pastry Chef"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing", "Retail", "Hotel & Food"],
    salary: "$32,780",
  },
  {
    img: img3,
    jobTitle: "Barbers",
    jobTitleAlternatives: ["Barber Shop Operator", "Barber Stylist", "Stylist"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Service"],
    salary: "$34,990",
  },
  {
    img: img3,
    jobTitle: "Baristas",
    jobTitleAlternatives: ["Barista", "Catering Barista"],
    educationRequired: "No High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Hotel & Food"],
    salary: "$27,930",
  },
  {
    img: img3,
    jobTitle: "Bartenders",
    jobTitleAlternatives: ["Banquet Bartender", "Bar Captain", "Bartender", "Mixologist"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Hotel & Food"],
    salary: "$29,380",
  },
  {
    img: img3,
    jobTitle: "Bicycle Repairers",
    jobTitleAlternatives: ["Bicycle Mechanic", "Bicycle Service Technician", "Bicycle Technician", "Bike Mechanic"],
    educationRequired: "No High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Retail"],
    salary: "$36,250",
  },
  {
    img: img3,
    jobTitle: "Bill & Account Collectors",
    jobTitleAlternatives: ["Collector", "Credit Clerk", "Debt Collector", "Patient Account Representative"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Administration & Support Services", "Finance & Insurance", "Health & Counseling"],
    salary: "$39,470",
  },
  {
    img: img3,
    jobTitle: "Billing & Posting Clerks",
    jobTitleAlternatives: ["Billing Clerk", "Billing Coordinator", "Pre-Audit Clerk", "Statement Clerk"],
    educationRequired: "some college or high school diploma/GED usually needed",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling", "Professional, Science, & Technical"],
    salary: "$42,810",
  },
  {
    img: img3,
    jobTitle: "Biochemists & Biophysicists",
    jobTitleAlternatives: ["Analytical Research Chemist", "Biochemist", "Biophysics Researcher", "Scientist"],
    educationRequired: "Doctoral Degree",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Manufacturing"],
    salary: "$103,810",
  },
  {
    img: img3,
    jobTitle: "Bioengineers & Biomedical Engineers",
    jobTitleAlternatives: ["Biomedical Equipment Technician (BMET)", "Biomedical Technician", "Engineer", "Process Engineer"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Manufacturing"],
    salary: "$99,550",
  },
  {
    img: img3,
    jobTitle: "Biofuels Processing Technicians",
    jobTitleAlternatives: ["Biofuels Processing Technician", "Ethanol Operator", "Process Operator", "Production Operator"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing", "Government"],
    salary: "$57,470",
  },
  {
    img: img3,
    jobTitle: "Biofuels Production Managers",
    jobTitleAlternatives: ["Biofuels Production Manager", "Plant Manager", "Production Coordinator", "Production Manager"],
    educationRequired: "bachelor's degree or some college usually needed",
    jobOutlook: "Salary:",
    industry: ["Manufacturing"],
    salary: "$107,560",
  },
  {
    img: img3,
    jobTitle: "Biofuels/Biodiesel Technology & Product Development Managers",
    jobTitleAlternatives: [
      "Analytical Research Program Manager",
      "Biodiesel Division Manager",
      "Laboratory Manager (Lab Manager)",
      "Project Development Director",
    ],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Manufacturing"],
    salary: "$159,920",
  },
  {
    img: img3,
    jobTitle: "Bioinformatics Scientists",
    jobTitleAlternatives: ["Bioinformaticist", "Bioinformatics Scientist", "Research Scientist", "Scientist"],
    educationRequired: "bachelor's degree or post-doctoral training usually needed",
    jobOutlook: "Salary:",
    industry: ["Government", "Professional, Science, & Technical"],
    salary: "$87,300",
  },
  {
    img: img3,
    jobTitle: "Bioinformatics Technicians",
    jobTitleAlternatives: ["Bioinformatics Analyst", "Bioinformatics Specialist", "Biotechnician", "Scientific Informatics Analyst"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Government", "Education"],
    salary: "$71,700",
  },
  {
    img: img3,
    jobTitle: "Biological Science Teachers, Postsecondary",
    jobTitleAlternatives: ["Associate Professor", "Biology Instructor", "Biology Professor", "Professor"],
    educationRequired: "Doctoral Degree",
    jobOutlook: "Salary:",
    industry: ["Education"],
    salary: "$81,650",
  },
  {
    img: img3,
    jobTitle: "Biological Technicians",
    jobTitleAlternatives: ["Biological Technician", "Laboratory Technician", "Research Associate", "Research Technician"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Education", "Government", "Health & Counseling"],
    salary: "$49,650",
  },
  {
    img: img3,
    jobTitle: "Biologists",
    jobTitleAlternatives: ["Biologist", "Research Biologist", "Research Scientist", "Scientist"],
    educationRequired: "Master's Degree",
    jobOutlook: "Salary:",
    industry: ["Government", "Professional, Science, & Technical"],
    salary: "$87,300",
  },
  {
    img: img3,
    jobTitle: "Biomass Plant Technicians",
    jobTitleAlternatives: ["Auxiliary Operator", "Fuel Handler", "Operations Technician (Operations Tech)", "Plant Operator"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Utilities", "Government"],
    salary: "$93,060",
  },
  {
    img: img3,
    jobTitle: "Biomass Power Plant Managers",
    jobTitleAlternatives: ["Maintenance Manager", "Maintenance Supervisor", "Operations Manager", "Plant Manager"],
    educationRequired: "bachelor's degree or associate's degree usually needed",
    jobOutlook: "Salary:",
    industry: ["Manufacturing"],
    salary: "$107,560",
  },
  {
    img: img3,
    jobTitle: "Biostatisticians",
    jobTitleAlternatives: ["Biometrician", "Biostatistician", "Research Scientist", "Statistical Scientist"],
    educationRequired: "Master's Degree",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Government", "Education"],
    salary: "$98,920",
  },
  {
    img: img3,
    jobTitle: "Blockchain Engineers",
    jobTitleAlternatives: [[]],
    educationRequired: "Master's Degree",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Government"],
    salary: "$98,740",
  },
  {
    img: img3,
    jobTitle: "Boilermakers",
    jobTitleAlternatives: ["Boiler Maker", "Boiler Mechanic", "Boiler Technician", "Boilermaker"],
    educationRequired: "no high school diploma/GED or certificate after high school usually needed",
    jobOutlook: "Salary:",
    industry: ["Construction", "Manufacturing"],
    salary: "$66,920",
  },
  {
    img: img3,
    jobTitle: "Bookkeeping, Accounting, & Auditing Clerks",
    jobTitleAlternatives: ["Account Clerk", "Accounting Assistant", "Accounting Clerk", "Accounts Payables Clerk"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical"],
    salary: "$45,860",
  },
  {
    img: img3,
    jobTitle: "Brickmasons & Blockmasons",
    jobTitleAlternatives: ["Block Layer", "Brick and Block Mason", "Bricklayer", "Mason"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Construction"],
    salary: "$59,000",
  },
  {
    img: img3,
    jobTitle: "Bridge & Lock Tenders",
    jobTitleAlternatives: ["Bridge Operator", "Bridge Tender", "Lock Tender"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Government", "Construction"],
    salary: "$47,280",
  },
  {
    img: img3,
    jobTitle: "Broadcast Announcers & Radio Disc Jockeys",
    jobTitleAlternatives: ["Anchor", "Announcer", "News Anchor", "Television News Anchor (TV News Anchor)"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Media & Communication"],
    salary: "$41,750",
  },
  {
    img: img3,
    jobTitle: "Broadcast Technicians",
    jobTitleAlternatives: ["Board Operator", "Broadcast Engineer", "Broadcast Technician", "Control Operator"],
    educationRequired: "associate's degree or some college usually needed",
    jobOutlook: "Salary:",
    industry: ["Media & Communication"],
    salary: "$60,700",
  },
  {
    img: img3,
    jobTitle: "Brokerage Clerks",
    jobTitleAlternatives: ["Registered Sales Assistant", "Sales Assistant", "Sales Trader", "Trading Assistant"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Finance & Insurance"],
    salary: "$54,680",
  },
  {
    img: img3,
    jobTitle: "Brownfield Redevelopment Specialists & Site Managers",
    jobTitleAlternatives: [
      "Brownfield Program Director",
      "Brownfield Redevelopment Coordinator",
      "Brownfields Practice Leader",
      "Environmental Program Manager",
    ],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Government"],
    salary: "$128,620",
  },
  {
    img: img3,
    jobTitle: "Budget Analysts",
    jobTitleAlternatives: ["Budget Analyst", "Budget Coordinator", "Budget Officer", "Policy Analyst"],
    educationRequired: "bachelor's degree or associate's degree usually needed",
    jobOutlook: "Salary:",
    industry: ["Government", "Education", "Professional, Science, & Technical"],
    salary: "$82,260",
  },
  {
    img: img3,
    jobTitle: "Bus & Truck Mechanics & Diesel Engine Specialists",
    jobTitleAlternatives: ["Bus Mechanic", "Diesel Mechanic", "Diesel Technician (Diesel Tech)", "Truck Mechanic"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Transportation & Storage", "Wholesale/Commercial Sales", "Government", "Service"],
    salary: "$54,360",
  },
  {
    img: img3,
    jobTitle: "Bus Drivers, School",
    jobTitleAlternatives: ["Bus Driver", "School Bus Driver", "Shuttle Bus Driver", "Special Education Bus Driver"],
    educationRequired: "Associate's Degree",
    jobOutlook: "Salary:",
    industry: ["Education", "Transportation & Storage", "Government"],
    salary: "$41,270",
  },
  {
    img: img3,
    jobTitle: "Bus Drivers, Transit & Intercity",
    jobTitleAlternatives: ["Bus Driver", "Bus Operator", "Motor Coach Operator", "Transit Bus Driver"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Government", "Transportation & Storage"],
    salary: "$50,890",
  },
  {
    img: img3,
    jobTitle: "Business Continuity Planners",
    jobTitleAlternatives: [
      "Business Continuity Administrator",
      "Business Continuity Analyst",
      "Business Continuity Consultant",
      "Business Continuity Coordinator",
    ],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Government", "Professional, Science, & Technical", "Education"],
    salary: "$75,990",
  },
  {
    img: img3,
    jobTitle: "Business Intelligence Analysts",
    jobTitleAlternatives: [
      "Business Intelligence Analyst",
      "Competitive Intelligence Analyst",
      "Market Intelligence Consultant",
      "Strategic Business and Technology Intelligence Consultant",
    ],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Finance & Insurance", "Media & Communication", "Management"],
    salary: "$103,500",
  },
  {
    img: img3,
    jobTitle: "Business Teachers, Postsecondary",
    jobTitleAlternatives: ["Business Instructor", "Business Professor", "Instructor", "Professor"],
    educationRequired: "Doctoral Degree",
    jobOutlook: "Salary:",
    industry: ["Education"],
    salary: "$88,790",
  },
  {
    img: img3,
    jobTitle: "Butchers & Meat Cutters",
    jobTitleAlternatives: ["Butcher", "Meat Clerk", "Meat Cutter", "Meat Specialist"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Retail"],
    salary: "$36,930",
  },
  {
    img: img3,
    jobTitle: "Buyers & Purchasing Agents, Farm Products",
    jobTitleAlternatives: ["Buyer", "Grain Buyer", "Grain Merchandiser", "Purchasing Agent"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Manufacturing", "Wholesale/Commercial Sales", "Government"],
    salary: "$67,620",
  },
  {
    img: img3,
    jobTitle: "Cabinetmakers & Bench Carpenters",
    jobTitleAlternatives: ["Cabinet Assembler", "Cabinet Installer", "Cabinetmaker", "Frame Builder"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing"],
    salary: "$38,810",
  },
  {
    img: img3,
    jobTitle: "Calibration Technologists & Technicians",
    jobTitleAlternatives: [[]],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Manufacturing", "Service"],
    salary: "$62,050",
  },
  {
    img: img3,
    jobTitle: "Camera & Photographic Equipment Repairers",
    jobTitleAlternatives: ["Camera Repair Technician", "Camera Repairman", "Camera Technician", "Repair Technician"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Education", "Service"],
    salary: "$44,060",
  },
  {
    img: img3,
    jobTitle: "Camera Operators, Television, Video, & Film",
    jobTitleAlternatives: ["Camera Operator", "Cameraman", "Television News Photographer", "Videographer"],
    educationRequired: "Associate's Degree",
    jobOutlook: "Salary:",
    industry: ["Media & Communication", "Professional, Science, & Technical"],
    salary: "$58,230",
  },
  {
    img: img3,
    jobTitle: "Captains, Mates, & Pilots of Water Vessels",
    jobTitleAlternatives: ["Boat Captain", "Captain", "First Mate", "Mate"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Transportation & Storage"],
    salary: "$95,210",
  },
  {
    img: img3,
    jobTitle: "Cardiologists",
    jobTitleAlternatives: [[]],
    educationRequired: "Doctoral Degree",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling"],
    salary: "$239,200+",
  },
  {
    img: img3,
    jobTitle: "Cardiovascular Technologists & Technicians",
    jobTitleAlternatives: [
      "Cardio Tech (Cardiovascular Technician)",
      "Cardiology Technician",
      "Cardiovascular Technologist (CVT)",
      "Registered Cardiovascular Invasive Specialist (RCIS)",
    ],
    educationRequired: "associate's degree or certificate after high school usually needed",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling"],
    salary: "$63,020",
  },
  {
    img: img3,
    jobTitle: "Career/Technical Education Teachers, Middle School",
    jobTitleAlternatives: [
      "Family and Consumer Sciences Teacher (FACS Teacher)",
      "Teacher",
      "Technology Education Teacher (Tech Ed Teacher)",
      "Technology Teacher",
    ],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Education"],
    salary: "$62,630",
  },
  {
    img: img3,
    jobTitle: "Career/Technical Education Teachers, Postsecondary",
    jobTitleAlternatives: [
      "Cosmetology Instructor; HVAC-R Instructor (Heating",
      "Ventilation",
      "Air Conditioning",
      "And Refrigeration Instructor); Instructor; Professor",
    ],
    educationRequired: "Associate's Degree",
    jobOutlook: "Salary:",
    industry: ["Education"],
    salary: "$59,840",
  },
  {
    img: img3,
    jobTitle: "Career/Technical Education Teachers, Secondary School",
    jobTitleAlternatives: ["Family and Consumer Sciences Teacher (FACS Teacher)", "Instructor", "Teacher", "Technology Education Teacher"],
    educationRequired: "bachelor's degree or certificate after high school usually needed",
    jobOutlook: "Salary:",
    industry: ["Education"],
    salary: "$62,500",
  },
  {
    img: img3,
    jobTitle: "Cargo & Freight Agents",
    jobTitleAlternatives: ["Air Export Specialist", "Drop Shipment Clerk", "Logistics Coordinator", "Logistics Service Representative"],
    educationRequired: "Associate's Degree",
    jobOutlook: "Salary:",
    industry: ["Transportation & Storage"],
    salary: "$46,860",
  },
  {
    img: img3,
    jobTitle: "Carpenters",
    jobTitleAlternatives: ["Cabinet Maker", "Carpenter", "Form Carpenter", "Scaffold Builder"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Construction"],
    salary: "$51,390",
  },
  {
    img: img3,
    jobTitle: "Carpet Installers",
    jobTitleAlternatives: ["Carpet Installer", "Carpet Mechanic", "Flooring Installer", "Installer"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Construction", "Retail"],
    salary: "$45,240",
  },
  {
    img: img3,
    jobTitle: "Cartographers & Photogrammetrists",
    jobTitleAlternatives: ["Cartographer", "Photogrammetric Technician", "Photogrammetrist", "Stereo Compiler"],
    educationRequired: "certificate after high school or bachelor's degree usually needed",
    jobOutlook: "Salary:",
    industry: ["Government", "Professional, Science, & Technical"],
    salary: "$71,890",
  },
  {
    img: img3,
    jobTitle: "Cashiers",
    jobTitleAlternatives: ["Cashier", "Checker", "Customer Assistant", "Sales Associate"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Retail", "Hotel & Food"],
    salary: "$28,240",
  },
  {
    img: img3,
    jobTitle: "Cement Masons & Concrete Finishers",
    jobTitleAlternatives: ["Cement Finisher", "Cement Mason", "Concrete Finisher", "Finisher"],
    educationRequired: "No High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Construction"],
    salary: "$48,300",
  },
  {
    img: img3,
    jobTitle: "Chefs & Head Cooks",
    jobTitleAlternatives: ["Chef", "Cook", "Executive Chef (Ex Chef)", "Sous Chef"],
    educationRequired: "associate's degree or certificate after high school usually needed",
    jobOutlook: "Salary:",
    industry: ["Hotel & Food"],
    salary: "$56,520",
  },
  {
    img: img3,
    jobTitle: "Chemical Engineers",
    jobTitleAlternatives: ["Chemical Engineer", "Engineer", "Process Engineer", "Scientist"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Manufacturing", "Professional, Science, & Technical"],
    salary: "$106,260",
  },
  {
    img: img3,
    jobTitle: "Chemical Equipment Operators & Tenders",
    jobTitleAlternatives: ["Chemical Operator", "Multiskill Operator", "Outside Operator", "Vessel Operator"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing"],
    salary: "$49,330",
  },
  {
    img: img3,
    jobTitle: "Chemical Plant & System Operators",
    jobTitleAlternatives: ["Chemical Operator", "Loader Technician", "Process Operator", "Process Technician"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing"],
    salary: "$82,670",
  },
  {
    img: img3,
    jobTitle: "Chemical Technicians",
    jobTitleAlternatives: [
      "Chemical Technician",
      "Laboratory Analyst (Lab Analyst)",
      "Laboratory Technician (Lab Tech)",
      "Quality Control Technician (QC Tech)",
    ],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Manufacturing", "Professional, Science, & Technical"],
    salary: "$50,840",
  },
  {
    img: img3,
    jobTitle: "Chemistry Teachers, Postsecondary",
    jobTitleAlternatives: ["Assistant Professor", "Chemistry Instructor", "Chemistry Professor", "Professor"],
    educationRequired: "master's degree or post-doctoral training usually needed",
    jobOutlook: "Salary:",
    industry: ["Education"],
    salary: "$80,720",
  },
  {
    img: img3,
    jobTitle: "Chemists",
    jobTitleAlternatives: ["Analytical Chemist", "Chemist", "Forensic Scientist", "Research Chemist"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Manufacturing", "Professional, Science, & Technical", "Government"],
    salary: "$80,670",
  },
  {
    img: img3,
    jobTitle: "Chief Executives",
    jobTitleAlternatives: ["Chief Executive Officer (CEO)", "Chief Financial Officer (CFO)", "Chief Operating Officer (COO)", "Executive Director"],
    educationRequired: "Doctoral Degree",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical"],
    salary: "$189,520",
  },
  {
    img: img3,
    jobTitle: "Chief Sustainability Officers",
    jobTitleAlternatives: ["Chief Sustainability Officer (CSO)", "Sustainability Director", "Sustainability Manager", "Sustainable Design Director"],
    educationRequired: "Master's Degree",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical"],
    salary: "$189,520",
  },
  {
    img: img3,
    jobTitle: "Child, Family, & School Social Workers",
    jobTitleAlternatives: ["Case Manager", "Family Protection Specialist", "Family Service Worker", "School Social Worker"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Government", "Health & Counseling", "Education"],
    salary: "$50,820",
  },
  {
    img: img3,
    jobTitle: "Childcare Workers",
    jobTitleAlternatives: ["Child Care Worker", "Childcare Provider", "Infant Teacher", "Toddler Teacher"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling", "Service", "Education"],
    salary: "$28,520",
  },
  {
    img: img3,
    jobTitle: "Chiropractors",
    jobTitleAlternatives: ["Chiropractic Doctor (DC)", "Chiropractic Neurologist", "Chiropractic Physician", "Chiropractor"],
    educationRequired: "Doctoral Degree",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling"],
    salary: "$75,380",
  },
  {
    img: img3,
    jobTitle: "Choreographers",
    jobTitleAlternatives: ["Ballet Director", "Choreographer", "Dance Director", "Dance Maker"],
    educationRequired: "bachelor's degree or some college usually needed",
    jobOutlook: "Salary:",
    industry: ["Education", "Arts & Entertainment"],
    salary: "$50,990",
  },
  {
    img: img3,
    jobTitle: "Civil Engineering Technologists & Technicians",
    jobTitleAlternatives: ["Civil Engineering Technician", "Engineer Technician", "Engineering Assistant", "Engineering Technician"],
    educationRequired: "certificate after high school or some college usually needed",
    jobOutlook: "Salary:",
    industry: ["Government", "Professional, Science, & Technical"],
    salary: "$59,630",
  },
  {
    img: img3,
    jobTitle: "Civil Engineers",
    jobTitleAlternatives: ["Civil Engineer", "Design Engineer", "Project Engineer", "Structural Engineer"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Government", "Construction"],
    salary: "$89,940",
  },
  {
    img: img3,
    jobTitle: "Claims Adjusters, Examiners, & Investigators",
    jobTitleAlternatives: ["Claims Adjuster", "Claims Analyst", "Claims Examiner", "Claims Representative"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Finance & Insurance", "Government"],
    salary: "$72,230",
  },
  {
    img: img3,
    jobTitle: "Cleaners of Vehicles & Equipment",
    jobTitleAlternatives: ["Automotive Detailer (Auto Detailer)", "Car Washer", "Detailer", "Reconditioner"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Service", "Retail"],
    salary: "$31,000",
  },
  {
    img: img3,
    jobTitle: "Cleaning, Washing, & Metal Pickling Equipment Operators & Tenders",
    jobTitleAlternatives: ["Clean in Places Operator (CIP Operator)", "Sanitation Technician", "Sanitation Worker", "Sanitizer"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing"],
    salary: "$37,190",
  },
  {
    img: img3,
    jobTitle: "Clergy",
    jobTitleAlternatives: ["Minister", "Pastor", "Priest", "Rector"],
    educationRequired: "Master's Degree",
    jobOutlook: "Salary:",
    industry: ["Service"],
    salary: "$55,550",
  },
  {
    img: img3,
    jobTitle: "Climate Change Policy Analysts",
    jobTitleAlternatives: ["Climate Analyst", "Climate and Energy Program Associate", "Environmental Policy Analyst", "Policy Analyst"],
    educationRequired: "Master's Degree",
    jobOutlook: "Salary:",
    industry: ["Government", "Professional, Science, & Technical"],
    salary: "$76,480",
  },
  {
    img: img3,
    jobTitle: "Clinical & Counseling Psychologists",
    jobTitleAlternatives: ["Clinical Psychologist", "Counseling Psychologist", "Psychologist", "Psychotherapist"],
    educationRequired: "Doctoral Degree",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling"],
    salary: "$90,130",
  },
  {
    img: img3,
    jobTitle: "Clinical Data Managers",
    jobTitleAlternatives: [
      "Clinical Data Management Director (CDM Director)",
      "Clinical Data Management Manager (CDM Manager)",
      "Clinical Data Manager",
      "Data Management Manager",
    ],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Finance & Insurance", "Media & Communication", "Management"],
    salary: "$103,500",
  },
  {
    img: img3,
    jobTitle: "Clinical Neuropsychologists",
    jobTitleAlternatives: ["Clinical Neuropsychologist", "Neuropsychologist", "Pediatric Neuropsychologist", "Staff Psychologist"],
    educationRequired: "Doctoral Degree",
    jobOutlook: "Salary:",
    industry: ["Government"],
    salary: "$106,420",
  },
  {
    img: img3,
    jobTitle: "Clinical Nurse Specialists",
    jobTitleAlternatives: [
      "Clinical Nurse Specialist",
      "Critical Care Clinical Nurse Specialist",
      "Emergency Clinical Nurse Specialist",
      "ICU Clinical Nurse Specialist (Intensive Care Unit Clinical Nurse Specialist)",
    ],
    educationRequired: "Master's Degree",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling"],
    salary: "$81,220",
  },
  {
    img: img3,
    jobTitle: "Clinical Research Coordinators",
    jobTitleAlternatives: ["Clinical Program Manager", "Clinical Research Coordinator", "Clinical Trial Manager", "Research Coordinator"],
    educationRequired: "bachelor's degree or certificate after high school usually needed",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Government"],
    salary: "$144,440",
  },
  {
    img: img3,
    jobTitle: "Coaches & Scouts",
    jobTitleAlternatives: ["Basketball Coach", "Coach", "Football Coach", "Track and Field Coach"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Education", "Arts & Entertainment"],
    salary: "$44,890",
  },
  {
    img: img3,
    jobTitle: "Coating, Painting, & Spraying Machine Setters, Operators, & Tenders",
    jobTitleAlternatives: ["Automotive Painter (Auto Painter)", "Coater Operator", "Paint Technician (Paint Tech)", "Painter"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing", "Service"],
    salary: "$43,960",
  },
  {
    img: img3,
    jobTitle: "Coil Winders, Tapers, & Finishers",
    jobTitleAlternatives: ["Armature Winder", "Coil Finisher", "Coil Winder", "Winder"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing"],
    salary: "$43,160",
  },
  {
    img: img3,
    jobTitle: "Coin, Vending, & Amusement Machine Servicers & Repairers",
    jobTitleAlternatives: ["Field Service Technician", "Service Technician", "Slot Technician", "Vending Technician"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Retail", "Arts & Entertainment", "Government", "Wholesale/Commercial Sales", "Manufacturing"],
    salary: "$39,690",
  },
  {
    img: img3,
    jobTitle: "Commercial & Industrial Designers",
    jobTitleAlternatives: ["Design Engineer", "Designer", "Industrial Designer", "Product Designer"],
    educationRequired: "bachelor's degree or some college usually needed",
    jobOutlook: "Salary:",
    industry: ["Manufacturing", "Professional, Science, & Technical", "Wholesale/Commercial Sales"],
    salary: "$75,910",
  },
  {
    img: img3,
    jobTitle: "Commercial Divers",
    jobTitleAlternatives: ["Commercial Diver", "Diver", "Diver Tender", "Tender"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Administration & Support Services", "Construction"],
    salary: "$68,300",
  },
  {
    img: img3,
    jobTitle: "Commercial Pilots",
    jobTitleAlternatives: ["Captain", "EMS Helicopter Pilot (Emergency Medical Service Helicopter Pilot)", "First Officer", "Pilot"],
    educationRequired: "bachelor's degree or certificate after high school usually needed",
    jobOutlook: "Salary:",
    industry: ["Transportation & Storage", "Health & Counseling"],
    salary: "$103,910",
  },
  {
    img: img3,
    jobTitle: "Communications Teachers, Postsecondary",
    jobTitleAlternatives: ["Communication Instructor", "Communication Professor", "Instructor", "Professor"],
    educationRequired: "Master's Degree",
    jobOutlook: "Salary:",
    industry: ["Education"],
    salary: "$76,250",
  },
  {
    img: img3,
    jobTitle: "Community Health Workers",
    jobTitleAlternatives: [
      "Community Health Outreach Worker",
      "Community Health Program Coordinator",
      "Community Health Promoter",
      "Community Nutrition Educator",
    ],
    educationRequired: "bachelor's degree or associate's degree usually needed",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling", "Government", "Service"],
    salary: "$46,190",
  },
  {
    img: img3,
    jobTitle: "Compensation & Benefits Managers",
    jobTitleAlternatives: ["Benefits Manager", "Compensation and Benefits Manager", "Compensation Director", "Compensation Manager"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Management", "Finance & Insurance", "Professional, Science, & Technical"],
    salary: "$131,280",
  },
  {
    img: img3,
    jobTitle: "Compensation, Benefits, & Job Analysis Specialists",
    jobTitleAlternatives: ["Benefits Analyst", "Compensation Analyst", "Compensation Consultant", "Personnel Specialist"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Finance & Insurance", "Government", "Professional, Science, & Technical", "Service", "Management"],
    salary: "$67,780",
  },
  {
    img: img3,
    jobTitle: "Compliance Managers",
    jobTitleAlternatives: ["Compliance Director", "Compliance Manager", "Environmental Manager", "Environmental Program Manager"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Government"],
    salary: "$128,620",
  },
  {
    img: img3,
    jobTitle: "Compliance Officers",
    jobTitleAlternatives: ["Driver License Examiner", "License Examiner", "Motor Vehicle Clerk", "Public Service Representative (PSR)"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Government", "Finance & Insurance"],
    salary: "$71,690",
  },
  {
    img: img3,
    jobTitle: "Computer & Information Research Scientists",
    jobTitleAlternatives: ["Computer Scientist", "Computer Specialist", "Control System Computer Scientist", "Research Scientist"],
    educationRequired: "bachelor's degree or doctoral degree usually needed",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Government", "Media & Communication"],
    salary: "$136,620",
  },
  {
    img: img3,
    jobTitle: "Computer & Information Systems Managers",
    jobTitleAlternatives: [
      "Information Systems Director (IS Director)",
      "Information Systems Manager (IS Manager)",
      "Information Technology Director (IT Director)",
      "Information Technology Manager (IT Manager)",
    ],
    educationRequired: "bachelor's degree or some college usually needed",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Media & Communication", "Finance & Insurance"],
    salary: "$164,070",
  },
  {
    img: img3,
    jobTitle: "Computer Hardware Engineers",
    jobTitleAlternatives: ["Design Engineer", "Engineer", "Hardware Engineer", "Systems Integration Engineer"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Manufacturing", "Media & Communication"],
    salary: "$132,360",
  },
  {
    img: img3,
    jobTitle: "Computer Network Architects",
    jobTitleAlternatives: ["Network Analyst", "Network and Security Engineer", "Network Consultant", "Solutions Architect"],
    educationRequired: "Master's Degree",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Media & Communication"],
    salary: "$126,900",
  },
  {
    img: img3,
    jobTitle: "Computer Network Support Specialists",
    jobTitleAlternatives: ["Network Specialist", "Network Technical Analyst", "Network Technician", "Systems Specialist"],
    educationRequired: "bachelor's degree or associate's degree usually needed",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Media & Communication", "Education"],
    salary: "$68,050",
  },
  {
    img: img3,
    jobTitle: "Computer Numerically Controlled Tool Operators",
    jobTitleAlternatives: [
      "Computer Numerical Control Machine Operator (CNC Machine Operator)",
      "Computer Numerical Control Machinist (CNC Machinist)",
      "Computer Numerical Control Operator (CNC Operator)",
      "Machinist",
    ],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing"],
    salary: "$46,760",
  },
  {
    img: img3,
    jobTitle: "Computer Numerically Controlled Tool Programmers",
    jobTitleAlternatives: [
      "CAD CAM Programmer (Computer-Aided Design Computer-Aided Manufacturing Programmer)",
      "Computer Numerical Control Machinist (CNC Machinist)",
      "Computer Numerical Control Programmer (CNC Programmer)",
      "Programmer",
    ],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing"],
    salary: "$60,800",
  },
  {
    img: img3,
    jobTitle: "Computer Programmers",
    jobTitleAlternatives: ["Analyst Programmer", "Computer Programmer", "Programmer", "Programmer Analyst"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Media & Communication"],
    salary: "$97,800",
  },
  {
    img: img3,
    jobTitle: "Computer Science Teachers, Postsecondary",
    jobTitleAlternatives: ["Computer Information Systems Instructor (CIS Instructor)", "Computer Science Professor", "Instructor", "Professor"],
    educationRequired: "Master's Degree",
    jobOutlook: "Salary:",
    industry: ["Education"],
    salary: "$84,760",
  },
  {
    img: img3,
    jobTitle: "Computer Systems Analysts",
    jobTitleAlternatives: ["Business Systems Analyst", "Computer Systems Analyst", "Computer Systems Consultant", "Systems Analyst"],
    educationRequired: "bachelor's degree or associate's degree usually needed",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Finance & Insurance", "Management"],
    salary: "$102,240",
  },
  {
    img: img3,
    jobTitle: "Computer Systems Engineers/Architects",
    jobTitleAlternatives: ["Information Technology Architect (IT Architect)", "Solutions Architect", "Systems Consultant", "Systems Engineer"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Government"],
    salary: "$98,740",
  },
  {
    img: img3,
    jobTitle: "Computer User Support Specialists",
    jobTitleAlternatives: [
      "Computer Support Specialist",
      "Computer Technician",
      "Information Technology Specialist (IT Specialist)",
      "Support Specialist",
    ],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Education", "Media & Communication", "Administration & Support Services"],
    salary: "$57,890",
  },
  {
    img: img3,
    jobTitle: "Computer, Automated Teller, & Office Machine Repairers",
    jobTitleAlternatives: ["ATM Technician (Automated Teller Machine Technician)", "Computer Technician", "Copier Technician", "Service Technician"],
    educationRequired: "associate's degree or certificate after high school usually needed",
    jobOutlook: "Salary:",
    industry: ["Wholesale/Commercial Sales", "Retail", "Service"],
    salary: "$44,910",
  },
  {
    img: img3,
    jobTitle: "Concierges",
    jobTitleAlternatives: ["Chef Concierge", "Club Concierge", "Guest Service Agent", "Hotel Concierge"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Real Estate & Rentals", "Health & Counseling", "Hotel & Food", "Service"],
    salary: "$35,560",
  },
  {
    img: img3,
    jobTitle: "Conservation Scientists",
    jobTitleAlternatives: ["Conservationist", "Land Resource Specialist", "Research Soil Scientist", "Resource Conservationist"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Government", "Service"],
    salary: "$64,460",
  },
  {
    img: img3,
    jobTitle: "Construction & Building Inspectors",
    jobTitleAlternatives: ["Building Inspector", "Building Official", "Construction Inspector", "Plumbing Inspector"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Government", "Professional, Science, & Technical"],
    salary: "$64,480",
  },
  {
    img: img3,
    jobTitle: "Construction Laborers",
    jobTitleAlternatives: ["Construction Laborer", "Construction Worker", "Equipment Operator (EO)", "Post Framer"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Construction"],
    salary: "$40,750",
  },
  {
    img: img3,
    jobTitle: "Construction Managers",
    jobTitleAlternatives: ["Concrete Foreman", "Construction Area Manager", "Construction Manager", "Construction Superintendent"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Construction"],
    salary: "$101,480",
  },
  {
    img: img3,
    jobTitle: "Continuous Mining Machine Operators",
    jobTitleAlternatives: [
      "Continuous Miner Operator (CMO)",
      "Continuous Mining Machine Operator",
      "Continuous Mining Operator (CMO)",
      "Miner Operator",
    ],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Mining, Oil, & Gas"],
    salary: "$57,360",
  },
  {
    img: img3,
    jobTitle: "Control & Valve Installers & Repairers",
    jobTitleAlternatives: [
      "Instrument and Electrical Technician (I and E Technician)",
      "Measurement Technician",
      "Meter Technician",
      "Valve Technician",
    ],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Utilities", "Government"],
    salary: "$64,810",
  },
  {
    img: img3,
    jobTitle: "Conveyor Operators & Tenders",
    jobTitleAlternatives: ["Chipper Operator", "Flumer", "Packaging Line Operator", "Process Operator"],
    educationRequired: "No High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Transportation & Storage", "Manufacturing", "Wholesale/Commercial Sales"],
    salary: "$36,890",
  },
  {
    img: img3,
    jobTitle: "Cooks, Fast Food",
    jobTitleAlternatives: ["Cook", "Grill Cook", "Line Cook", "Pizza Cook"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Hotel & Food"],
    salary: "$27,640",
  },
  {
    img: img3,
    jobTitle: "Cooks, Institution & Cafeteria",
    jobTitleAlternatives: ["Cafeteria Cook", "Chef", "Cook", "School Cook"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling", "Education", "Hotel & Food"],
    salary: "$32,510",
  },
  {
    img: img3,
    jobTitle: "Cooks, Private Household",
    jobTitleAlternatives: ["Certified Personal Chef (CPC)", "Personal Chef", "Private Chef"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Service"],
    salary: "$38,570",
  },
  {
    img: img3,
    jobTitle: "Cooks, Restaurant",
    jobTitleAlternatives: ["Cook", "Grill Cook", "Line Cook", "Prep Cook (Preparation Cook)"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Hotel & Food"],
    salary: "$34,110",
  },
  {
    img: img3,
    jobTitle: "Cooks, Short Order",
    jobTitleAlternatives: ["Cook", "Grill Cook", "Line Cook", "Short Order Cook"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Hotel & Food", "Retail"],
    salary: "$30,360",
  },
  {
    img: img3,
    jobTitle: "Cooling & Freezing Equipment Operators & Tenders",
    jobTitleAlternatives: ["Freezer Operator", "Freezer Person", "Refrigeration Operator", "Refrigeration Technician"],
    educationRequired: "no high school diploma/GED or certificate after high school usually needed",
    jobOutlook: "Salary:",
    industry: ["Manufacturing"],
    salary: "$42,890",
  },
  {
    img: img3,
    jobTitle: "Coroners",
    jobTitleAlternatives: ["Coroner", "County Coroner", "Deputy Coroner", "Medical Examiner"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Government", "Finance & Insurance"],
    salary: "$71,690",
  },
  {
    img: img3,
    jobTitle: "Correctional Officers & Jailers",
    jobTitleAlternatives: ["Correctional Officer", "Corrections Officer (CO)", "Detention Officer", "Jailer"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Government"],
    salary: "$49,610",
  },
  {
    img: img3,
    jobTitle: "Correspondence Clerks",
    jobTitleAlternatives: ["Correspondence Clerk", "Correspondence Coordinator", "Office Technician", "Technical Clerk"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Finance & Insurance", "Administration & Support Services", "Professional, Science, & Technical", "Health & Counseling"],
    salary: "$38,700",
  },
  {
    img: img3,
    jobTitle: "Cost Estimators",
    jobTitleAlternatives: ["Construction Estimator", "Cost Analyst", "Cost Estimator", "Estimator"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Construction", "Manufacturing"],
    salary: "$71,200",
  },
  {
    img: img3,
    jobTitle: "Costume Attendants",
    jobTitleAlternatives: ["Costumer", "Dresser", "Wardrobe Assistant", "Wardrobe Attendant"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Media & Communication", "Arts & Entertainment", "Education"],
    salary: "$48,470",
  },
  {
    img: img3,
    jobTitle: "Counter & Rental Clerks",
    jobTitleAlternatives: ["Counter Clerk", "Rental Agent", "Rental Sales Representative", "Video Clerk"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Real Estate & Rentals", "Retail", "Service"],
    salary: "$35,830",
  },
  {
    img: img3,
    jobTitle: "Couriers & Messengers",
    jobTitleAlternatives: ["Courier", "Driver", "Laboratory Courier", "Messenger"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Transportation & Storage", "Health & Counseling"],
    salary: "$35,280",
  },
  {
    img: img3,
    jobTitle: "Court Reporters & Simultaneous Captioners",
    jobTitleAlternatives: ["Certified Shorthand Reporter (CSR)", "Court Reporter", "Court Stenographer", "Official Court Reporter"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Government", "Administration & Support Services"],
    salary: "$63,560",
  },
  {
    img: img3,
    jobTitle: "Court, Municipal, & License Clerks",
    jobTitleAlternatives: ["City Clerk", "Court Clerk", "License Clerk", "Town Clerk"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Government"],
    salary: "$44,140",
  },
  {
    img: img3,
    jobTitle: "Craft Artists",
    jobTitleAlternatives: ["Artist", "Designer", "Furniture Maker", "Glass Artist"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Arts & Entertainment"],
    salary: "$38,150",
  },
  {
    img: img3,
    jobTitle: "Crane & Tower Operators",
    jobTitleAlternatives: ["Crane Operator", "Mobile Crane Operator", "Overhead Crane Operator", "Scrap Crane Operator"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Construction", "Manufacturing", "Transportation & Storage", "Wholesale/Commercial Sales"],
    salary: "$61,340",
  },
  {
    img: img3,
    jobTitle: "Credit Analysts",
    jobTitleAlternatives: ["Credit Administrator", "Credit Analyst", "Credit Officer", "Credit Representative"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Finance & Insurance", "Management"],
    salary: "$78,850",
  },
  {
    img: img3,
    jobTitle: "Credit Authorizers, Checkers, & Clerks",
    jobTitleAlternatives: ["Commercial Credit Reviewer", "Credit Investigator", "Credit Processor", "Credit Representative"],
    educationRequired: "Associate's Degree",
    jobOutlook: "Salary:",
    industry: ["Finance & Insurance", "Administration & Support Services", "Wholesale/Commercial Sales", "Retail"],
    salary: "$44,830",
  },
  {
    img: img3,
    jobTitle: "Credit Counselors",
    jobTitleAlternatives: ["Certified Consumer Credit and Housing Counselor", "Certified Credit Counselor", "Credit Counselor", "Housing Counselor"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Education", "Finance & Insurance", "Professional, Science, & Technical", "Health & Counseling"],
    salary: "$47,320",
  },
  {
    img: img3,
    jobTitle: "Crematory Operators",
    jobTitleAlternatives: [[]],
    educationRequired: "Associate's Degree",
    jobOutlook: "Salary:",
    industry: ["Service"],
    salary: "$40,360",
  },
  {
    img: img3,
    jobTitle: "Criminal Justice & Law Enforcement Teachers, Postsecondary",
    jobTitleAlternatives: ["Assistant Professor", "Associate Professor", "Criminal Justice Professor", "Professor"],
    educationRequired: "Master's Degree",
    jobOutlook: "Salary:",
    industry: ["Education"],
    salary: "$64,990",
  },
  {
    img: img3,
    jobTitle: "Critical Care Nurses",
    jobTitleAlternatives: [
      "Critical Care Nurse Practitioner",
      "Critical Care Registered Nurse (CCRN)",
      "ICU Nurse (Intensive Care Unit Nurse)",
      "Staff Nurse",
    ],
    educationRequired: "bachelor's degree or associate's degree usually needed",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling"],
    salary: "$81,220",
  },
  {
    img: img3,
    jobTitle: "Crossing Guards & Flaggers",
    jobTitleAlternatives: ["Adult Crossing Guard", "Community Service Officer", "Crossing Guard", "School Crossing Guard"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Government", "Administration & Support Services"],
    salary: "$33,380",
  },
  {
    img: img3,
    jobTitle: "Crushing, Grinding, & Polishing Machine Setters, Operators, & Tenders",
    jobTitleAlternatives: ["Crusher Operator", "Grinder Operator", "Machine Operator", "Mill Operator"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing", "Mining, Oil, & Gas"],
    salary: "$43,290",
  },
  {
    img: img3,
    jobTitle: "Curators",
    jobTitleAlternatives: ["Collections Curator", "Collections Manager", "Curator", "Museum Curator"],
    educationRequired: "Master's Degree",
    jobOutlook: "Salary:",
    industry: ["Arts & Entertainment", "Government", "Education"],
    salary: "$60,380",
  },
  {
    img: img3,
    jobTitle: "Customer Service Representatives",
    jobTitleAlternatives: [
      "Account Representative",
      "Customer Service Representative (CSR)",
      "Customer Service Specialist",
      "Member Services Representative (Member Services Rep)",
    ],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Finance & Insurance", "Administration & Support Services", "Retail"],
    salary: "$37,780",
  },
  {
    img: img3,
    jobTitle: "Customs & Border Protection Officers",
    jobTitleAlternatives: ["Customs Inspector", "Customs Officer", "Inspector", "Special Agent"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Government"],
    salary: "$65,790",
  },
  {
    img: img3,
    jobTitle: "Customs Brokers",
    jobTitleAlternatives: ["Corporate Licensed Broker", "Customs Broker"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Government", "Finance & Insurance"],
    salary: "$71,690",
  },
  {
    img: img3,
    jobTitle: "Cutters & Trimmers, Hand",
    jobTitleAlternatives: ["Cloth Cutter", "Leather Cutter", "Offline Cutter", "Trimmer"],
    educationRequired: "No High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing"],
    salary: "$36,130",
  },
  {
    img: img3,
    jobTitle: "Cutting & Slicing Machine Setters, Operators, & Tenders",
    jobTitleAlternatives: ["Cutter", "Cutter Operator", "Machine Operator", "Paper Cutter"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing"],
    salary: "$39,880",
  },
  {
    img: img3,
    jobTitle: "Cutting, Punching, & Press Machine Setters, Operators, & Tenders, Metal & Plastic",
    jobTitleAlternatives: ["Machine Operator", "Press Operator", "Saw Operator", "Set-Up Operator"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing"],
    salary: "$39,340",
  },
  {
    img: img3,
    jobTitle: "Cytogenetic Technologists",
    jobTitleAlternatives: [
      "Clinical Cytogeneticist Scientist (CCS)",
      "Cytogenetic Technologist",
      "Cytogenetics Clinical Laboratory Specialist (CG CLSp)",
      "Molecular Genetics Technologist",
    ],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling"],
    salary: "$57,380",
  },
  {
    img: img3,
    jobTitle: "Cytotechnologists",
    jobTitleAlternatives: ["Cytologist", "Cytology Applications Specialist", "Cytology Coordinator", "Cytotechnologist"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling"],
    salary: "$57,380",
  },
  {
    img: img3,
    jobTitle: "Dancers",
    jobTitleAlternatives: ["Ballet Dancer", "Company Dancer", "Dancer", "Soloist Dancer"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Arts & Entertainment", "Education"],
    salary: "$21.64",
  },
  {
    img: img3,
    jobTitle: "Data Entry Keyers",
    jobTitleAlternatives: ["Data Capture Specialist", "Data Entry Clerk", "Data Entry Operator", "Data Transcriber"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Administration & Support Services", "Professional, Science, & Technical"],
    salary: "$36,190",
  },
  {
    img: img3,
    jobTitle: "Data Scientists",
    jobTitleAlternatives: [[]],
    educationRequired: "Master's Degree",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Finance & Insurance", "Media & Communication", "Management"],
    salary: "$103,500",
  },
  {
    img: img3,
    jobTitle: "Data Warehousing Specialists",
    jobTitleAlternatives: ["Data Warehouse Analyst", "Data Warehouse Solution Architect"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Media & Communication", "Finance & Insurance"],
    salary: "$134,870",
  },
  {
    img: img3,
    jobTitle: "Database Administrators",
    jobTitleAlternatives: ["Database Administrator (DBA)", "Database Analyst", "Database Coordinator", "Information Systems Manager"],
    educationRequired: "bachelor's degree or professional degree usually needed",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Finance & Insurance", "Media & Communication"],
    salary: "$99,890",
  },
  {
    img: img3,
    jobTitle: "Database Architects",
    jobTitleAlternatives: ["Database Analyst", "Database Architect", "Database Developer", "Information Technology Architect (IT Architect)"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Media & Communication", "Finance & Insurance"],
    salary: "$134,870",
  },
  {
    img: img3,
    jobTitle: "Demonstrators & Product Promoters",
    jobTitleAlternatives: ["Demonstrator", "In Store Demonstrator", "Merchandiser", "Product Demonstrator"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing", "Professional, Science, & Technical", "Retail"],
    salary: "$34,770",
  },
  {
    img: img3,
    jobTitle: "Dental Assistants",
    jobTitleAlternatives: [
      "Certified Dental Assistant (CDA)",
      "Dental Assistant (DA)",
      "Expanded Duty Dental Assistant (EDDA)",
      "Registered Dental Assistant (RDA)",
    ],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling"],
    salary: "$44,820",
  },
  {
    img: img3,
    jobTitle: "Dental Hygienists",
    jobTitleAlternatives: ["Dental Hygienist", "Hygienist", "Licensed Dental Hygienist", "Registered Dental Hygienist (RDH)"],
    educationRequired: "associate's degree or professional degree usually needed",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling"],
    salary: "$81,400",
  },
  {
    img: img3,
    jobTitle: "Dental Laboratory Technicians",
    jobTitleAlternatives: [
      "Dental Ceramist",
      "Dental Laboratory Technician (Dental Lab Tech)",
      "Dental Technician (Dental Tech)",
      "Denture Technician (Denture Tech)",
    ],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing", "Health & Counseling"],
    salary: "$46,050",
  },
  {
    img: img3,
    jobTitle: "Dentists, General",
    jobTitleAlternatives: ["Dentist", "Family Dentist", "General Dentist", "Pediatric Dentist"],
    educationRequired: "Doctoral Degree",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling"],
    salary: "$155,040",
  },
  {
    img: img3,
    jobTitle: "Dermatologists",
    jobTitleAlternatives: ["Board Certified Dermatologist", "Dermatologist Physician", "MD (Medical Doctor)", "Mohs Surgeon"],
    educationRequired: "Doctoral Degree",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling"],
    salary: "$239,200+",
  },
  {
    img: img3,
    jobTitle: "Derrick Operators, Oil & Gas",
    jobTitleAlternatives: ["Derrick Hand", "Derrick Man", "Derrick Operator", "Floor Hand"],
    educationRequired: "No High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Mining, Oil, & Gas"],
    salary: "$51,220",
  },
  {
    img: img3,
    jobTitle: "Desktop Publishers",
    jobTitleAlternatives: ["Compositor", "Computer Typesetter", "Electronic Console Display Operator", "Publisher"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Media & Communication", "Professional, Science, & Technical"],
    salary: "$47,910",
  },
  {
    img: img3,
    jobTitle: "Detectives & Criminal Investigators",
    jobTitleAlternatives: ["Criminal Investigator", "Detective", "Investigator", "Special Agent"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Government"],
    salary: "$86,280",
  },
  {
    img: img3,
    jobTitle: "Diagnostic Medical Sonographers",
    jobTitleAlternatives: ["Diagnostic Medical Sonographer", "Registered Diagnostic Medical Sonographer (RDMS)", "Sonographer", "Ultrasonographer"],
    educationRequired: "associate's degree or certificate after high school usually needed",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling"],
    salary: "$81,350",
  },
  {
    img: img3,
    jobTitle: "Dietetic Technicians",
    jobTitleAlternatives: ["Diet Tech (Dietetic Technician)", "Diet Technician Registered (DTR)", "Dietary Aide", "Nutrition Technician"],
    educationRequired: "associate's degree or certificate after high school usually needed",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling"],
    salary: "$33,960",
  },
  {
    img: img3,
    jobTitle: "Dietitians & Nutritionists",
    jobTitleAlternatives: ["Clinical Dietitian", "Dietitian", "Nutritionist", "Registered Dietitian"],
    educationRequired: "certificate after college or master's degree usually needed",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling", "Government"],
    salary: "$66,450",
  },
  {
    img: img3,
    jobTitle: "Digital Forensics Analysts",
    jobTitleAlternatives: [[]],
    educationRequired: "Master's Degree",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Government"],
    salary: "$98,740",
  },
  {
    img: img3,
    jobTitle: "Dining Room & Cafeteria Attendants & Bartender Helpers",
    jobTitleAlternatives: ["Barback", "Bus Boy", "Bus Person", "Busser"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Hotel & Food", "Education"],
    salary: "$29,120",
  },
  {
    img: img3,
    jobTitle: "Directors, Religious Activities & Education",
    jobTitleAlternatives: ["Children's Ministries Director", "Christian Education Director", "Religious Education Director", "Youth Director"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Service"],
    salary: "$49,380",
  },
  {
    img: img3,
    jobTitle: "Disc Jockeys",
    jobTitleAlternatives: [[]],
    educationRequired: "Associate's Degree",
    jobOutlook: "Salary:",
    industry: ["Arts & Entertainment", "Hotel & Food"],
    salary: "$21.34",
  },
  {
    img: img3,
    jobTitle: "Dishwashers",
    jobTitleAlternatives: ["Dish Machine Operator (DMO)", "Dishwasher", "Kitchen Steward", "Steward"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Hotel & Food"],
    salary: "$29,080",
  },
  {
    img: img3,
    jobTitle: "Dispatchers",
    jobTitleAlternatives: ["City Dispatcher", "Dispatcher (Dispatch)", "Taxi Dispatcher", "Train Dispatcher"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Transportation & Storage", "Administration & Support Services"],
    salary: "$44,830",
  },
  {
    img: img3,
    jobTitle: "Document Management Specialists",
    jobTitleAlternatives: [
      "Document Control Manager",
      "ECM Consultant (Enterprise Content Management Consultant)",
      "Electronic Content Manager",
      "Records Manager",
    ],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Government"],
    salary: "$98,740",
  },
  {
    img: img3,
    jobTitle: "Door-to-Door Sales Workers, News & Street Vendors, & Related Workers",
    jobTitleAlternatives: ["Direct Sales Coach", "Door-to-Door Sales Trainer", "Independent Sales Associate", "Independent Sales Representative"],
    educationRequired: "No High School Diploma",
    jobOutlook: "Salary:",
    industry: [],
    salary: "$31,100",
  },
  {
    img: img3,
    jobTitle: "Dredge Operators",
    jobTitleAlternatives: ["Dredge Operator", "Dredger"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Construction", "Mining, Oil, & Gas"],
    salary: "$47,090",
  },
  {
    img: img3,
    jobTitle: "Drilling & Boring Machine Tool Setters, Operators, & Tenders, Metal & Plastic",
    jobTitleAlternatives: ["CNC Machinist (Computer Numerical Control Machinist)", "Drill Operator", "Drill Setup Operator", "Machine Operator"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing", "Construction"],
    salary: "$42,450",
  },
  {
    img: img3,
    jobTitle: "Driver/Sales Workers",
    jobTitleAlternatives: ["Driver", "Driver Salesman", "Route Driver", "Route Salesman"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Hotel & Food", "Wholesale/Commercial Sales", "Retail"],
    salary: "$32,690",
  },
  {
    img: img3,
    jobTitle: "Drywall & Ceiling Tile Installers",
    jobTitleAlternatives: ["Drywall Finisher", "Drywall Hanger", "Drywall Installer", "Metal Framer"],
    educationRequired: "No High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Construction"],
    salary: "$50,440",
  },
  {
    img: img3,
    jobTitle: "Earth Drillers",
    jobTitleAlternatives: ["Blast Hole Driller", "Drill Operator", "Driller", "Well Driller"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Construction", "Mining, Oil, & Gas"],
    salary: "$51,740",
  },
  {
    img: img3,
    jobTitle: "Economics Teachers, Postsecondary",
    jobTitleAlternatives: ["Economics Instructor", "Economics Professor", "Instructor", "Professor"],
    educationRequired: "Doctoral Degree",
    jobOutlook: "Salary:",
    industry: ["Education"],
    salary: "$103,930",
  },
  {
    img: img3,
    jobTitle: "Economists",
    jobTitleAlternatives: ["Economic Analyst", "Economic Consultant", "Economist", "Research Associate"],
    educationRequired: "Doctoral Degree",
    jobOutlook: "Salary:",
    industry: ["Government", "Professional, Science, & Technical"],
    salary: "$113,940",
  },
  {
    img: img3,
    jobTitle: "Editors",
    jobTitleAlternatives: ["Editor", "News Editor", "Newspaper Copy Editor", "Sports Editor"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Media & Communication", "Professional, Science, & Technical"],
    salary: "$73,080",
  },
  {
    img: img3,
    jobTitle: "Education & Childcare Administrators, Preschool & Daycare",
    jobTitleAlternatives: ["Education Director", "Preschool Director", "Principal", "Site Coordinator"],
    educationRequired: "Associate's Degree",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling", "Service"],
    salary: "$49,690",
  },
  {
    img: img3,
    jobTitle: "Education Administrators, Kindergarten through Secondary",
    jobTitleAlternatives: ["Elementary Principal", "Middle School Principal", "Principal", "Superintendent"],
    educationRequired: "certificate after master's or master's degree usually needed",
    jobOutlook: "Salary:",
    industry: ["Education"],
    salary: "$101,320",
  },
  {
    img: img3,
    jobTitle: "Education Administrators, Postsecondary",
    jobTitleAlternatives: ["Academic Affairs Vice President (Academic Affairs VP)", "Academic Dean", "Dean", "Registrar"],
    educationRequired: "Master's Degree",
    jobOutlook: "Salary:",
    industry: ["Education"],
    salary: "$99,940",
  },
  {
    img: img3,
    jobTitle: "Education Teachers, Postsecondary",
    jobTitleAlternatives: ["Assistant Professor", "Associate Professor", "Education Professor", "Professor"],
    educationRequired: "Doctoral Degree",
    jobOutlook: "Salary:",
    industry: ["Education"],
    salary: "$66,930",
  },
  {
    img: img3,
    jobTitle: "Educational, Guidance, & Career Counselors & Advisors",
    jobTitleAlternatives: ["Academic Advisor", "Career Counselor", "Guidance Counselor", "School Counselor"],
    educationRequired: "Master's Degree",
    jobOutlook: "Salary:",
    industry: ["Education"],
    salary: "$60,140",
  },
  {
    img: img3,
    jobTitle: "Electric Motor, Power Tool, & Related Repairers",
    jobTitleAlternatives: ["Electric Motor Winder", "Maintenance Technician", "Repair Technician", "Service Technician"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Wholesale/Commercial Sales", "Service"],
    salary: "$48,260",
  },
  {
    img: img3,
    jobTitle: "Electrical & Electronic Engineering Technologists & Technicians",
    jobTitleAlternatives: [
      "Electronics Engineering Technician",
      "Engineering Technician (Engineering Tech)",
      "Engineering Technologist",
      "Technologist",
    ],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Manufacturing", "Professional, Science, & Technical", "Government"],
    salary: "$66,390",
  },
  {
    img: img3,
    jobTitle: "Electrical & Electronic Equipment Assemblers",
    jobTitleAlternatives: ["Assembler", "Electrical Assembler", "Electronics Assembler", "Transformer Assembler"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing"],
    salary: "$38,580",
  },
  {
    img: img3,
    jobTitle: "Electrical & Electronics Drafters",
    jobTitleAlternatives: ["Computer-Aided Design Operator", "Drafter", "Electrical Designer", "Staking Technician (Staking Tech)"],
    educationRequired: "bachelor's degree or associate's degree usually needed",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Manufacturing", "Construction", "Utilities"],
    salary: "$64,240",
  },
  {
    img: img3,
    jobTitle: "Electrical & Electronics Installers & Repairers, Transportation Equipment",
    jobTitleAlternatives: ["Critical Systems Technician", "Electronic Bench Technician", "Locomotive Electrician", "Power Technician (Power Tech)"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Transportation & Storage", "Government", "Manufacturing"],
    salary: "$73,630",
  },
  {
    img: img3,
    jobTitle: "Electrical & Electronics Repairers, Commercial & Industrial Equipment",
    jobTitleAlternatives: [
      "Control Technician",
      "Electrical and Instrument Technician (E and I Tech)",
      "Electronic Technician",
      "I and C Tech (Instrument and Control Technician)",
    ],
    educationRequired: "associate's degree or certificate after high school usually needed",
    jobOutlook: "Salary:",
    industry: ["Manufacturing", "Government"],
    salary: "$64,030",
  },
  {
    img: img3,
    jobTitle: "Electrical & Electronics Repairers, Powerhouse, Substation, & Relay",
    jobTitleAlternatives: ["Relay Technician", "Substation Electrician", "Substation Technician", "Wireman"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Utilities", "Government"],
    salary: "$93,720",
  },
  {
    img: img3,
    jobTitle: "Electrical Engineers",
    jobTitleAlternatives: ["Design Engineer", "Electrical Engineer", "Project Engineer", "Test Engineer"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Manufacturing", "Utilities"],
    salary: "$103,320",
  },
  {
    img: img3,
    jobTitle: "Electrical Power-Line Installers & Repairers",
    jobTitleAlternatives: ["Electrical Lineman", "Lineworker", "Power Lineman", "Service Man"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Utilities", "Construction", "Government"],
    salary: "$82,340",
  },
  {
    img: img3,
    jobTitle: "Electricians",
    jobTitleAlternatives: ["Electrician", "Industrial Electrician", "Inside Wireman", "Maintenance Electrician"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Construction"],
    salary: "$60,240",
  },
  {
    img: img3,
    jobTitle: "Electro-Mechanical & Mechatronics Technologists & Technicians",
    jobTitleAlternatives: [
      "Electro-Mechanic",
      "Electromechanical Technician (EM Technician)",
      "Electronics Technician (Electronics Tech)",
      "Mechanical Technician (Mechanical Tech)",
    ],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing", "Professional, Science, & Technical"],
    salary: "$60,570",
  },
  {
    img: img3,
    jobTitle: "Electromechanical Equipment Assemblers",
    jobTitleAlternatives: ["Assembler", "Electronic Assembler", "Electronic Technician", "Mechanical Assembler"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing"],
    salary: "$38,580",
  },
  {
    img: img3,
    jobTitle: "Electronic Equipment Installers & Repairers, Motor Vehicles",
    jobTitleAlternatives: ["Automotive Technician", "Car Audio Installer", "Car Stereo Installer", "Mobile Electronics Installation Specialist"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Retail", "Service", "Manufacturing", "Wholesale/Commercial Sales"],
    salary: "$41,600",
  },
  {
    img: img3,
    jobTitle: "Electronics Engineers",
    jobTitleAlternatives: ["Design Engineer", "Integrated Circuit Design Engineer (IC Design Engineer)", "Product Engineer", "Test Engineer"],
    educationRequired: "bachelor's degree or associate's degree usually needed",
    jobOutlook: "Salary:",
    industry: ["Manufacturing", "Professional, Science, & Technical", "Media & Communication", "Government"],
    salary: "$108,170",
  },
  {
    img: img3,
    jobTitle: "Elementary School Teachers",
    jobTitleAlternatives: ["Classroom Teacher", "Elementary School Teacher", "Elementary Teacher", "Teacher"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Education"],
    salary: "$61,690",
  },
  {
    img: img3,
    jobTitle: "Elevator & Escalator Installers & Repairers",
    jobTitleAlternatives: ["Elevator Adjuster", "Elevator Mechanic", "Elevator Service Mechanic", "Elevator Service Technician"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Construction"],
    salary: "$99,000",
  },
  {
    img: img3,
    jobTitle: "Eligibility Interviewers, Government Programs",
    jobTitleAlternatives: ["Case Manager", "Eligibility Specialist", "Program Eligibility Specialist", "Workforce Services Representative (WSR)"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Government"],
    salary: "$49,230",
  },
  {
    img: img3,
    jobTitle: "Embalmers",
    jobTitleAlternatives: ["Embalmer", "Licensed Embalmer", "Trade Embalmer"],
    educationRequired: "Associate's Degree",
    jobOutlook: "Salary:",
    industry: ["Service"],
    salary: "$49,910",
  },
  {
    img: img3,
    jobTitle: "Emergency Management Directors",
    jobTitleAlternatives: [
      "Emergency Management Coordinator",
      "Emergency Management System Director (EMS Director)",
      "Emergency Planner",
      "Emergency Services Director",
    ],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Government"],
    salary: "$79,180",
  },
  {
    img: img3,
    jobTitle: "Emergency Medical Technicians",
    jobTitleAlternatives: ["Emergency Medical Technician (EMT)", "First Responder"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling", "Government"],
    salary: "$36,680",
  },
  {
    img: img3,
    jobTitle: "Emergency Medicine Physicians",
    jobTitleAlternatives: [[]],
    educationRequired: "Doctoral Degree",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling"],
    salary: "$239,200+",
  },
  {
    img: img3,
    jobTitle: "Endoscopy Technicians",
    jobTitleAlternatives: [
      "Certified Endoscopy Technician",
      "Certified Flex Endoscope Reprocessor",
      "Endoscopy Technician (Endo Technician)",
      "Gastrointestinal Technician (GI Technician)",
    ],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling", "Government", "Education"],
    salary: "$40,420",
  },
  {
    img: img3,
    jobTitle: "Energy Auditors",
    jobTitleAlternatives: ["Energy Auditor", "Energy Consultant", "Energy Rater", "Home Performance Consultant"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Government", "Professional, Science, & Technical"],
    salary: "$64,480",
  },
  {
    img: img3,
    jobTitle: "Energy Engineers",
    jobTitleAlternatives: ["Energy Efficiency Engineer", "Energy Engineer", "Industrial Energy Engineer", "Test and Balance Engineer"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Government", "Manufacturing"],
    salary: "$104,600",
  },
  {
    img: img3,
    jobTitle: "Engine & Other Machine Assemblers",
    jobTitleAlternatives: ["Assembler", "Assembly Line Worker", "Engine Assembler", "Machine Assembler"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing"],
    salary: "$50,850",
  },
  {
    img: img3,
    jobTitle: "Engineering Teachers, Postsecondary",
    jobTitleAlternatives: ["Assistant Professor", "Associate Professor", "Instructor", "Professor"],
    educationRequired: "Doctoral Degree",
    jobOutlook: "Salary:",
    industry: ["Education"],
    salary: "$103,550",
  },
  {
    img: img3,
    jobTitle: "English Language & Literature Teachers, Postsecondary",
    jobTitleAlternatives: ["English Instructor", "English Professor", "Instructor", "Professor"],
    educationRequired: "Doctoral Degree",
    jobOutlook: "Salary:",
    industry: ["Education"],
    salary: "$74,280",
  },
  {
    img: img3,
    jobTitle: "Entertainment & Recreation Managers",
    jobTitleAlternatives: [[]],
    educationRequired: "Master's Degree",
    jobOutlook: "Salary:",
    industry: ["Arts & Entertainment", "Government"],
    salary: "$67,220",
  },
  {
    img: img3,
    jobTitle: "Environmental Compliance Inspectors",
    jobTitleAlternatives: ["Enforcement Officer", "Environmental Inspector", "Environmental Protection Specialist", "Environmental Quality Analyst"],
    educationRequired: "bachelor's degree or associate's degree usually needed",
    jobOutlook: "Salary:",
    industry: ["Government", "Finance & Insurance"],
    salary: "$71,690",
  },
  {
    img: img3,
    jobTitle: "Environmental Economists",
    jobTitleAlternatives: ["Economist", "Environmental Economist", "Natural Resource Economist", "Resource Economist"],
    educationRequired: "Doctoral Degree",
    jobOutlook: "Salary:",
    industry: ["Government", "Professional, Science, & Technical"],
    salary: "$113,940",
  },
  {
    img: img3,
    jobTitle: "Environmental Engineering Technologists & Technicians",
    jobTitleAlternatives: [
      "Air Quality Instrument Specialist",
      "Environmental Field Technician",
      "Environmental Technician",
      "Haz Tech (Hazardous Technician)",
    ],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Government", "Administration & Support Services"],
    salary: "$50,980",
  },
  {
    img: img3,
    jobTitle: "Environmental Engineers",
    jobTitleAlternatives: ["Engineer", "Environmental Engineer", "Hazardous Substances Engineer", "Sanitary Engineer"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Government"],
    salary: "$96,530",
  },
  {
    img: img3,
    jobTitle: "Environmental Restoration Planners",
    jobTitleAlternatives: [
      "Coastal and Estuary Specialist",
      "Habitat Restoration Specialist",
      "Marine Habitat Resources Specialist",
      "Restoration Ecologist",
    ],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Government", "Professional, Science, & Technical"],
    salary: "$76,480",
  },
  {
    img: img3,
    jobTitle: "Environmental Science & Protection Technicians, Including Health",
    jobTitleAlternatives: ["Environmental Technician (Environmental Tech)", "Laboratory Technician", "Public Health Sanitarian", "Sanitarian"],
    educationRequired: "bachelor's degree or associate's degree usually needed",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Government"],
    salary: "$48,380",
  },
  {
    img: img3,
    jobTitle: "Environmental Science Teachers, Postsecondary",
    jobTitleAlternatives: ["Assistant Professor", "Associate Professor", "Environmental Sciences Professor", "Professor"],
    educationRequired: "Doctoral Degree",
    jobOutlook: "Salary:",
    industry: ["Education"],
    salary: "$83,040",
  },
  {
    img: img3,
    jobTitle: "Environmental Scientists & Specialists, Including Health",
    jobTitleAlternatives: [
      "Environmental Programs Specialist",
      "Environmental Protection Specialist",
      "Environmental Scientist",
      "Environmental Specialist",
    ],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Government", "Professional, Science, & Technical"],
    salary: "$76,480",
  },
  {
    img: img3,
    jobTitle: "Epidemiologists",
    jobTitleAlternatives: ["Epidemiologist", "Infection Control Practitioner (ICP)", "Nurse Epidemiologist", "Research Epidemiologist"],
    educationRequired: "Master's Degree",
    jobOutlook: "Salary:",
    industry: ["Government", "Health & Counseling"],
    salary: "$78,520",
  },
  {
    img: img3,
    jobTitle: "Equal Opportunity Representatives & Officers",
    jobTitleAlternatives: [
      "Affirmative Action Officer (AA Officer)",
      "Civil Rights Investigator",
      "Equal Employment Opportunity Officer (EEO Officer)",
      "Equal Opportunity Specialist",
    ],
    educationRequired: "bachelor's degree or some college usually needed",
    jobOutlook: "Salary:",
    industry: ["Government", "Finance & Insurance"],
    salary: "$71,690",
  },
  {
    img: img3,
    jobTitle: "Etchers & Engravers",
    jobTitleAlternatives: ["Chemical Engraver", "Engraver", "Etcher", "Photo Engraver"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing", "Retail"],
    salary: "$37,980",
  },
  {
    img: img3,
    jobTitle: "Excavating & Loading Machine & Dragline Operators, Surface Mining",
    jobTitleAlternatives: ["Dragline Oiler", "Dragline Operator", "Heavy Equipment Operator", "Loader Operator"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Mining, Oil, & Gas", "Construction"],
    salary: "$48,350",
  },
  {
    img: img3,
    jobTitle: "Executive Secretaries & Executive Administrative Assistants",
    jobTitleAlternatives: ["Administrative Assistant", "Administrative Secretary", "Executive Assistant", "Executive Secretary"],
    educationRequired: "Associate's Degree",
    jobOutlook: "Salary:",
    industry: ["Education", "Government", "Professional, Science, & Technical", "Finance & Insurance"],
    salary: "$65,980",
  },
  {
    img: img3,
    jobTitle: "Exercise Physiologists",
    jobTitleAlternatives: [
      "Clinical Exercise Physiologist",
      "Exercise Physiologist",
      "Exercise Specialist",
      "Lifestyle and Weight Management Consultant",
    ],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling"],
    salary: "$51,350",
  },
  {
    img: img3,
    jobTitle: "Exercise Trainers & Group Fitness Instructors",
    jobTitleAlternatives: ["Aerobics Instructor", "Fitness Instructor", "Group Fitness Instructor", "Personal Trainer"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Arts & Entertainment"],
    salary: "$45,380",
  },
  {
    img: img3,
    jobTitle: "Explosives Workers, Ordnance Handling Experts, & Blasters",
    jobTitleAlternatives: ["Blast Hole Driller", "Blaster", "Explosive Technician", "Powderman"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Government", "Mining, Oil, & Gas", "Construction", "Wholesale/Commercial Sales"],
    salary: "$56,670",
  },
  {
    img: img3,
    jobTitle: "Extruding & Drawing Machine Setters, Operators, & Tenders, Metal & Plastic",
    jobTitleAlternatives: ["Equipment Technician", "Extruder Operator", "Extrusion Press Operator", "Machine Operator"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing"],
    salary: "$39,970",
  },
  {
    img: img3,
    jobTitle: "Extruding & Forming Machine Setters, Operators, & Tenders, Synthetic & Glass Fibers",
    jobTitleAlternatives: ["Extruder Operator", "Extrusion Line Operator", "Extrusion Operator", "Spindraw Operator"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing"],
    salary: "$39,860",
  },
  {
    img: img3,
    jobTitle: "Extruding, Forming, Pressing, & Compacting Machine Setters, Operators, & Tenders",
    jobTitleAlternatives: ["Extruder Operator", "Extrusion Operator", "Machine Operator", "Press Operator"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing"],
    salary: "$39,480",
  },
  {
    img: img3,
    jobTitle: "Fabric & Apparel Patternmakers",
    jobTitleAlternatives: ["Designer", "Pattern Designer", "Pattern Maker", "Production Pattern Maker"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing", "Management"],
    salary: "$60,320",
  },
  {
    img: img3,
    jobTitle: "Facilities Managers",
    jobTitleAlternatives: ["Facilities Manager"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Education", "Government", "Health & Counseling", "Manufacturing"],
    salary: "$99,030",
  },
  {
    img: img3,
    jobTitle: "Fallers",
    jobTitleAlternatives: ["Logger", "Timber Cutter", "Timber Faller", "Tree Faller"],
    educationRequired: "No High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Farming, Forestry, Fishing, & Hunting", "Administration & Support Services"],
    salary: "$49,160",
  },
  {
    img: img3,
    jobTitle: "Family & Consumer Sciences Teachers, Postsecondary",
    jobTitleAlternatives: ["Assistant Professor", "Associate Professor", "Instructor", "Professor"],
    educationRequired: "Doctoral Degree",
    jobOutlook: "Salary:",
    industry: ["Education"],
    salary: "$76,440",
  },
  {
    img: img3,
    jobTitle: "Family Medicine Physicians",
    jobTitleAlternatives: ["Family Physician", "Family Practice Physician (FP Physician)", "Medical Doctor (MD)", "Physician"],
    educationRequired: "Doctoral Degree",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling"],
    salary: "$211,300",
  },
  {
    img: img3,
    jobTitle: "Farm & Home Management Educators",
    jobTitleAlternatives: [
      "4-H Youth Development Specialist",
      "Extension Agent",
      "Extension Educator",
      "Family and Consumer Sciences Extension Agent",
    ],
    educationRequired: "Master's Degree",
    jobOutlook: "Salary:",
    industry: ["Education", "Government"],
    salary: "$53,450",
  },
  {
    img: img3,
    jobTitle: "Farm Equipment Mechanics & Service Technicians",
    jobTitleAlternatives: ["Agricultural Technician", "Farm Equipment Mechanic", "Mechanic", "Tractor Mechanic"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Wholesale/Commercial Sales", "Farming, Forestry, Fishing, & Hunting"],
    salary: "$48,010",
  },
  {
    img: img3,
    jobTitle: "Farm Labor Contractors",
    jobTitleAlternatives: ["Farm Labor Contractor", "Field Manager", "Field Supervisor"],
    educationRequired: "No High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Farming, Forestry, Fishing, & Hunting"],
    salary: "$49,330",
  },
  {
    img: img3,
    jobTitle: "Farmers, Ranchers, & Other Agricultural Managers",
    jobTitleAlternatives: ["Aquaculture Director", "Farm Manager", "Greenhouse Manager", "Ranch Manager"],
    educationRequired: "Master's Degree",
    jobOutlook: "Salary:",
    industry: ["Farming, Forestry, Fishing, & Hunting"],
    salary: "$75,760",
  },
  {
    img: img3,
    jobTitle: "Farmworkers & Laborers, Crop, Nursery, & Greenhouse",
    jobTitleAlternatives: ["Greenhouse Worker", "Grower", "Harvester", "Nursery Worker"],
    educationRequired: "No High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Farming, Forestry, Fishing, & Hunting"],
    salary: "$33,000",
  },
  {
    img: img3,
    jobTitle: "Farmworkers, Farm, Ranch, & Aquacultural Animals",
    jobTitleAlternatives: ["Cowboy", "Herdsman", "Livestock Handler", "Ranch Hand"],
    educationRequired: "some college or high school diploma/GED usually needed",
    jobOutlook: "Salary:",
    industry: ["Farming, Forestry, Fishing, & Hunting"],
    salary: "$34,150",
  },
  {
    img: img3,
    jobTitle: "Fashion Designers",
    jobTitleAlternatives: ["Costume Designer", "Designer", "Fashion Designer", "Product Developer"],
    educationRequired: "associate's degree or some college usually needed",
    jobOutlook: "Salary:",
    industry: ["Wholesale/Commercial Sales", "Management", "Retail", "Manufacturing"],
    salary: "$76,700",
  },
  {
    img: img3,
    jobTitle: "Fast Food & Counter Workers",
    jobTitleAlternatives: ["Cafe Server", "Cafeteria Server", "Food Service Assistant", "Food Service Worker"],
    educationRequired: "No High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Hotel & Food"],
    salary: "$27,930",
  },
  {
    img: img3,
    jobTitle: "Fence Erectors",
    jobTitleAlternatives: ["Fence Builder", "Fence Erector", "Fence Installer", "Fence Laborer"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Construction"],
    salary: "$39,840",
  },
  {
    img: img3,
    jobTitle: "Fiberglass Laminators & Fabricators",
    jobTitleAlternatives: ["Boat Builder", "Chopper Gun Operator", "Fiberglass Laminator", "Laminator"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing"],
    salary: "$38,110",
  },
  {
    img: img3,
    jobTitle: "File Clerks",
    jobTitleAlternatives: ["File Clerk", "Medical Records Clerk", "Office Assistant", "Records Clerk"],
    educationRequired: "high school diploma/GED or master's degree usually needed",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling", "Government", "Professional, Science, & Technical"],
    salary: "$37,290",
  },
  {
    img: img3,
    jobTitle: "Film & Video Editors",
    jobTitleAlternatives: ["Editor", "Film Editor", "News Editor", "Video Editor"],
    educationRequired: "bachelor's degree or associate's degree usually needed",
    jobOutlook: "Salary:",
    industry: ["Media & Communication"],
    salary: "$63,520",
  },
  {
    img: img3,
    jobTitle: "Financial & Investment Analysts",
    jobTitleAlternatives: ["Analyst", "Financial Analyst", "Investment Analyst", "Securities Analyst"],
    educationRequired: "Master's Degree",
    jobOutlook: "Salary:",
    industry: ["Finance & Insurance", "Professional, Science, & Technical", "Management"],
    salary: "$95,080",
  },
  {
    img: img3,
    jobTitle: "Financial Examiners",
    jobTitleAlternatives: ["Bank Examiner", "Compliance Officer", "Credit Union Examiner", "Examining Officer"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Finance & Insurance", "Government"],
    salary: "$82,210",
  },
  {
    img: img3,
    jobTitle: "Financial Managers",
    jobTitleAlternatives: ["Banking Center Manager (BCM)", "Branch Manager", "Credit Administration Manager", "Financial Center Manager"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Finance & Insurance", "Professional, Science, & Technical", "Management"],
    salary: "$139,790",
  },
  {
    img: img3,
    jobTitle: "Financial Quantitative Analysts",
    jobTitleAlternatives: ["Investment Strategist", "Portfolio Manager", "Quantitative Analyst", "Quantitative Strategy Analyst"],
    educationRequired: "Master's Degree",
    jobOutlook: "Salary:",
    industry: ["Finance & Insurance", "Government"],
    salary: "$73,810",
  },
  {
    img: img3,
    jobTitle: "Financial Risk Specialists",
    jobTitleAlternatives: ["Analyst", "Risk Analyst", "Risk Specialist", "Securities Analyst"],
    educationRequired: "Master's Degree",
    jobOutlook: "Salary:",
    industry: ["Finance & Insurance", "Management", "Professional, Science, & Technical"],
    salary: "$102,120",
  },
  {
    img: img3,
    jobTitle: "Fine Artists, Including Painters, Sculptors, & Illustrators",
    jobTitleAlternatives: ["Artist", "Fine Artist", "Painter", "Sculptor"],
    educationRequired: "some college or bachelor's degree usually needed",
    jobOutlook: "Salary:",
    industry: ["Arts & Entertainment"],
    salary: "$57,560",
  },
  {
    img: img3,
    jobTitle: "Fire Inspectors & Investigators",
    jobTitleAlternatives: ["Arson Investigator", "Fire Inspector", "Fire Investigator", "Fire Prevention Inspector"],
    educationRequired: "certificate after high school or some college usually needed",
    jobOutlook: "Salary:",
    industry: ["Government"],
    salary: "$69,450",
  },
  {
    img: img3,
    jobTitle: "Fire-Prevention & Protection Engineers",
    jobTitleAlternatives: ["Consulting Engineer", "Engineer", "Fire Protection Consultant", "Fire Protection Engineer (FP Engineer)"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Manufacturing", "Professional, Science, & Technical", "Government", "Construction"],
    salary: "$100,660",
  },
  {
    img: img3,
    jobTitle: "Firefighters",
    jobTitleAlternatives: ["Fire Engineer", "Fire Equipment Operator", "Firefighter", "Wildland Firefighter"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Government"],
    salary: "$51,680",
  },
  {
    img: img3,
    jobTitle: "First-Line Supervisors of Construction Trades & Extraction Workers",
    jobTitleAlternatives: ["Construction Foreman", "Construction Supervisor", "Electrical Supervisor", "Roustabout Field Supervisor"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Construction"],
    salary: "$74,080",
  },
  {
    img: img3,
    jobTitle: "First-Line Supervisors of Correctional Officers",
    jobTitleAlternatives: ["Correctional Officer Captain", "Correctional Supervisor"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Government"],
    salary: "$63,310",
  },
  {
    img: img3,
    jobTitle: "First-Line Supervisors of Entertainment & Recreation Workers",
    jobTitleAlternatives: ["Caddymaster", "Community Life Director", "Hair Salon Manager", "Recreation Coordinator"],
    educationRequired: "Associate's Degree",
    jobOutlook: "Salary:",
    industry: ["Arts & Entertainment", "Government"],
    salary: "$44,660",
  },
  {
    img: img3,
    jobTitle: "First-Line Supervisors of Farming, Fishing, & Forestry Workers",
    jobTitleAlternatives: ["Farm Supervisor", "Harvesting Supervisor", "Hatchery Manager", "Logging Supervisor"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Farming, Forestry, Fishing, & Hunting"],
    salary: "$54,490",
  },
  {
    img: img3,
    jobTitle: "First-Line Supervisors of Firefighting & Prevention Workers",
    jobTitleAlternatives: ["Engine Boss", "Fire Battalion Chief", "Fire Captain", "Fire Chief"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Government"],
    salary: "$80,150",
  },
  {
    img: img3,
    jobTitle: "First-Line Supervisors of Food Preparation & Serving Workers",
    jobTitleAlternatives: ["Cafeteria Manager", "Food Service Manager", "Food Service Supervisor", "Kitchen Manager"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Hotel & Food"],
    salary: "$37,050",
  },
  {
    img: img3,
    jobTitle: "First-Line Supervisors of Gambling Services Workers",
    jobTitleAlternatives: ["Casino Shift Manager (CSM)", "Floor Supervisor", "Slot Supervisor", "Table Games Supervisor"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Government", "Hotel & Food", "Arts & Entertainment"],
    salary: "$56,290",
  },
  {
    img: img3,
    jobTitle: "First-Line Supervisors of Helpers, Laborers, & Material Movers, Hand",
    jobTitleAlternatives: ["Maintenance Supervisor", "Receiving Manager", "Receiving Supervisor", "Shipping Supervisor"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Transportation & Storage", "Retail", "Wholesale/Commercial Sales"],
    salary: "$57,860",
  },
  {
    img: img3,
    jobTitle: "First-Line Supervisors of Housekeeping & Janitorial Workers",
    jobTitleAlternatives: ["Environmental Services Supervisor (EVS)", "Executive Housekeeper", "Housekeeping Supervisor", "Maintenance Supervisor"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Administration & Support Services", "Hotel & Food"],
    salary: "$44,440",
  },
  {
    img: img3,
    jobTitle: "First-Line Supervisors of Landscaping, Lawn Service, & Groundskeeping Workers",
    jobTitleAlternatives: ["Golf Course Superintendent", "Grounds Manager", "Grounds Supervisor", "Landscape Supervisor"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Administration & Support Services"],
    salary: "$50,810",
  },
  {
    img: img3,
    jobTitle: "First-Line Supervisors of Material-Moving Machine & Vehicle Operators",
    jobTitleAlternatives: ["Driver Manager", "Fleet Manager", "Transportation Supervisor", "Warehouse Supervisor"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Transportation & Storage", "Retail", "Wholesale/Commercial Sales"],
    salary: "$57,860",
  },
  {
    img: img3,
    jobTitle: "First-Line Supervisors of Mechanics, Installers, & Repairers",
    jobTitleAlternatives: ["Maintenance Foreman", "Maintenance Manager", "Maintenance Supervisor", "Service Manager"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Service", "Construction", "Retail", "Government", "Manufacturing", "Real Estate & Rentals"],
    salary: "$73,140",
  },
  {
    img: img3,
    jobTitle: "First-Line Supervisors of Non-Retail Sales Workers",
    jobTitleAlternatives: ["Customer Service Supervisor", "Reservations Supervisor", "Sales Leader", "Sales Supervisor"],
    educationRequired: "bachelor's degree or some college usually needed",
    jobOutlook: "Salary:",
    industry: ["Wholesale/Commercial Sales", "Finance & Insurance"],
    salary: "$82,850",
  },
  {
    img: img3,
    jobTitle: "First-Line Supervisors of Office & Administrative Support Workers",
    jobTitleAlternatives: ["Accounting Manager", "Customer Service Manager", "Customer Service Supervisor", "Office Manager"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling", "Finance & Insurance"],
    salary: "$61,370",
  },
  {
    img: img3,
    jobTitle: "First-Line Supervisors of Passenger Attendants",
    jobTitleAlternatives: ["On Car Supervisor", "Transportation Supervisor"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Transportation & Storage", "Retail", "Wholesale/Commercial Sales"],
    salary: "$57,860",
  },
  {
    img: img3,
    jobTitle: "First-Line Supervisors of Personal Service Workers",
    jobTitleAlternatives: ["Adult Family Home Program Manager", "Direct Care Supervisor", "Resident Care Supervisor"],
    educationRequired: "bachelor's degree or certificate after high school usually needed",
    jobOutlook: "Salary:",
    industry: ["Service", "Health & Counseling"],
    salary: "$43,680",
  },
  {
    img: img3,
    jobTitle: "First-Line Supervisors of Police & Detectives",
    jobTitleAlternatives: ["Police Captain", "Shift Supervisor"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Government"],
    salary: "$96,290",
  },
  {
    img: img3,
    jobTitle: "First-Line Supervisors of Production & Operating Workers",
    jobTitleAlternatives: ["Assembly Supervisor", "Manufacturing Supervisor", "Production Manager", "Production Supervisor"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Manufacturing"],
    salary: "$63,510",
  },
  {
    img: img3,
    jobTitle: "First-Line Supervisors of Retail Sales Workers",
    jobTitleAlternatives: ["Department Manager", "Meat Department Manager", "Shift Manager", "Store Manager"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Retail"],
    salary: "$45,250",
  },
  {
    img: img3,
    jobTitle: "First-Line Supervisors of Security Workers",
    jobTitleAlternatives: [[]],
    educationRequired: "Associate's Degree",
    jobOutlook: "Salary:",
    industry: ["Administration & Support Services"],
    salary: "$48,810",
  },
  {
    img: img3,
    jobTitle: "Fish & Game Wardens",
    jobTitleAlternatives: ["Game Warden", "Natural Resource Officer", "State Game Warden", "Wildlife Officer"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Government"],
    salary: "$59,500",
  },
  {
    img: img3,
    jobTitle: "Fishing & Hunting Workers",
    jobTitleAlternatives: ["Commercial Fisherman", "Hunter", "Trapper", "Wildlife Control Operator"],
    educationRequired: "No High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Farming, Forestry, Fishing, & Hunting"],
    salary: "$33,970",
  },
  {
    img: img3,
    jobTitle: "Fitness & Wellness Coordinators",
    jobTitleAlternatives: ["Fitness and Wellness Coordinator", "Fitness and Wellness Director", "Fitness Coordinator", "Fitness Director"],
    educationRequired: "Master's Degree",
    jobOutlook: "Salary:",
    industry: ["Service"],
    salary: "$56,090",
  },
  {
    img: img3,
    jobTitle: "Flight Attendants",
    jobTitleAlternatives: ["Flight Attendant", "In-Flight Crew Member", "International Flight Attendant", "Purser"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Transportation & Storage"],
    salary: "$63,760",
  },
  {
    img: img3,
    jobTitle: "Floor Layers",
    jobTitleAlternatives: ["Floor Covering Contractor", "Floor Coverings Installer", "Flooring Installer", "Vinyl Installer"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Construction", "Retail"],
    salary: "$48,870",
  },
  {
    img: img3,
    jobTitle: "Floor Sanders & Finishers",
    jobTitleAlternatives: ["Floor Finisher", "Floor Mechanic", "Floor Sander", "Hardwood Floor Sander"],
    educationRequired: "No High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Construction"],
    salary: "$46,060",
  },
  {
    img: img3,
    jobTitle: "Floral Designers",
    jobTitleAlternatives: ["Designer", "Floral Clerk", "Floral Designer", "Florist"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Retail"],
    salary: "$33,160",
  },
  {
    img: img3,
    jobTitle: "Food & Tobacco Roasting, Baking, & Drying Machine Operators & Tenders",
    jobTitleAlternatives: ["Coffee Roaster", "Machine Operator", "Roaster", "Roasterman"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing"],
    salary: "$37,790",
  },
  {
    img: img3,
    jobTitle: "Food Batchmakers",
    jobTitleAlternatives: ["Batching Operator", "Blender", "Mixer", "Mixer Operator"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing"],
    salary: "$36,580",
  },
  {
    img: img3,
    jobTitle: "Food Cooking Machine Operators & Tenders",
    jobTitleAlternatives: ["Fryer Operator", "Kettle Fry Cook Operator", "Machine Operator", "Retort Operator"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing", "Hotel & Food"],
    salary: "$36,700",
  },
  {
    img: img3,
    jobTitle: "Food Preparation Workers",
    jobTitleAlternatives: ["Deli Clerk (Delicatessen Clerk)", "Diet Aide", "Dietary Aide", "Food Service Worker"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Hotel & Food", "Retail"],
    salary: "$29,790",
  },
  {
    img: img3,
    jobTitle: "Food Science Technicians",
    jobTitleAlternatives: [
      "Central Lab Technician (CLT)",
      "Quality Assurance Analyst (QA Analyst)",
      "Quality Control Technician (QC Technician)",
      "Quality Technician",
    ],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Manufacturing", "Farming, Forestry, Fishing, & Hunting"],
    salary: "$47,860",
  },
  {
    img: img3,
    jobTitle: "Food Scientists & Technologists",
    jobTitleAlternatives: ["Food Scientist", "Food Technologist", "Research Food Technologist", "Research Scientist"],
    educationRequired: "bachelor's degree or doctoral degree usually needed",
    jobOutlook: "Salary:",
    industry: ["Manufacturing", "Professional, Science, & Technical", "Management"],
    salary: "$79,860",
  },
  {
    img: img3,
    jobTitle: "Food Servers, Nonrestaurant",
    jobTitleAlternatives: ["Food Service Worker", "Room Server", "Room Service Server", "Tray Server"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling", "Hotel & Food"],
    salary: "$30,300",
  },
  {
    img: img3,
    jobTitle: "Food Service Managers",
    jobTitleAlternatives: ["Food and Beverage Manager", "Food Service Director", "Food Service Manager", "Restaurant Manager"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Hotel & Food"],
    salary: "$61,310",
  },
  {
    img: img3,
    jobTitle: "Foreign Language & Literature Teachers, Postsecondary",
    jobTitleAlternatives: ["French Professor", "Professor", "Spanish Instructor", "Spanish Professor"],
    educationRequired: "Doctoral Degree",
    jobOutlook: "Salary:",
    industry: ["Education"],
    salary: "$76,030",
  },
  {
    img: img3,
    jobTitle: "Forensic Science Technicians",
    jobTitleAlternatives: [
      "Crime Laboratory Analyst",
      "Crime Scene Technician (Crime Scene Tech)",
      "CSI (Crime Scene Investigator)",
      "Forensic Scientist",
    ],
    educationRequired: "bachelor's degree or some college usually needed",
    jobOutlook: "Salary:",
    industry: ["Government"],
    salary: "$63,740",
  },
  {
    img: img3,
    jobTitle: "Forest & Conservation Technicians",
    jobTitleAlternatives: ["Forest Technician", "Forestry Aide", "Forestry Technician (Forestry Tech)", "Resource Technician"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Government"],
    salary: "$41,520",
  },
  {
    img: img3,
    jobTitle: "Forest & Conservation Workers",
    jobTitleAlternatives: ["Field Laborer", "Forest Resource Specialist", "Forestry Support Specialist", "Tree Planter"],
    educationRequired: "bachelor's degree or associate's degree usually needed",
    jobOutlook: "Salary:",
    industry: ["Farming, Forestry, Fishing, & Hunting", "Government"],
    salary: "$32,270",
  },
  {
    img: img3,
    jobTitle: "Forest Fire Inspectors & Prevention Specialists",
    jobTitleAlternatives: ["Fire Management Officer", "Fire Prevention Technician", "Forest Officer", "Forest Patrolman"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Government"],
    salary: "$48,110",
  },
  {
    img: img3,
    jobTitle: "Foresters",
    jobTitleAlternatives: ["Area Forester", "Forest Practices Field Coordinator", "Forester", "Silviculturist"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Farming, Forestry, Fishing, & Hunting", "Government"],
    salary: "$64,220",
  },
  {
    img: img3,
    jobTitle: "Forestry & Conservation Science Teachers, Postsecondary",
    jobTitleAlternatives: ["Assistant Professor", "Associate Professor", "Forestry Professor", "Professor"],
    educationRequired: "Doctoral Degree",
    jobOutlook: "Salary:",
    industry: ["Education"],
    salary: "$96,500",
  },
  {
    img: img3,
    jobTitle: "Forging Machine Setters, Operators, & Tenders, Metal & Plastic",
    jobTitleAlternatives: ["Blacksmith", "Forge Operator", "Hammer Operator", "Machine Operator"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing"],
    salary: "$46,540",
  },
  {
    img: img3,
    jobTitle: "Foundry Mold & Coremakers",
    jobTitleAlternatives: ["Core Maker", "Mold Maker", "Molder", "Sand Molder"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing"],
    salary: "$40,120",
  },
  {
    img: img3,
    jobTitle: "Fraud Examiners, Investigators & Analysts",
    jobTitleAlternatives: ["Certified Fraud Examiner", "Inspector General", "Investigator", "Special Investigation Unit Investigator"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Finance & Insurance", "Government"],
    salary: "$73,810",
  },
  {
    img: img3,
    jobTitle: "Freight Forwarders",
    jobTitleAlternatives: ["Air Export Agent", "Air Export Coordinator", "Route Specialist", "Special Services Agent"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Transportation & Storage"],
    salary: "$46,860",
  },
  {
    img: img3,
    jobTitle: "Fuel Cell Engineers",
    jobTitleAlternatives: ["Engineer", "Fuel Cell Engineer", "Research Engineer", "Stack Engineer"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Manufacturing", "Professional, Science, & Technical"],
    salary: "$96,310",
  },
  {
    img: img3,
    jobTitle: "Fundraisers",
    jobTitleAlternatives: ["Contract Grant Writer", "Development Officer", "Fundraising Consultant", "Philanthropy Officer"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Service", "Education", "Health & Counseling"],
    salary: "$61,190",
  },
  {
    img: img3,
    jobTitle: "Fundraising Managers",
    jobTitleAlternatives: ["Account Supervisor", "Annual Giving Director", "Development Director"],
    educationRequired: "Master's Degree",
    jobOutlook: "Salary:",
    industry: ["Service", "Education", "Health & Counseling"],
    salary: "$107,390",
  },
  {
    img: img3,
    jobTitle: "Funeral Attendants",
    jobTitleAlternatives: ["Funeral Assistant", "Funeral Attendant", "Funeral Home Assistant", "Funeral Home Associate"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Service"],
    salary: "$31,160",
  },
  {
    img: img3,
    jobTitle: "Funeral Home Managers",
    jobTitleAlternatives: ["Funeral Home Manager", "Funeral Service Manager", "Mortuary Operations Manager", "Prearranged Funerals Sales Manager"],
    educationRequired: "associate's degree or professional degree usually needed",
    jobOutlook: "Salary:",
    industry: ["Service"],
    salary: "$72,110",
  },
  {
    img: img3,
    jobTitle: "Furnace, Kiln, Oven, Drier, & Kettle Operators & Tenders",
    jobTitleAlternatives: ["Dry Kiln Operator", "Furnace Operator", "Kiln Fireman", "Kiln Operator"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing"],
    salary: "$44,530",
  },
  {
    img: img3,
    jobTitle: "Furniture Finishers",
    jobTitleAlternatives: ["Finisher", "Furniture Finisher", "Lacquer Sprayer", "Sprayer"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing", "Service"],
    salary: "$37,960",
  },
  {
    img: img3,
    jobTitle: "Gambling & Sports Book Writers & Runners",
    jobTitleAlternatives: ["Casino Floor Runner", "Casino Runner", "Keno Writer", "Racebook Writer"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Arts & Entertainment", "Service", "Government"],
    salary: "$28,940",
  },
  {
    img: img3,
    jobTitle: "Gambling Cage Workers",
    jobTitleAlternatives: ["Cage Cashier", "Casino Cage Cashier", "Casino Cashier", "Gaming Cashier"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Government", "Arts & Entertainment", "Hotel & Food"],
    salary: "$31,720",
  },
  {
    img: img3,
    jobTitle: "Gambling Change Persons & Booth Cashiers",
    jobTitleAlternatives: ["Cage Cashier", "Cashier", "Change Person", "Slot Attendant"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Government", "Arts & Entertainment", "Hotel & Food"],
    salary: "$30,010",
  },
  {
    img: img3,
    jobTitle: "Gambling Dealers",
    jobTitleAlternatives: ["Blackjack Dealer", "Casino Dealer", "Table Games Dealer", "Twenty-One Dealer"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Hotel & Food", "Arts & Entertainment", "Government"],
    salary: "$29,120",
  },
  {
    img: img3,
    jobTitle: "Gambling Managers",
    jobTitleAlternatives: ["Casino Manager", "Gaming Manager", "Slot Manager", "Table Games Manager"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Government", "Arts & Entertainment", "Hotel & Food"],
    salary: "$80,710",
  },
  {
    img: img3,
    jobTitle: "Gambling Surveillance Officers & Gambling Investigators",
    jobTitleAlternatives: ["Surveillance Agent", "Surveillance Observer", "Surveillance Officer", "Surveillance Operator"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Government", "Arts & Entertainment", "Hotel & Food"],
    salary: "$35,970",
  },
  {
    img: img3,
    jobTitle: "Gas Compressor & Gas Pumping Station Operators",
    jobTitleAlternatives: ["Compressor Station Operator", "Compressor Technician", "Filler", "Plant Operator"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Transportation & Storage", "Wholesale/Commercial Sales", "Utilities"],
    salary: "$59,990",
  },
  {
    img: img3,
    jobTitle: "Gas Plant Operators",
    jobTitleAlternatives: ["Gas Controller", "Gas Dispatcher", "Gas Plant Operator", "Plant Operator"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Utilities", "Transportation & Storage"],
    salary: "$79,460",
  },
  {
    img: img3,
    jobTitle: "Gem & Diamond Workers",
    jobTitleAlternatives: ["Diamond Cutter", "Diamond Setter", "Gemologist", "Lapidarist"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Retail", "Manufacturing"],
    salary: "$47,140",
  },
  {
    img: img3,
    jobTitle: "General & Operations Managers",
    jobTitleAlternatives: ["General Manager (GM)", "Operations Director", "Operations Manager", "Store Manager"],
    educationRequired: "Master's Degree",
    jobOutlook: "Salary:",
    industry: ["Retail", "Professional, Science, & Technical"],
    salary: "$98,100",
  },
  {
    img: img3,
    jobTitle: "General Internal Medicine Physicians",
    jobTitleAlternatives: ["Internal Medicine Physician (IM Physician)", "Internist", "Medical Doctor (MD)", "Physician"],
    educationRequired: "Doctoral Degree",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling"],
    salary: "$214,460",
  },
  {
    img: img3,
    jobTitle: "Genetic Counselors",
    jobTitleAlternatives: [
      "Certified Genetic Counselor",
      "Genetic Counselor",
      "Prenatal and Pediatric Genetic Counselor",
      "Reproductive Genetic Counseling Coordinator",
    ],
    educationRequired: "Master's Degree",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling"],
    salary: "$89,990",
  },
  {
    img: img3,
    jobTitle: "Geneticists",
    jobTitleAlternatives: ["Cardiovascular Geneticist", "Medical Geneticist", "Research Scientist", "Scientist"],
    educationRequired: "post-doctoral training or master's degree usually needed",
    jobOutlook: "Salary:",
    industry: ["Government", "Professional, Science, & Technical"],
    salary: "$87,300",
  },
  {
    img: img3,
    jobTitle: "Geodetic Surveyors",
    jobTitleAlternatives: ["Geodesist", "Geodetic Advisor", "Geodetic Surveyor", "Land Surveyor"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical"],
    salary: "$63,080",
  },
  {
    img: img3,
    jobTitle: "Geographers",
    jobTitleAlternatives: ["Earth Observations Scientist", "Geographer", "GIS Geographer (Geographic Information Systems Geographer)", "Scientist"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Government"],
    salary: "$88,900",
  },
  {
    img: img3,
    jobTitle: "Geographic Information Systems Technologists & Technicians",
    jobTitleAlternatives: [
      "Geographic Information System Analyst (GIS Analyst)",
      "Geographic Information Systems Analyst (GIS Analyst)",
      "GIS Specialist (Geographic Information Systems Specialist)",
      "GIS Technician (Geographic Information Systems Technician)",
    ],
    educationRequired: "Master's Degree",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Government"],
    salary: "$98,740",
  },
  {
    img: img3,
    jobTitle: "Geography Teachers, Postsecondary",
    jobTitleAlternatives: ["Associate Professor", "Geography Instructor", "Geography Professor", "Professor"],
    educationRequired: "Doctoral Degree",
    jobOutlook: "Salary:",
    industry: ["Education"],
    salary: "$81,920",
  },
  {
    img: img3,
    jobTitle: "Geological Technicians",
    jobTitleAlternatives: ["Geological Technician", "Geotechnician", "Materials Technician", "Physical Science Technician"],
    educationRequired: "Master's Degree",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Mining, Oil, & Gas"],
    salary: "$48,490",
  },
  {
    img: img3,
    jobTitle: "Geoscientists",
    jobTitleAlternatives: ["Geologist", "Geophysicist", "Geoscientist", "Project Geologist"],
    educationRequired: "Master's Degree",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Government", "Mining, Oil, & Gas", "Management"],
    salary: "$87,480",
  },
  {
    img: img3,
    jobTitle: "Geothermal Production Managers",
    jobTitleAlternatives: ["Plant Manager", "Plant Supervisor", "Power Plant Operations Manager"],
    educationRequired: "some college or certificate after high school usually needed",
    jobOutlook: "Salary:",
    industry: ["Manufacturing"],
    salary: "$107,560",
  },
  {
    img: img3,
    jobTitle: "Geothermal Technicians",
    jobTitleAlternatives: [
      "I C and E Technician (Instrumentation",
      "Control",
      "and Electrical Technician); Operations and Maintenance Technician (O and M Technician); Operations Technician; Plant Technician",
    ],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Wholesale/Commercial Sales", "Retail", "Administration & Support Services", "Construction", "Manufacturing"],
    salary: "$44,500",
  },
  {
    img: img3,
    jobTitle: "Glass Blowers, Molders, Benders, & Finishers",
    jobTitleAlternatives: ["Glass Bender", "Glass Blower", "Neon Glass Bender", "Neon Tube Bender"],
    educationRequired: "No High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing"],
    salary: "$39,590",
  },
  {
    img: img3,
    jobTitle: "Glaziers",
    jobTitleAlternatives: ["Commercial Glazier", "Glass Installer", "Glazer", "Glazier"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Construction", "Retail"],
    salary: "$48,720",
  },
  {
    img: img3,
    jobTitle: "Government Property Inspectors & Investigators",
    jobTitleAlternatives: [
      "Housing Inspector",
      "Housing Quality Standard Inspector (HQS Inspector)",
      "Quality Assurance Specialist",
      "Rehabilitation Construction Specialist",
    ],
    educationRequired: "bachelor's degree or associate's degree usually needed",
    jobOutlook: "Salary:",
    industry: ["Government", "Finance & Insurance"],
    salary: "$71,690",
  },
  {
    img: img3,
    jobTitle: "Graders & Sorters, Agricultural Products",
    jobTitleAlternatives: ["Apple Sorter", "Grader", "Potato Grader", "Sorter"],
    educationRequired: "No High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Farming, Forestry, Fishing, & Hunting", "Manufacturing"],
    salary: "$32,550",
  },
  {
    img: img3,
    jobTitle: "Graphic Designers",
    jobTitleAlternatives: ["Artist", "Designer", "Graphic Artist", "Graphic Designer"],
    educationRequired: "bachelor's degree or associate's degree usually needed",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Manufacturing", "Media & Communication"],
    salary: "$57,990",
  },
  {
    img: img3,
    jobTitle: "Grinding & Polishing Workers, Hand",
    jobTitleAlternatives: ["Buffer", "Finisher", "Grinder", "Polisher"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing", "Administration & Support Services"],
    salary: "$36,960",
  },
  {
    img: img3,
    jobTitle: "Grinding, Lapping, Polishing, & Buffing Machine Tool Setters, Operators, & Tenders, Metal & Plastic",
    jobTitleAlternatives: ["Centerless Grinder Operator", "Grinder", "Grinder Operator", "Grinding Machine Operator"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing"],
    salary: "$38,910",
  },
  {
    img: img3,
    jobTitle: "Hairdressers, Hairstylists, & Cosmetologists",
    jobTitleAlternatives: ["Cosmetologist", "Hair Stylist", "Hairdresser", "Hairstylist"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Service"],
    salary: "$33,290",
  },
  {
    img: img3,
    jobTitle: "Hazardous Materials Removal Workers",
    jobTitleAlternatives: ["Asbestos Abatement Worker", "Asbestos Remover", "Asbestos Worker", "Waste Handling Technician"],
    educationRequired: "No High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Administration & Support Services"],
    salary: "$46,690",
  },
  {
    img: img3,
    jobTitle: "Health & Safety Engineers",
    jobTitleAlternatives: ["Product Safety Engineer", "Safety and Health Consultant", "Safety Engineer", "System Safety Engineer"],
    educationRequired: "Master's Degree",
    jobOutlook: "Salary:",
    industry: ["Manufacturing", "Professional, Science, & Technical", "Government", "Construction"],
    salary: "$100,660",
  },
  {
    img: img3,
    jobTitle: "Health Education Specialists",
    jobTitleAlternatives: ["Clinical Instructor", "Health Education Specialist", "Health Educator", "Public Health Educator"],
    educationRequired: "Associate's Degree",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling", "Government"],
    salary: "$59,990",
  },
  {
    img: img3,
    jobTitle: "Health Informatics Specialists",
    jobTitleAlternatives: [
      "Clinical Informatics Specialist",
      "Digital Diabetes Research Officer",
      "Nursing Informatics Specialist",
      "Registered Nurse Clinical Information Systems Coordinator (RN Clinical Information Systems Coordinator)",
    ],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Finance & Insurance", "Management"],
    salary: "$102,240",
  },
  {
    img: img3,
    jobTitle: "Health Information Technologists & Medical Registrars",
    jobTitleAlternatives: ["Medical Records Analyst", "Medical Records Director"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling"],
    salary: "$58,250",
  },
  {
    img: img3,
    jobTitle: "Health Specialties Teachers, Postsecondary",
    jobTitleAlternatives: ["Assistant Professor", "Associate Professor", "Clinical Professor", "Professor"],
    educationRequired: "Master's Degree",
    jobOutlook: "Salary:",
    industry: ["Education"],
    salary: "$100,300",
  },
  {
    img: img3,
    jobTitle: "Healthcare Social Workers",
    jobTitleAlternatives: ["Clinical Social Worker", "Hospice Social Worker", "Medical Social Worker", "Social Worker"],
    educationRequired: "Master's Degree",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling"],
    salary: "$60,280",
  },
  {
    img: img3,
    jobTitle: "Hearing Aid Specialists",
    jobTitleAlternatives: ["Hearing Aid Specialist", "Hearing Instrument Dispenser", "Hearing Instrument Specialist (HIS)", "Hearing Specialist"],
    educationRequired: "doctoral degree or high school diploma/GED usually needed",
    jobOutlook: "Salary:",
    industry: ["Retail", "Health & Counseling"],
    salary: "$59,020",
  },
  {
    img: img3,
    jobTitle: "Heat Treating Equipment Setters, Operators, & Tenders, Metal & Plastic",
    jobTitleAlternatives: ["Furnace Operator", "Heat Treat Operator", "Heat Treat Technician", "Heat Treater"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing"],
    salary: "$40,900",
  },
  {
    img: img3,
    jobTitle: "Heating, Air Conditioning, & Refrigeration Mechanics & Installers",
    jobTitleAlternatives: [
      "HVAC Mechanic (Heating",
      "Ventilation",
      "and Air Conditioning Mechanic); Refrigeration Mechanic; Refrigeration Technician (Refrigeration Tech); Service Technician (Service Tech)",
    ],
    educationRequired: "certificate after high school or no high school diploma/GED usually needed",
    jobOutlook: "Salary:",
    industry: ["Construction"],
    salary: "$51,390",
  },
  {
    img: img3,
    jobTitle: "Heavy & Tractor-Trailer Truck Drivers",
    jobTitleAlternatives: ["Driver", "Line Haul Driver", "Over the Road Driver (OTR Driver)", "Truck Driver"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Transportation & Storage", "Wholesale/Commercial Sales"],
    salary: "$49,920",
  },
  {
    img: img3,
    jobTitle: "Helpers--Brickmasons, Blockmasons, Stonemasons, & Tile & Marble Setters",
    jobTitleAlternatives: ["Bricklayer Helper", "Hod Carrier", "Marble Finisher Helper", "Mason Tender"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Construction"],
    salary: "$39,430",
  },
  {
    img: img3,
    jobTitle: "Helpers--Carpenters",
    jobTitleAlternatives: ["Carpenter Assistant", "Carpenter Helper", "Carpenter's Helper"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Construction"],
    salary: "$37,490",
  },
  {
    img: img3,
    jobTitle: "Helpers--Electricians",
    jobTitleAlternatives: ["Electrician Helper", "Electrician's Helper"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Construction"],
    salary: "$37,070",
  },
  {
    img: img3,
    jobTitle: "Helpers--Extraction Workers",
    jobTitleAlternatives: ["Driller Helper", "Driller's Assistant", "Miner Helper", "Powderman"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Mining, Oil, & Gas", "Construction"],
    salary: "$43,110",
  },
  {
    img: img3,
    jobTitle: "Helpers--Installation, Maintenance, & Repair Workers",
    jobTitleAlternatives: ["Maintenance Aide", "Maintenance Helper", "Mechanic Helper", "Technician's Helper"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Construction", "Service"],
    salary: "$35,100",
  },
  {
    img: img3,
    jobTitle: "Helpers--Painters, Paperhangers, Plasterers, & Stucco Masons",
    jobTitleAlternatives: ["Painter Helper", "Plaster Helper", "Plaster Tender"],
    educationRequired: "No High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Construction"],
    salary: "$36,080",
  },
  {
    img: img3,
    jobTitle: "Helpers--Pipelayers, Plumbers, Pipefitters, & Steamfitters",
    jobTitleAlternatives: ["Plumber's Helper"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Construction"],
    salary: "$36,340",
  },
  {
    img: img3,
    jobTitle: "Helpers--Production Workers",
    jobTitleAlternatives: ["Helper", "Material Handler", "Press Helper"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing", "Administration & Support Services"],
    salary: "$34,670",
  },
  {
    img: img3,
    jobTitle: "Helpers--Roofers",
    jobTitleAlternatives: ["Roofer Helper"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Construction"],
    salary: "$37,020",
  },
  {
    img: img3,
    jobTitle: "Highway Maintenance Workers",
    jobTitleAlternatives: [
      "Equipment Operator (EO)",
      "Highway Maintainer",
      "Highway Maintenance Worker",
      "Transportation Maintenance Specialist (TMS)",
    ],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Government"],
    salary: "$44,930",
  },
  {
    img: img3,
    jobTitle: "Histology Technicians",
    jobTitleAlternatives: ["Histologic Technician", "Histology Technician"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling"],
    salary: "$57,380",
  },
  {
    img: img3,
    jobTitle: "Historians",
    jobTitleAlternatives: ["Historian", "Historic Sites Registrar", "Research Associate", "Researcher"],
    educationRequired: "Doctoral Degree",
    jobOutlook: "Salary:",
    industry: ["Government", "Professional, Science, & Technical"],
    salary: "$64,540",
  },
  {
    img: img3,
    jobTitle: "History Teachers, Postsecondary",
    jobTitleAlternatives: ["History Instructor", "History Professor", "Instructor", "Professor"],
    educationRequired: "Doctoral Degree",
    jobOutlook: "Salary:",
    industry: ["Education"],
    salary: "$79,400",
  },
  {
    img: img3,
    jobTitle: "Histotechnologists",
    jobTitleAlternatives: ["Clinical Laboratory Manager", "Histology Specialist", "Histology Technologist", "Histotechnologist"],
    educationRequired: "Master's Degree",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling"],
    salary: "$57,380",
  },
  {
    img: img3,
    jobTitle: "Hoist & Winch Operators",
    jobTitleAlternatives: ["Hoist Operator", "Hoistman", "Material Handler", "Service Operator"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Transportation & Storage", "Manufacturing", "Construction"],
    salary: "$58,950",
  },
  {
    img: img3,
    jobTitle: "Home Appliance Repairers",
    jobTitleAlternatives: ["Appliance Technician (Appliance Tech)", "Repair Technician", "Service Technician (Service Tech)", "Vacuum Repairer"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Service", "Retail"],
    salary: "$46,000",
  },
  {
    img: img3,
    jobTitle: "Home Health Aides",
    jobTitleAlternatives: ["Certified Home Health Aide (CHHA)", "Certified Nurses Aide (CNA)", "Home Care Aide", "Home Health Aide (HHA)"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling"],
    salary: "$30,180",
  },
  {
    img: img3,
    jobTitle: "Hospitalists",
    jobTitleAlternatives: ["Hospitalist", "Hospitalist Medical Doctor (Hospitalist MD)", "MD (Medical Doctor)", "Physician"],
    educationRequired: "Doctoral Degree",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling", "Government"],
    salary: "$223,410",
  },
  {
    img: img3,
    jobTitle: "Hosts & Hostesses, Restaurant, Lounge, & Coffee Shop",
    jobTitleAlternatives: ["Buffet Hostess", "Greeter", "Host", "Hostess"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Hotel & Food"],
    salary: "$27,720",
  },
  {
    img: img3,
    jobTitle: "Hotel, Motel, & Resort Desk Clerks",
    jobTitleAlternatives: ["Front Desk Agent", "Front Desk Clerk", "Guest Services Agent (GSA)", "Night Auditor"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Hotel & Food"],
    salary: "$28,910",
  },
  {
    img: img3,
    jobTitle: "Human Factors Engineers & Ergonomists",
    jobTitleAlternatives: ["Consulting Ergonomist", "Ergonomist", "Human Factors Engineer", "Occupational Ergonomist"],
    educationRequired: "Master's Degree",
    jobOutlook: "Salary:",
    industry: ["Manufacturing", "Professional, Science, & Technical"],
    salary: "$96,350",
  },
  {
    img: img3,
    jobTitle: "Human Resources Assistants",
    jobTitleAlternatives: [
      "Human Resources Administrative Assistant (HR Administrative Assistant)",
      "Human Resources Assistant (HR Assistant)",
      "Human Resources Associate (HR Associate)",
      "Personnel Clerk",
    ],
    educationRequired: "bachelor's degree or associate's degree usually needed",
    jobOutlook: "Salary:",
    industry: ["Government", "Health & Counseling", "Administration & Support Services"],
    salary: "$45,930",
  },
  {
    img: img3,
    jobTitle: "Human Resources Managers",
    jobTitleAlternatives: [
      "Employee Relations Manager",
      "HR Administration Director (Human Resources Administration Director)",
      "Human Resources Director (HR Director)",
      "Human Resources Manager (HR Manager)",
    ],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Management", "Manufacturing"],
    salary: "$130,000",
  },
  {
    img: img3,
    jobTitle: "Human Resources Specialists",
    jobTitleAlternatives: [
      "HR Coordinator (Human Resources Coordinator)",
      "HR Generalist (Human Resources Generalist)",
      "Human Resources Specialist (HR Specialist)",
      "Recruiter",
    ],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Administration & Support Services", "Professional, Science, & Technical", "Government", "Health & Counseling"],
    salary: "$64,240",
  },
  {
    img: img3,
    jobTitle: "Hydroelectric Plant Technicians",
    jobTitleAlternatives: [
      "Hydroelectric Mechanic",
      "Hydroelectric Operations and Maintenance Technician (Hydro O and M Technician)",
      "Operations and Maintenance Technician (O and M Technician)",
      "Power Plant Mechanic",
    ],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Utilities", "Government"],
    salary: "$93,060",
  },
  {
    img: img3,
    jobTitle: "Hydroelectric Production Managers",
    jobTitleAlternatives: ["Plant Manager", "Power Plant Superintendent", "Power Plant Supervisor", "Power Project Manager"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing"],
    salary: "$107,560",
  },
  {
    img: img3,
    jobTitle: "Hydrologic Technicians",
    jobTitleAlternatives: [[]],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Government", "Mining, Oil, & Gas", "Manufacturing"],
    salary: "$58,360",
  },
  {
    img: img3,
    jobTitle: "Hydrologists",
    jobTitleAlternatives: ["Groundwater Consultant", "Hydrogeologist", "Hydrologist", "Research Hydrologist"],
    educationRequired: "Master's Degree",
    jobOutlook: "Salary:",
    industry: ["Government", "Professional, Science, & Technical"],
    salary: "$85,990",
  },
  {
    img: img3,
    jobTitle: "Industrial Ecologists",
    jobTitleAlternatives: ["Ecologist", "Environmental Consultant", "Environmental Protection Agency Counselor", "Research Scientist"],
    educationRequired: "Master's Degree",
    jobOutlook: "Salary:",
    industry: ["Government", "Professional, Science, & Technical"],
    salary: "$76,480",
  },
  {
    img: img3,
    jobTitle: "Industrial Engineering Technologists & Technicians",
    jobTitleAlternatives: [
      "Industrial Engineering Technician",
      "Manufacturing Technology Analyst",
      "Quality Control Engineering Technician (QC Engineering Technician)",
      "Quality Technician",
    ],
    educationRequired: "some college or associate's degree usually needed",
    jobOutlook: "Salary:",
    industry: ["Manufacturing", "Professional, Science, & Technical"],
    salary: "$61,210",
  },
  {
    img: img3,
    jobTitle: "Industrial Engineers",
    jobTitleAlternatives: ["Engineer", "Industrial Engineer", "Process Engineer", "Project Engineer"],
    educationRequired: "bachelor's degree or some college usually needed",
    jobOutlook: "Salary:",
    industry: ["Manufacturing", "Professional, Science, & Technical"],
    salary: "$96,350",
  },
  {
    img: img3,
    jobTitle: "Industrial Machinery Mechanics",
    jobTitleAlternatives: ["Industrial Machinery Mechanic", "Maintenance Technician", "Mechanic", "Overhauler"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing", "Wholesale/Commercial Sales", "Service"],
    salary: "$59,830",
  },
  {
    img: img3,
    jobTitle: "Industrial Production Managers",
    jobTitleAlternatives: ["Assembly Manager", "Manufacturing Manager", "Plant Manager", "Production Manager"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing"],
    salary: "$107,560",
  },
  {
    img: img3,
    jobTitle: "Industrial Truck & Tractor Operators",
    jobTitleAlternatives: ["Fork Truck Driver", "Forklift Driver", "Forklift Operator", "Spotter Driver"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Transportation & Storage", "Manufacturing", "Administration & Support Services", "Wholesale/Commercial Sales"],
    salary: "$41,230",
  },
  {
    img: img3,
    jobTitle: "Industrial-Organizational Psychologists",
    jobTitleAlternatives: [
      "Industrial Psychologist",
      "Industrial/Organizational Psychologist (I/O Psychologist)",
      "Organizational Psychologist",
      "Research Scientist",
    ],
    educationRequired: "Doctoral Degree",
    jobOutlook: "Salary:",
    industry: ["Education"],
    salary: "$139,280",
  },
  {
    img: img3,
    jobTitle: "Information Security Analysts",
    jobTitleAlternatives: [
      "Information Security Officer",
      "Information Systems Security Officer (ISSO)",
      "Information Technology Security Analyst (IT Security Analyst)",
      "Network Security Analyst",
    ],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Finance & Insurance", "Media & Communication"],
    salary: "$112,000",
  },
  {
    img: img3,
    jobTitle: "Information Security Engineers",
    jobTitleAlternatives: [[]],
    educationRequired: "Master's Degree",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Government"],
    salary: "$98,740",
  },
  {
    img: img3,
    jobTitle: "Information Technology Project Managers",
    jobTitleAlternatives: [
      "Cloud Product Director",
      "Cybersecurity Project Manager",
      "IT Manager (Information Technology Manager)",
      "IT Project Manager (Information Technology Project Manager)",
    ],
    educationRequired: "bachelor's degree or associate's degree usually needed",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Government"],
    salary: "$98,740",
  },
  {
    img: img3,
    jobTitle: "Inspectors, Testers, Sorters, Samplers, & Weighers",
    jobTitleAlternatives: ["Inspector", "Quality Control Inspector (QC Inspector)", "Quality Inspector", "Quality Technician"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing"],
    salary: "$43,900",
  },
  {
    img: img3,
    jobTitle: "Instructional Coordinators",
    jobTitleAlternatives: ["Curriculum and Instruction Director", "Curriculum Coordinator", "Instructional Designer", "Instructional Technologist"],
    educationRequired: "Master's Degree",
    jobOutlook: "Salary:",
    industry: ["Education"],
    salary: "$66,490",
  },
  {
    img: img3,
    jobTitle: "Insulation Workers, Floor, Ceiling, & Wall",
    jobTitleAlternatives: ["Insulation Estimator", "Insulation Installer", "Insulator", "Retrofit Installer"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Construction"],
    salary: "$45,380",
  },
  {
    img: img3,
    jobTitle: "Insulation Workers, Mechanical",
    jobTitleAlternatives: ["Industrial Insulator", "Insulation Mechanic", "Insulator", "Mechanical Insulator"],
    educationRequired: "No High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Construction"],
    salary: "$50,590",
  },
  {
    img: img3,
    jobTitle: "Insurance Appraisers, Auto Damage",
    jobTitleAlternatives: [
      "Appraiser",
      "Automobile Appraiser (Auto Appraiser)",
      "Automobile Damage Appraiser (Auto Damage Appraiser)",
      "Material Damage Appraiser",
    ],
    educationRequired: "some college or certificate after high school usually needed",
    jobOutlook: "Salary:",
    industry: ["Finance & Insurance"],
    salary: "$69,380",
  },
  {
    img: img3,
    jobTitle: "Insurance Claims & Policy Processing Clerks",
    jobTitleAlternatives: ["Claims Processor", "Claims Representative (Claims Rep)", "Claims Technician (Claims Tech)", "Underwriting Assistant"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Finance & Insurance"],
    salary: "$46,080",
  },
  {
    img: img3,
    jobTitle: "Insurance Sales Agents",
    jobTitleAlternatives: ["Insurance Agent", "Insurance Broker", "Sales Agent", "Sales Representative"],
    educationRequired: "bachelor's degree or associate's degree usually needed",
    jobOutlook: "Salary:",
    industry: ["Finance & Insurance"],
    salary: "$57,860",
  },
  {
    img: img3,
    jobTitle: "Insurance Underwriters",
    jobTitleAlternatives: ["Account Underwriter", "Life Underwriter", "Personal Lines Underwriter", "Underwriter"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Finance & Insurance"],
    salary: "$76,230",
  },
  {
    img: img3,
    jobTitle: "Intelligence Analysts",
    jobTitleAlternatives: ["Crime Analyst", "Criminal Intelligence Analyst", "Criminal Research Specialist", "Intelligence Analyst"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Government"],
    salary: "$86,280",
  },
  {
    img: img3,
    jobTitle: "Interior Designers",
    jobTitleAlternatives: ["Designer", "Interior Design Consultant", "Interior Design Coordinator", "Interior Designer"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Retail"],
    salary: "$61,590",
  },
  {
    img: img3,
    jobTitle: "Interpreters & Translators",
    jobTitleAlternatives: ["Interpreter", "Medical Interpreter", "Sign Language Interpreter", "Translator"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Education", "Health & Counseling"],
    salary: "$53,640",
  },
  {
    img: img3,
    jobTitle: "Interviewers",
    jobTitleAlternatives: ["Admissions Clerk", "Admissions Representative", "Interviewer", "Registration Clerk"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling", "Professional, Science, & Technical"],
    salary: "$38,700",
  },
  {
    img: img3,
    jobTitle: "Investment Fund Managers",
    jobTitleAlternatives: [
      "Fixed Income Portfolio Manager",
      "Fixed Income Vice President (Fixed Income VP)",
      "Investment Analysis Vice President (Investment Analysis VP)",
      "Portfolio Manager",
    ],
    educationRequired: "Master's Degree",
    jobOutlook: "Salary:",
    industry: ["Finance & Insurance", "Professional, Science, & Technical", "Management"],
    salary: "$139,790",
  },
  {
    img: img3,
    jobTitle: "Janitors & Cleaners",
    jobTitleAlternatives: ["Cleaner", "Custodian", "Floor Tech (Floor Technician)", "Janitor"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Administration & Support Services", "Education"],
    salary: "$31,990",
  },
  {
    img: img3,
    jobTitle: "Jewelers & Precious Stone & Metal Workers",
    jobTitleAlternatives: ["Bench Jeweler", "Goldsmith", "Jeweler", "Silversmith"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Retail", "Manufacturing"],
    salary: "$47,140",
  },
  {
    img: img3,
    jobTitle: "Judges, Magistrate Judges, & Magistrates",
    jobTitleAlternatives: ["District Court Judge", "Judge", "Magistrate", "Superior Court Judge"],
    educationRequired: "Doctoral Degree",
    jobOutlook: "Salary:",
    industry: ["Government"],
    salary: "$151,030",
  },
  {
    img: img3,
    jobTitle: "Judicial Law Clerks",
    jobTitleAlternatives: ["Judicial Assistant", "Judicial Clerk", "Judicial Law Clerk", "Law Clerk"],
    educationRequired: "Doctoral Degree",
    jobOutlook: "Salary:",
    industry: ["Government"],
    salary: "$57,490",
  },
  {
    img: img3,
    jobTitle: "Kindergarten Teachers",
    jobTitleAlternatives: ["Bilingual Kindergarten Teacher", "Classroom Teacher", "Kinder Teacher", "Teacher"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Education"],
    salary: "$60,490",
  },
  {
    img: img3,
    jobTitle: "Labor Relations Specialists",
    jobTitleAlternatives: ["Business Agent", "Business Representative", "Field Operations Coordinator", "Labor Relations Specialist"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Service"],
    salary: "$82,010",
  },
  {
    img: img3,
    jobTitle: "Laborers & Freight, Stock, & Material Movers, Hand",
    jobTitleAlternatives: ["Dock Worker", "Laborer", "Material Handler", "Warehouse Worker"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Transportation & Storage", "Administration & Support Services", "Manufacturing", "Wholesale/Commercial Sales"],
    salary: "$36,110",
  },
  {
    img: img3,
    jobTitle: "Landscape Architects",
    jobTitleAlternatives: ["Golf Course Architect", "Land Planner", "Landscape Architect", "Landscape Planner"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Administration & Support Services"],
    salary: "$73,210",
  },
  {
    img: img3,
    jobTitle: "Landscaping & Groundskeeping Workers",
    jobTitleAlternatives: ["Grounds Maintenance Worker", "Grounds Worker", "Groundskeeper", "Outside Maintenance Worker"],
    educationRequired: "no high school diploma/GED or certificate after high school usually needed",
    jobOutlook: "Salary:",
    industry: ["Administration & Support Services"],
    salary: "$35,890",
  },
  {
    img: img3,
    jobTitle: "Lathe & Turning Machine Tool Setters, Operators, & Tenders, Metal & Plastic",
    jobTitleAlternatives: ["Computer Numerical Control Lathe Operator (CNC Lathe Operator)", "Lathe Operator", "Machine Operator", "Setup Operator"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing"],
    salary: "$47,020",
  },
  {
    img: img3,
    jobTitle: "Laundry & Dry-Cleaning Workers",
    jobTitleAlternatives: ["Laundry Aide", "Laundry Attendant", "Laundry Housekeeper", "Laundry Worker"],
    educationRequired: "No High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Service", "Health & Counseling", "Hotel & Food"],
    salary: "$29,060",
  },
  {
    img: img3,
    jobTitle: "Law Teachers, Postsecondary",
    jobTitleAlternatives: ["Associate Professor", "Instructor", "Law Professor", "Professor"],
    educationRequired: "professional degree or doctoral degree usually needed",
    jobOutlook: "Salary:",
    industry: ["Education"],
    salary: "$108,860",
  },
  {
    img: img3,
    jobTitle: "Lawyers",
    jobTitleAlternatives: ["Attorney", "Attorney at Law", "Attorney General", "Lawyer"],
    educationRequired: "professional degree or doctoral degree usually needed",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Government"],
    salary: "$135,740",
  },
  {
    img: img3,
    jobTitle: "Layout Workers, Metal & Plastic",
    jobTitleAlternatives: ["Layout Inspector", "Layout Man", "Layout Technician (Layout Tech)", "Layout Worker"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing", "Government"],
    salary: "$58,260",
  },
  {
    img: img3,
    jobTitle: "Legal Secretaries & Administrative Assistants",
    jobTitleAlternatives: ["Confidential Secretary", "Legal Administrative Assistant", "Legal Office Support Assistant", "Legal Secretary"],
    educationRequired: "some college or bachelor's degree usually needed",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Government"],
    salary: "$48,780",
  },
  {
    img: img3,
    jobTitle: "Legislators",
    jobTitleAlternatives: [[]],
    educationRequired: "Master's Degree",
    jobOutlook: "Salary:",
    industry: ["Government"],
    salary: "$48,090",
  },
  {
    img: img3,
    jobTitle: "Librarians & Media Collections Specialists",
    jobTitleAlternatives: ["Librarian", "Library Media Specialist", "Media Specialist", "Reference Librarian"],
    educationRequired: "Master's Degree",
    jobOutlook: "Salary:",
    industry: ["Education", "Government"],
    salary: "$61,660",
  },
  {
    img: img3,
    jobTitle: "Library Assistants, Clerical",
    jobTitleAlternatives: ["Library Aide", "Library Assistant", "Library Circulation Assistant", "Library Clerk"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Government", "Education", "Media & Communication"],
    salary: "$31,150",
  },
  {
    img: img3,
    jobTitle: "Library Science Teachers, Postsecondary",
    jobTitleAlternatives: ["Assistant Professor", "Associate Professor", "Library Science Professor", "Professor"],
    educationRequired: "Doctoral Degree",
    jobOutlook: "Salary:",
    industry: ["Education"],
    salary: "$76,370",
  },
  {
    img: img3,
    jobTitle: "Library Technicians",
    jobTitleAlternatives: ["Library Assistant", "Library Associate", "Library Technical Assistant (LTA)", "Library Technician"],
    educationRequired: "bachelor's degree or associate's degree usually needed",
    jobOutlook: "Salary:",
    industry: ["Government", "Education"],
    salary: "$37,600",
  },
  {
    img: img3,
    jobTitle: "Licensed Practical & Licensed Vocational Nurses",
    jobTitleAlternatives: ["Charge Nurse", "Clinic Nurse", "Licensed Vocational Nurse (LVN)", "Pediatric LPN (Pediatric Licensed Practical Nurse)"],
    educationRequired: "some college or certificate after high school usually needed",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling"],
    salary: "$54,620",
  },
  {
    img: img3,
    jobTitle: "Lifeguards, Ski Patrol, & Other Recreational Protective Service Workers",
    jobTitleAlternatives: ["Lifeguard", "Ocean Lifeguard", "Pool Attendant", "Ski Patroller"],
    educationRequired: "No High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Arts & Entertainment", "Government", "Service"],
    salary: "$27,270",
  },
  {
    img: img3,
    jobTitle: "Light Truck Drivers",
    jobTitleAlternatives: ["Delivery Driver", "Driver", "Package Car Driver", "Truck Driver"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Transportation & Storage", "Retail", "Wholesale/Commercial Sales"],
    salary: "$40,410",
  },
  {
    img: img3,
    jobTitle: "Lighting Technicians",
    jobTitleAlternatives: [[]],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Arts & Entertainment", "Hotel & Food", "Real Estate & Rentals"],
    salary: "$61,650",
  },
  {
    img: img3,
    jobTitle: "Loading & Moving Machine Operators, Underground Mining",
    jobTitleAlternatives: ["Load Haul Dump Operator (LHD Operator)", "Loader Operator", "Loading Machine Operator", "Shuttle Car Operator"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Mining, Oil, & Gas"],
    salary: "$63,920",
  },
  {
    img: img3,
    jobTitle: "Loan Interviewers & Clerks",
    jobTitleAlternatives: ["Licensed Loan Officer Assistant", "Loan Analyst", "Loan Processor", "Mortgage Loan Processor"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Finance & Insurance"],
    salary: "$46,490",
  },
  {
    img: img3,
    jobTitle: "Loan Officers",
    jobTitleAlternatives: ["Commercial Loan Officer", "Financial Aid Counselor", "Loan Counselor", "Loan Officer"],
    educationRequired: "bachelor's degree or some college usually needed",
    jobOutlook: "Salary:",
    industry: ["Finance & Insurance"],
    salary: "$65,740",
  },
  {
    img: img3,
    jobTitle: "Locker Room, Coatroom, & Dressing Room Attendants",
    jobTitleAlternatives: ["Athletic Equipment Manager", "Ladies Locker Room Attendant", "Locker Room Attendant", "Spa Attendant"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Arts & Entertainment", "Service", "Hotel & Food"],
    salary: "$30,210",
  },
  {
    img: img3,
    jobTitle: "Locksmiths & Safe Repairers",
    jobTitleAlternatives: ["Certified Master Locksmith (CML)", "Lock Technician", "Locksmith", "Safe Technician"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Administration & Support Services", "Education"],
    salary: "$47,400",
  },
  {
    img: img3,
    jobTitle: "Locomotive Engineers",
    jobTitleAlternatives: ["Locomotive Engineer", "Passenger Locomotive Engineer", "Railroad Engineer", "Transportation Specialist"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Transportation & Storage"],
    salary: "$74,570",
  },
  {
    img: img3,
    jobTitle: "Lodging Managers",
    jobTitleAlternatives: ["Front Desk Manager", "Front Office Manager", "Hotel Manager", "Resort Manager"],
    educationRequired: "bachelor's degree or no high school diploma/GED usually needed",
    jobOutlook: "Salary:",
    industry: ["Hotel & Food"],
    salary: "$61,910",
  },
  {
    img: img3,
    jobTitle: "Log Graders & Scalers",
    jobTitleAlternatives: ["Log Buyer", "Log Grader", "Log Scaler", "Scaler"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing"],
    salary: "$42,370",
  },
  {
    img: img3,
    jobTitle: "Logging Equipment Operators",
    jobTitleAlternatives: ["Loader Operator", "Logging Equipment Operator", "Skidder Driver", "Skidder Operator"],
    educationRequired: "No High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Farming, Forestry, Fishing, & Hunting", "Manufacturing"],
    salary: "$46,400",
  },
  {
    img: img3,
    jobTitle: "Logisticians",
    jobTitleAlternatives: ["Client Services Administrator", "Logistician", "Production Planner", "Supply Management Specialist"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Manufacturing", "Government", "Professional, Science, & Technical", "Transportation & Storage", "Management"],
    salary: "$77,520",
  },
  {
    img: img3,
    jobTitle: "Logistics Analysts",
    jobTitleAlternatives: ["Logistics Analyst", "Logistics Management Analyst", "Supply Chain Analyst", "Transportation Analyst"],
    educationRequired: "bachelor's degree or associate's degree usually needed",
    jobOutlook: "Salary:",
    industry: ["Manufacturing", "Government", "Professional, Science, & Technical", "Transportation & Storage", "Management"],
    salary: "$77,520",
  },
  {
    img: img3,
    jobTitle: "Logistics Engineers",
    jobTitleAlternatives: ["Logistics Engineer", "Reliability Engineer", "Supportability Engineer", "Systems Engineer"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Manufacturing", "Government", "Professional, Science, & Technical", "Transportation & Storage", "Management"],
    salary: "$77,520",
  },
  {
    img: img3,
    jobTitle: "Loss Prevention Managers",
    jobTitleAlternatives: ["Asset Protection Manager", "Loss Prevention Director", "Loss Prevention Manager", "Loss Prevention Operations Manager"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Government"],
    salary: "$128,620",
  },
  {
    img: img3,
    jobTitle: "Low Vision Therapists, Orientation & Mobility Specialists, & Vision Rehabilitation Therapists",
    jobTitleAlternatives: [
      "Certified Orientation and Mobility Specialist (COMS)",
      "Orientation and Mobility Specialist (O and M Specialist)",
      "Vision Rehabilitation Therapist (VRT)",
      "Visually Impaired Teacher (TVI)",
    ],
    educationRequired: "master's degree or certificate after college usually needed",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling", "Education"],
    salary: "$93,180",
  },
  {
    img: img3,
    jobTitle: "Machine Feeders & Offbearers",
    jobTitleAlternatives: ["Dryer Feeder", "Feeder", "Machine Feeder", "Offbearer"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing", "Transportation & Storage"],
    salary: "$38,040",
  },
  {
    img: img3,
    jobTitle: "Machinists",
    jobTitleAlternatives: ["CNC Machinist (Computer Numeric Controlled Machinist)", "Machinist", "Maintenance Machinist", "Tool Room Machinist"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing"],
    salary: "$48,510",
  },
  {
    img: img3,
    jobTitle: "Magnetic Resonance Imaging Technologists",
    jobTitleAlternatives: [
      "Magnetic Resonance Imaging Coordinator (MRI Coordinator)",
      "Magnetic Resonance Imaging Quality Assurance Coordinator (MRI Quality Assurance Coordinator)",
      "MRI Specialist (Magnetic Resonance Imaging Specialist)",
      "MRI Technologist (Magnetic Resonance Imaging Technologist)",
    ],
    educationRequired: "associate's degree or certificate after high school usually needed",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling"],
    salary: "$80,090",
  },
  {
    img: img3,
    jobTitle: "Maids & Housekeeping Cleaners",
    jobTitleAlternatives: ["Environmental Services Aide", "Environmental Services Worker", "Housekeeper", "Housekeeping Laundry Worker"],
    educationRequired: "No High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Hotel & Food", "Service", "Health & Counseling", "Administration & Support Services"],
    salary: "$29,960",
  },
  {
    img: img3,
    jobTitle: "Mail Clerks & Mail Machine Operators",
    jobTitleAlternatives: ["Mail Clerk", "Mail Handler", "Mail Machine Operator", "Postal Clerk"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Administration & Support Services", "Professional, Science, & Technical", "Finance & Insurance", "Government"],
    salary: "$35,070",
  },
  {
    img: img3,
    jobTitle: "Maintenance & Repair Workers, General",
    jobTitleAlternatives: ["Facilities Technician", "Maintenance Engineer", "Maintenance Mechanic", "Maintenance Technician"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Real Estate & Rentals", "Manufacturing", "Government"],
    salary: "$44,980",
  },
  {
    img: img3,
    jobTitle: "Maintenance Workers, Machinery",
    jobTitleAlternatives: ["Machine Repairer", "Maintainer", "Maintenance Technician", "Maintenance Worker"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing"],
    salary: "$53,310",
  },
  {
    img: img3,
    jobTitle: "Makeup Artists, Theatrical & Performance",
    jobTitleAlternatives: ["Hair and Makeup Designer", "Makeup Artist", "Special Effects Makeup Artist", "Special Makeup Effects Artist"],
    educationRequired: "certificate after high school or no high school diploma/GED usually needed",
    jobOutlook: "Salary:",
    industry: [],
    salary: "$96,370",
  },
  {
    img: img3,
    jobTitle: "Management Analysts",
    jobTitleAlternatives: ["Business Analyst", "Business Consultant", "Management Analyst", "Management Consultant"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Government", "Finance & Insurance"],
    salary: "$95,290",
  },
  {
    img: img3,
    jobTitle: "Manicurists & Pedicurists",
    jobTitleAlternatives: ["Manicurist", "Nail Technician (Nail Tech)", "Pedicurist"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Service"],
    salary: "$31,130",
  },
  {
    img: img3,
    jobTitle: "Manufactured Building & Mobile Home Installers",
    jobTitleAlternatives: ["Mobile Home Installer", "Mobile Home Laborer", "Mobile Home Set-Up Person", "Modular Set Crew Member"],
    educationRequired: "No High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Retail", "Construction", "Manufacturing"],
    salary: "$36,820",
  },
  {
    img: img3,
    jobTitle: "Manufacturing Engineers",
    jobTitleAlternatives: ["Manufacturing Engineer", "Plant Engineer", "Process Engineer", "Process Improvement Engineer"],
    educationRequired: "bachelor's degree or associate's degree usually needed",
    jobOutlook: "Salary:",
    industry: ["Manufacturing", "Professional, Science, & Technical"],
    salary: "$96,350",
  },
  {
    img: img3,
    jobTitle: "Marine Engineers & Naval Architects",
    jobTitleAlternatives: ["Marine Engineer", "Marine Engineering Consultant", "Marine Surveyor", "Naval Architect"],
    educationRequired: "Master's Degree",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Manufacturing", "Government"],
    salary: "$96,910",
  },
  {
    img: img3,
    jobTitle: "Market Research Analysts & Marketing Specialists",
    jobTitleAlternatives: ["Market Analyst", "Market Research Analyst", "Market Research Consultant", "Market Research Specialist"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Media & Communication", "Finance & Insurance"],
    salary: "$68,230",
  },
  {
    img: img3,
    jobTitle: "Marketing Managers",
    jobTitleAlternatives: ["Brand Manager", "Business Development Manager", "Marketing Director", "Marketing Manager"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Management", "Media & Communication", "Finance & Insurance"],
    salary: "$140,040",
  },
  {
    img: img3,
    jobTitle: "Marriage & Family Therapists",
    jobTitleAlternatives: [
      "Clinical Therapist",
      "Licensed Marriage and Family Therapist (LMFT)",
      "Marriage and Family Therapist (MFT)",
      "Outpatient Therapist",
    ],
    educationRequired: "Master's Degree",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling"],
    salary: "$56,570",
  },
  {
    img: img3,
    jobTitle: "Massage Therapists",
    jobTitleAlternatives: [
      "Certified Massage Therapist (CMT)",
      "Licensed Massage Practitioner (LMP)",
      "Licensed Massage Therapist (LMT)",
      "Massage Therapist",
    ],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Service", "Health & Counseling"],
    salary: "$49,860",
  },
  {
    img: img3,
    jobTitle: "Materials Engineers",
    jobTitleAlternatives: ["Materials Engineer", "Materials Research Engineer", "Metallurgical Engineer", "Metallurgist"],
    educationRequired: "bachelor's degree or doctoral degree usually needed",
    jobOutlook: "Salary:",
    industry: ["Manufacturing", "Professional, Science, & Technical"],
    salary: "$100,140",
  },
  {
    img: img3,
    jobTitle: "Materials Scientists",
    jobTitleAlternatives: ["Materials Scientist", "Polymer Materials Consultant", "Research Scientist", "Scientist"],
    educationRequired: "bachelor's degree or doctoral degree usually needed",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Manufacturing", "Education"],
    salary: "$104,380",
  },
  {
    img: img3,
    jobTitle: "Mathematical Science Teachers, Postsecondary",
    jobTitleAlternatives: ["Instructor", "Mathematics Instructor (Math Instructor)", "Mathematics Professor", "Professor"],
    educationRequired: "Master's Degree",
    jobOutlook: "Salary:",
    industry: ["Education"],
    salary: "$77,420",
  },
  {
    img: img3,
    jobTitle: "Mathematicians",
    jobTitleAlternatives: ["Computational Mathematician", "Cryptographer", "Mathematician", "Research Scientist"],
    educationRequired: "Doctoral Degree",
    jobOutlook: "Salary:",
    industry: ["Government", "Professional, Science, & Technical", "Education"],
    salary: "$112,110",
  },
  {
    img: img3,
    jobTitle: "Meat, Poultry, & Fish Cutters & Trimmers",
    jobTitleAlternatives: ["Deboner", "Meat Cutter", "Trimmer", "Wing Scorer"],
    educationRequired: "No High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing", "Retail"],
    salary: "$35,070",
  },
  {
    img: img3,
    jobTitle: "Mechanical Door Repairers",
    jobTitleAlternatives: ["Door Installer", "Garage Door Installer", "Garage Door Technician", "Service Technician"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Construction", "Retail"],
    salary: "$47,010",
  },
  {
    img: img3,
    jobTitle: "Mechanical Drafters",
    jobTitleAlternatives: ["Design Drafter", "Drafter", "Mechanical Designer", "Mechanical Drafter"],
    educationRequired: "Associate's Degree",
    jobOutlook: "Salary:",
    industry: ["Manufacturing", "Professional, Science, & Technical"],
    salary: "$61,310",
  },
  {
    img: img3,
    jobTitle: "Mechanical Engineering Technologists & Technicians",
    jobTitleAlternatives: [
      "Engineering Technician (Engineering Tech)",
      "Mechanical Designer",
      "Mechanical Technician (Mechanical Tech)",
      "Research and Development Technician (R and D Tech)",
    ],
    educationRequired: "bachelor's degree or certificate after high school usually needed",
    jobOutlook: "Salary:",
    industry: ["Manufacturing", "Professional, Science, & Technical"],
    salary: "$61,990",
  },
  {
    img: img3,
    jobTitle: "Mechanical Engineers",
    jobTitleAlternatives: ["Design Engineer", "Mechanical Engineer", "Process Engineer", "Product Engineer"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Manufacturing", "Professional, Science, & Technical"],
    salary: "$96,310",
  },
  {
    img: img3,
    jobTitle: "Mechatronics Engineers",
    jobTitleAlternatives: ["Automation Engineer", "Equipment Engineer", "Process Controls Engineer", "Project Engineer"],
    educationRequired: "bachelor's degree or some college usually needed",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Government", "Manufacturing"],
    salary: "$104,600",
  },
  {
    img: img3,
    jobTitle: "Media Programming Directors",
    jobTitleAlternatives: ["Program Coordinator", "Program Director", "Program Manager", "Programming Director"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Media & Communication", "Arts & Entertainment"],
    salary: "$85,320",
  },
  {
    img: img3,
    jobTitle: "Media Technical Directors/Managers",
    jobTitleAlternatives: ["News Technical Director", "Production Director", "Production Manager", "Technical Director"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Media & Communication", "Arts & Entertainment"],
    salary: "$85,320",
  },
  {
    img: img3,
    jobTitle: "Medical & Clinical Laboratory Technicians",
    jobTitleAlternatives: [
      "Laboratory Assistant (Lab Assistant)",
      "Laboratory Technician (Lab Tech)",
      "Medical Laboratory Technician (MLT)",
      "Medical Laboratory Technicians (Medical Lab Technician)",
    ],
    educationRequired: "bachelor's degree or associate's degree usually needed",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling"],
    salary: "$57,380",
  },
  {
    img: img3,
    jobTitle: "Medical & Clinical Laboratory Technologists",
    jobTitleAlternatives: [
      "Clinical Laboratory Scientist (CLS)",
      "Clinical Laboratory Technologist",
      "Medical Laboratory Technologist (Medical Lab Tech)",
      "Medical Technologist (MT)",
    ],
    educationRequired: "bachelor's degree or associate's degree usually needed",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling"],
    salary: "$57,380",
  },
  {
    img: img3,
    jobTitle: "Medical & Health Services Managers",
    jobTitleAlternatives: [
      "Clinical Director",
      "Health Information Management Director (HIM Director)",
      "Health Information Manager (HIM Manager)",
      "Nursing Director",
    ],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling"],
    salary: "$104,830",
  },
  {
    img: img3,
    jobTitle: "Medical Appliance Technicians",
    jobTitleAlternatives: [
      "Lab Technician",
      "Orthotic and Prosthetic Technician (O and P Technician)",
      "Orthotic Technician",
      "Prosthetics Technician",
    ],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Manufacturing", "Health & Counseling", "Wholesale/Commercial Sales"],
    salary: "$42,160",
  },
  {
    img: img3,
    jobTitle: "Medical Assistants",
    jobTitleAlternatives: ["Certified Medical Assistant (CMA)", "Chiropractor Assistant", "Doctor's Assistant", "Registered Medical Assistant (RMA)"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling"],
    salary: "$38,270",
  },
  {
    img: img3,
    jobTitle: "Medical Dosimetrists",
    jobTitleAlternatives: [[]],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling"],
    salary: "$128,970",
  },
  {
    img: img3,
    jobTitle: "Medical Equipment Preparers",
    jobTitleAlternatives: [
      "Central Service Technician (CST)",
      "Central Sterile Supply Technician (CSS Technician)",
      "Certified Registered Central Service Technician (CRCST)",
      "Sterile Processing Technician (Sterile Processing Tech)",
    ],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling"],
    salary: "$41,480",
  },
  {
    img: img3,
    jobTitle: "Medical Equipment Repairers",
    jobTitleAlternatives: [
      "Biomed Tech (Biomedical Technician)",
      "Biomedical Electronics Technician",
      "Biomedical Engineering Technician (BMET)",
      "Biomedical Equipment Technician (BMET)",
    ],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Wholesale/Commercial Sales", "Health & Counseling", "Service"],
    salary: "$57,860",
  },
  {
    img: img3,
    jobTitle: "Medical Records Specialists",
    jobTitleAlternatives: [
      "Health Information Technician (Health Information Tech)",
      "Medical Records Clerk",
      "Medical Records Coordinator",
      "Registered Health Information Technician (RHIT)",
    ],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling"],
    salary: "$47,180",
  },
  {
    img: img3,
    jobTitle: "Medical Scientists",
    jobTitleAlternatives: ["Clinical Research Scientist", "Research Scientist", "Scientist", "Study Director"],
    educationRequired: "Doctoral Degree",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Health & Counseling", "Education"],
    salary: "$99,930",
  },
  {
    img: img3,
    jobTitle: "Medical Secretaries & Administrative Assistants",
    jobTitleAlternatives: ["Medical Receptionist", "Medical Secretary", "Unit Clerk", "Unit Support Representative"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling"],
    salary: "$38,500",
  },
  {
    img: img3,
    jobTitle: "Medical Transcriptionists",
    jobTitleAlternatives: ["Medical Scribe", "Medical Transcriptionist", "Radiology Transcriptionist", "Transcriptionist"],
    educationRequired: "certificate after high school or some college usually needed",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling", "Administration & Support Services"],
    salary: "$34,730",
  },
  {
    img: img3,
    jobTitle: "Meeting, Convention, & Event Planners",
    jobTitleAlternatives: ["Conference Planning Manager", "Conference Services Manager", "Convention Services Manager (CSM)", "Events Manager"],
    educationRequired: "bachelor's degree or some college usually needed",
    jobOutlook: "Salary:",
    industry: ["Service", "Education", "Arts & Entertainment"],
    salary: "$52,560",
  },
  {
    img: img3,
    jobTitle: "Mental Health & Substance Abuse Social Workers",
    jobTitleAlternatives: ["Case Manager", "Mental Health Therapist", "Social Worker", "Therapist"],
    educationRequired: "Master's Degree",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling", "Government"],
    salary: "$51,240",
  },
  {
    img: img3,
    jobTitle: "Mental Health Counselors",
    jobTitleAlternatives: ["Clinician", "Counselor", "Mental Health Specialist", "Mental Health Therapist"],
    educationRequired: "Master's Degree",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling"],
    salary: "$49,710",
  },
  {
    img: img3,
    jobTitle: "Merchandise Displayers & Window Trimmers",
    jobTitleAlternatives: ["Display Associate", "Merchandiser", "Visual Merchandiser (VM)", "Visual Merchandising Specialist"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Retail", "Wholesale/Commercial Sales", "Professional, Science, & Technical"],
    salary: "$35,380",
  },
  {
    img: img3,
    jobTitle: "Metal-Refining Furnace Operators & Tenders",
    jobTitleAlternatives: ["Central Melt Specialist", "Furnace Operator", "Melter", "Vacuum Melter"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing"],
    salary: "$50,280",
  },
  {
    img: img3,
    jobTitle: "Meter Readers, Utilities",
    jobTitleAlternatives: ["Meter Reader", "Meter Technician", "Water Meter Reader", "Water Use Inspector"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Government", "Utilities", "Administration & Support Services"],
    salary: "$44,760",
  },
  {
    img: img3,
    jobTitle: "Microbiologists",
    jobTitleAlternatives: ["Bacteriologist", "Microbiological Analyst", "Microbiologist", "Quality Control Microbiologist (QC Microbiologist)"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Government", "Manufacturing"],
    salary: "$81,990",
  },
  {
    img: img3,
    jobTitle: "Microsystems Engineers",
    jobTitleAlternatives: ["Process Engineer", "Product Design Engineer", "Project Engineer", "Radio Frequency Design Engineer (RF Design Engineer)"],
    educationRequired: "Master's Degree",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Government", "Manufacturing"],
    salary: "$104,600",
  },
  {
    img: img3,
    jobTitle: "Middle School Teachers",
    jobTitleAlternatives: ["Language Arts Teacher", "Mathematics Teacher (Math Teacher)", "Science Teacher", "Teacher"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Education"],
    salary: "$61,810",
  },
  {
    img: img3,
    jobTitle: "Midwives",
    jobTitleAlternatives: [
      "Certified Professional Midwife (CPM)",
      "Homebirth Midwife",
      "Licensed Certified Professional Midwife",
      "Licensed Midwife (LM)",
    ],
    educationRequired: "certificate after high school or master's degree usually needed",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling", "Government"],
    salary: "$60,160",
  },
  {
    img: img3,
    jobTitle: "Milling & Planing Machine Setters, Operators, & Tenders, Metal & Plastic",
    jobTitleAlternatives: ["Machine Operator", "Mill Operator", "Miller", "Milling Operator"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing"],
    salary: "$46,870",
  },
  {
    img: img3,
    jobTitle: "Millwrights",
    jobTitleAlternatives: ["Maintenance Millwright", "Millwright", "Millwright Business Representative", "Precision Millwright"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Construction", "Manufacturing"],
    salary: "$60,930",
  },
  {
    img: img3,
    jobTitle: "Mining & Geological Engineers, Including Mining Safety Engineers",
    jobTitleAlternatives: ["Mine Engineer", "Mining Consultant", "Mining Engineer", "Project Engineer"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Mining, Oil, & Gas"],
    salary: "$97,490",
  },
  {
    img: img3,
    jobTitle: "Mixing & Blending Machine Setters, Operators, & Tenders",
    jobTitleAlternatives: ["Machine Operator", "Mixer", "Mixer Operator", "Stock Preparation Operator (Stock Prep Operator)"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing"],
    salary: "$43,410",
  },
  {
    img: img3,
    jobTitle: "Mobile Heavy Equipment Mechanics",
    jobTitleAlternatives: ["Equipment Mechanic", "Heavy Equipment Mechanic", "Heavy Equipment Technician", "Mechanic"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Wholesale/Commercial Sales", "Construction", "Real Estate & Rentals", "Government"],
    salary: "$59,440",
  },
  {
    img: img3,
    jobTitle: "Model Makers, Metal & Plastic",
    jobTitleAlternatives: ["Metal Model Maker", "Model Builder", "Model Maker", "Molding Technician"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing", "Professional, Science, & Technical"],
    salary: "$57,620",
  },
  {
    img: img3,
    jobTitle: "Model Makers, Wood",
    jobTitleAlternatives: ["Craftsman", "Model Maker", "Sample Maker", "Sample Worker"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing", "Government"],
    salary: "$46,940",
  },
  {
    img: img3,
    jobTitle: "Models",
    jobTitleAlternatives: ["Art Model", "Artist's Model", "Figure Model", "Model"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Education"],
    salary: "$43,130",
  },
  {
    img: img3,
    jobTitle: "Molders, Shapers, & Casters",
    jobTitleAlternatives: ["Caster", "Mold Mechanic", "Molder", "Molding Line Operator"],
    educationRequired: "Associate's Degree",
    jobOutlook: "Salary:",
    industry: ["Manufacturing"],
    salary: "$39,590",
  },
  {
    img: img3,
    jobTitle: "Molding, Coremaking, & Casting Machine Setters, Operators, & Tenders, Metal & Plastic",
    jobTitleAlternatives: ["Machine Operator", "Molder", "Process Technician", "Production Technician"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing"],
    salary: "$37,050",
  },
  {
    img: img3,
    jobTitle: "Molecular & Cellular Biologists",
    jobTitleAlternatives: ["Molecular Biologist", "Research Scientist"],
    educationRequired: "post-doctoral training or bachelor's degree usually needed",
    jobOutlook: "Salary:",
    industry: ["Government", "Professional, Science, & Technical"],
    salary: "$87,300",
  },
  {
    img: img3,
    jobTitle: "Morticians, Undertakers, & Funeral Arrangers",
    jobTitleAlternatives: ["Funeral Arranger", "Funeral Director", "Licensed Funeral Director", "Mortician"],
    educationRequired: "associate's degree or certificate after high school usually needed",
    jobOutlook: "Salary:",
    industry: ["Service"],
    salary: "$51,570",
  },
  {
    img: img3,
    jobTitle: "Motion Picture Projectionists",
    jobTitleAlternatives: ["Booth Operator", "Movie Projectionist", "Projectionist", "Technical Projection Guide"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Media & Communication"],
    salary: "$33,360",
  },
  {
    img: img3,
    jobTitle: "Motorboat Mechanics & Service Technicians",
    jobTitleAlternatives: ["Marine Mechanic", "Marine Technician", "Mechanic", "Service Technician"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Retail", "Service", "Arts & Entertainment"],
    salary: "$48,280",
  },
  {
    img: img3,
    jobTitle: "Motorboat Operators",
    jobTitleAlternatives: ["Boat Operator", "Crew Boat Operator", "Launch Operator", "Water Taxi Operator"],
    educationRequired: "certificate after high school or some college usually needed",
    jobOutlook: "Salary:",
    industry: ["Transportation & Storage", "Construction", "Arts & Entertainment", "Government"],
    salary: "$41,430",
  },
  {
    img: img3,
    jobTitle: "Motorcycle Mechanics",
    jobTitleAlternatives: ["All Terrain Vehicle Technician (ATV Technician)", "Motorcycle Mechanic", "Motorcycle Technician", "Service Technician"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Retail", "Service"],
    salary: "$43,370",
  },
  {
    img: img3,
    jobTitle: "Multiple Machine Tool Setters, Operators, & Tenders, Metal & Plastic",
    jobTitleAlternatives: [
      "Cell Technician",
      "CNC Machine Setter (Computer Numerically Controlled Machine Setter)",
      "Machine Operator",
      "Machine Technician",
    ],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing", "Administration & Support Services"],
    salary: "$39,210",
  },
  {
    img: img3,
    jobTitle: "Museum Technicians & Conservators",
    jobTitleAlternatives: ["Conservator", "Objects Conservator", "Paintings Conservator", "Preparator"],
    educationRequired: "Master's Degree",
    jobOutlook: "Salary:",
    industry: ["Arts & Entertainment", "Government", "Education"],
    salary: "$47,270",
  },
  {
    img: img3,
    jobTitle: "Music Directors & Composers",
    jobTitleAlternatives: ["Choir Director", "Conductor", "Music Composer", "Music Director"],
    educationRequired: "Master's Degree",
    jobOutlook: "Salary:",
    industry: ["Service"],
    salary: "$62,940",
  },
  {
    img: img3,
    jobTitle: "Music Therapists",
    jobTitleAlternatives: [
      "Board Certified Music Therapist (MT-BC)",
      "LCAT (Licensed Creative Arts Therapist)",
      "Music Therapist",
      "Neurologic Music Therapist",
    ],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling"],
    salary: "$60,800",
  },
  {
    img: img3,
    jobTitle: "Musical Instrument Repairers & Tuners",
    jobTitleAlternatives: ["Banjo Repair Person", "Luthier", "Piano Technician", "Piano Tuner"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Retail", "Service"],
    salary: "$38,150",
  },
  {
    img: img3,
    jobTitle: "Musicians & Singers",
    jobTitleAlternatives: ["Choir Member", "Musician", "Singer", "Violinist"],
    educationRequired: "Master's Degree",
    jobOutlook: "Salary:",
    industry: ["Service"],
    salary: "$39.14",
  },
  {
    img: img3,
    jobTitle: "Nannies",
    jobTitleAlternatives: ["Family Manager", "Household Manager", "Nanny"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling", "Service", "Education"],
    salary: "$28,520",
  },
  {
    img: img3,
    jobTitle: "Nanosystems Engineers",
    jobTitleAlternatives: [[]],
    educationRequired: "Master's Degree",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Government", "Manufacturing"],
    salary: "$104,600",
  },
  {
    img: img3,
    jobTitle: "Nanotechnology Engineering Technologists & Technicians",
    jobTitleAlternatives: ["Engineering Technician", "Nanofabrication Specialist", "Research Associate", "Research Scientist"],
    educationRequired: "Master's Degree",
    jobOutlook: "Salary:",
    industry: ["Manufacturing", "Professional, Science, & Technical"],
    salary: "$61,210",
  },
  {
    img: img3,
    jobTitle: "Natural Sciences Managers",
    jobTitleAlternatives: [
      "Chemical Process Exploration Manager",
      "Environmental Program Manager",
      "Natural Sciences Manager",
      "Research and Development Director (R and D Director)",
    ],
    educationRequired: "bachelor's degree or post-doctoral training usually needed",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Government"],
    salary: "$144,440",
  },
  {
    img: img3,
    jobTitle: "Naturopathic Physicians",
    jobTitleAlternatives: ["Doctor (Dr)", "Naturopathic Doctor", "Naturopathic Medicine Doctor", "Physician"],
    educationRequired: "Doctoral Degree",
    jobOutlook: "Salary:",
    industry: ["Government", "Health & Counseling"],
    salary: "$106,230",
  },
  {
    img: img3,
    jobTitle: "Network & Computer Systems Administrators",
    jobTitleAlternatives: [
      "Information Technology Specialist (IT Specialist)",
      "Local Area Network Administrator (LAN Administrator)",
      "Network Administrator",
      "Systems Administrator",
    ],
    educationRequired: "bachelor's degree or associate's degree usually needed",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Education", "Media & Communication"],
    salary: "$90,520",
  },
  {
    img: img3,
    jobTitle: "Neurodiagnostic Technologists",
    jobTitleAlternatives: [
      "Certified Neurodiagnostic Technologist",
      "Electroneurodiagnostic Technologist (END Technologist)",
      "Registered Electroencephalogram Technologist (Registered EEG Tech)",
      "Registered Polysomnographic Technologist (RPSGT)",
    ],
    educationRequired: "associate's degree or certificate after high school usually needed",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling"],
    salary: "$44,990",
  },
  {
    img: img3,
    jobTitle: "Neurologists",
    jobTitleAlternatives: ["Adult and Pediatric Neurologist", "Neurologist", "Pediatric Neurologist", "Physician"],
    educationRequired: "Doctoral Degree",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling"],
    salary: "$224,260",
  },
  {
    img: img3,
    jobTitle: "Neuropsychologists",
    jobTitleAlternatives: ["Neuropsychologist", "Neuropsychology Medical Consultant", "Pediatric Neuropsychologist", "Staff Psychologist"],
    educationRequired: "Doctoral Degree",
    jobOutlook: "Salary:",
    industry: ["Government"],
    salary: "$106,420",
  },
  {
    img: img3,
    jobTitle: "New Accounts Clerks",
    jobTitleAlternatives: ["Financial Services Representative", "Member Service Representative", "New Accounts Representative", "Personal Banker"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Finance & Insurance"],
    salary: "$40,820",
  },
  {
    img: img3,
    jobTitle: "News Analysts, Reporters, & Journalists",
    jobTitleAlternatives: ["Anchor", "News Anchor", "News Reporter", "Reporter"],
    educationRequired: "Master's Degree",
    jobOutlook: "Salary:",
    industry: ["Media & Communication"],
    salary: "$55,960",
  },
  {
    img: img3,
    jobTitle: "Non-Destructive Testing Specialists",
    jobTitleAlternatives: [
      "Industrial Radiographer",
      "Non-Destructive Evaluation Technician (NDE Technician)",
      "Non-Destructive Testing Specialist (NDT Specialist)",
      "Non-Destructive Testing Technician (NDT Technician)",
    ],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Government", "Manufacturing", "Administration & Support Services"],
    salary: "$65,520",
  },
  {
    img: img3,
    jobTitle: "Nuclear Engineers",
    jobTitleAlternatives: ["Engineer", "Nuclear Engineer", "Nuclear Licensing Engineer", "Nuclear Reactor Engineer"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Utilities", "Professional, Science, & Technical", "Government"],
    salary: "$122,480",
  },
  {
    img: img3,
    jobTitle: "Nuclear Medicine Technologists",
    jobTitleAlternatives: [
      "Certified Nuclear Medicine Technologist (CNMT)",
      "Nuclear Cardiology Technologist",
      "Nuclear Medicine Technologist (NMT)",
      "Staff Nuclear Medicine Technologist",
    ],
    educationRequired: "Associate's Degree",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling"],
    salary: "$85,300",
  },
  {
    img: img3,
    jobTitle: "Nuclear Monitoring Technicians",
    jobTitleAlternatives: [
      "Health Physics Technician (HP Tech)",
      "Radiation Protection Specialist (RP Specialist)",
      "Radiation Protection Technician (RPT)",
      "Radiochemical Technician",
    ],
    educationRequired: "Associate's Degree",
    jobOutlook: "Salary:",
    industry: ["Utilities", "Professional, Science, & Technical"],
    salary: "$100,420",
  },
  {
    img: img3,
    jobTitle: "Nuclear Power Reactor Operators",
    jobTitleAlternatives: ["Nuclear Control Operator", "Nuclear Reactor Operator", "Nuclear Station Operator (NSO)", "Reactor Operator (RO)"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Utilities", "Government"],
    salary: "$115,870",
  },
  {
    img: img3,
    jobTitle: "Nuclear Technicians",
    jobTitleAlternatives: ["Licensed Nuclear Operator", "Nuclear Auxiliary Operator", "Nuclear Equipment Operator (NEO)", "Operations Technician"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Utilities", "Professional, Science, & Technical"],
    salary: "$100,420",
  },
  {
    img: img3,
    jobTitle: "Nurse Anesthetists",
    jobTitleAlternatives: [
      "Certified Registered Nurse Anesthetist (CRNA)",
      "Nurse Anesthetist",
      "Staff Certified Registered Nurse Anesthetist (Staff CRNA)",
      "Staff Nurse Anesthetist",
    ],
    educationRequired: "Doctoral Degree",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling"],
    salary: "$203,090",
  },
  {
    img: img3,
    jobTitle: "Nurse Midwives",
    jobTitleAlternatives: ["Certified Nurse Midwife (CNM)", "Nurse Midwife", "Staff Certified Nurse Midwife", "Staff Nurse Midwife"],
    educationRequired: "Master's Degree",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling"],
    salary: "$120,880",
  },
  {
    img: img3,
    jobTitle: "Nurse Practitioners",
    jobTitleAlternatives: [
      "ACNP (Acute Care Nurse Practitioner)",
      "Family Nurse Practitioner (FNP)",
      "Gastroenterology Nurse Practitioner",
      "Nurse Practitioner (NP)",
    ],
    educationRequired: "Master's Degree",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling"],
    salary: "$121,610",
  },
  {
    img: img3,
    jobTitle: "Nursing Assistants",
    jobTitleAlternatives: ["Certified Nurses Aide (CNA)", "Certified Nursing Assistant (CNA)", "Nursing Assistant", "Patient Care Assistant (PCA)"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling"],
    salary: "$35,760",
  },
  {
    img: img3,
    jobTitle: "Nursing Instructors & Teachers, Postsecondary",
    jobTitleAlternatives: ["Assistant Professor", "Nursing Instructor", "Nursing Professor", "Professor"],
    educationRequired: "Master's Degree",
    jobOutlook: "Salary:",
    industry: ["Education"],
    salary: "$78,580",
  },
  {
    img: img3,
    jobTitle: "Obstetricians & Gynecologists",
    jobTitleAlternatives: ["Medical Doctor (MD)", "OB/GYN (Obstetrician / Gynecologist)", "Obstetrician/Gynecologist (OB/GYN)", "Physician"],
    educationRequired: "Doctoral Degree",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling"],
    salary: "$239,200+",
  },
  {
    img: img3,
    jobTitle: "Occupational Health & Safety Specialists",
    jobTitleAlternatives: [
      "Certified Industrial Hygienist (CIH)",
      "Environmental Health and Safety Officer",
      "Industrial Hygienist",
      "Safety Specialist",
    ],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Government", "Manufacturing", "Professional, Science, & Technical", "Construction"],
    salary: "$78,570",
  },
  {
    img: img3,
    jobTitle: "Occupational Health & Safety Technicians",
    jobTitleAlternatives: [
      "Certified Industrial Hygienist (CIH)",
      "Construction Safety Consultant",
      "Industrial Hygiene Consultant",
      "Industrial Hygienist",
    ],
    educationRequired: "bachelor's degree or associate's degree usually needed",
    jobOutlook: "Salary:",
    industry: ["Manufacturing", "Professional, Science, & Technical", "Construction"],
    salary: "$57,970",
  },
  {
    img: img3,
    jobTitle: "Occupational Therapists",
    jobTitleAlternatives: [
      "Certified Hand Therapist (CHT)",
      "Occupational Therapist (OT)",
      "Pediatric Occupational Therapist (Pediatric OT)",
      "Registered Occupational Therapist (OTR)",
    ],
    educationRequired: "Master's Degree",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling", "Education"],
    salary: "$93,180",
  },
  {
    img: img3,
    jobTitle: "Occupational Therapy Aides",
    jobTitleAlternatives: [
      "Occupational Therapy Aide (OT Aide)",
      "Rehabilitation Aide (Rehab Aide)",
      "Rehabilitation Services Aide",
      "Restorative Aide",
    ],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling"],
    salary: "$37,060",
  },
  {
    img: img3,
    jobTitle: "Occupational Therapy Assistants",
    jobTitleAlternatives: [
      "Certified Occupational Therapist Assistant (COTA)",
      "Certified Occupational Therapy Assistant (COTA)",
      "Licensed Occupational Therapy Assistant (LOTA)",
      "Occupational Therapy Assistant (OTA)",
    ],
    educationRequired: "associate's degree usually needed",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling"],
    salary: "$64,250",
  },
  {
    img: img3,
    jobTitle: "Office Clerks, General",
    jobTitleAlternatives: ["Clerk", "Office Assistant", "Office Clerk", "Office Services Specialist"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling", "Education", "Government"],
    salary: "$38,040",
  },
  {
    img: img3,
    jobTitle: "Office Machine Operators",
    jobTitleAlternatives: ["Copy Center Operator", "Copy Machine Operator", "Key Operator", "Machine Operator"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Administration & Support Services", "Finance & Insurance", "Media & Communication"],
    salary: "$36,710",
  },
  {
    img: img3,
    jobTitle: "Online Merchants",
    jobTitleAlternatives: ["Online Services Manager"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Government", "Professional, Science, & Technical", "Education"],
    salary: "$75,990",
  },
  {
    img: img3,
    jobTitle: "Operating Engineers & Other Construction Equipment Operators",
    jobTitleAlternatives: ["Equipment Operator (EO)", "Heavy Equipment Operator (HEO)", "Machine Operator", "Operating Engineer"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Construction", "Government"],
    salary: "$51,430",
  },
  {
    img: img3,
    jobTitle: "Operations Research Analysts",
    jobTitleAlternatives: ["Advanced Analytics Associate", "Analytical Strategist", "Decision Analyst", "Operations Research Analyst"],
    educationRequired: "Master's Degree",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Finance & Insurance", "Government"],
    salary: "$85,720",
  },
  {
    img: img3,
    jobTitle: "Ophthalmic Laboratory Technicians",
    jobTitleAlternatives: [
      "Lab Technician (Laboratory Technician)",
      "Optical Lab Technician (Optical Laboratory Technician)",
      "Optical Technician",
      "Surfacing Technician",
    ],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing", "Health & Counseling", "Retail"],
    salary: "$36,810",
  },
  {
    img: img3,
    jobTitle: "Ophthalmic Medical Technicians",
    jobTitleAlternatives: [
      "Certified Ophthalmic Surgical Assistant",
      "Certified Ophthalmic Technician (COT)",
      "Ophthalmic Assistant",
      "Ophthalmic Tech (Ophthalmic Technician)",
    ],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling"],
    salary: "$38,860",
  },
  {
    img: img3,
    jobTitle: "Ophthalmic Medical Technologists",
    jobTitleAlternatives: [
      "Certified Ophthalmic Medical Technologist (COMT)",
      "Ophthalmic Medical Technologist (Ophthalmic Medical Tech)",
      "Ophthalmic Technologist (Ophthalmic Tech)",
      "Surgical Coordinator",
    ],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling"],
    salary: "$44,990",
  },
  {
    img: img3,
    jobTitle: "Ophthalmologists",
    jobTitleAlternatives: ["Glaucoma Specialist", "Ophthalmologist", "Physician", "Retina Specialist"],
    educationRequired: "Doctoral Degree",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling"],
    salary: "$219,810",
  },
  {
    img: img3,
    jobTitle: "Opticians, Dispensing",
    jobTitleAlternatives: ["Dispensing Optician", "Licensed Dispensing Optician (LDO)", "Licensed Optician", "Optician"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling", "Retail"],
    salary: "$39,610",
  },
  {
    img: img3,
    jobTitle: "Optometrists",
    jobTitleAlternatives: ["Optometrist", "Optometry Doctor (OD)", "Therapeutic Optometrist"],
    educationRequired: "Doctoral Degree",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling", "Retail"],
    salary: "$125,590",
  },
  {
    img: img3,
    jobTitle: "Oral & Maxillofacial Surgeons",
    jobTitleAlternatives: ["Doctor of Dental Surgery (DDS)", "Oral and Maxillofacial Surgeon (OMS)", "Oral Surgeon", "Surgeon"],
    educationRequired: "Doctoral Degree",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling"],
    salary: "$239,200+",
  },
  {
    img: img3,
    jobTitle: "Order Clerks",
    jobTitleAlternatives: ["Materials Specialist", "Order Clerk", "Sales Assistant", "Warehouse Clerk"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Retail", "Wholesale/Commercial Sales", "Manufacturing", "Transportation & Storage"],
    salary: "$38,060",
  },
  {
    img: img3,
    jobTitle: "Orderlies",
    jobTitleAlternatives: ["Patient Care Assistant (PCA)", "Patient Care Technician (PCT)", "Patient Transporter", "Transporter"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling"],
    salary: "$34,520",
  },
  {
    img: img3,
    jobTitle: "Orthodontists",
    jobTitleAlternatives: ["Board Certified Orthodontist", "Orthodontics and Dentofacial Orthopedics Specialist", "Orthodontist"],
    educationRequired: "post-doctoral training usually needed",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling"],
    salary: "$174,360",
  },
  {
    img: img3,
    jobTitle: "Orthopedic Surgeons",
    jobTitleAlternatives: ["Hand Surgeon", "Orthopedic Surgeon", "Physician", "Surgeon"],
    educationRequired: "Doctoral Degree",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling"],
    salary: "$239,200+",
  },
  {
    img: img3,
    jobTitle: "Orthoptists",
    jobTitleAlternatives: ["Certified Orthoptist", "Clinical Orthoptist (CO)", "Orthoptist"],
    educationRequired: "certificate after college usually needed",
    jobOutlook: "Salary:",
    industry: ["Government", "Health & Counseling"],
    salary: "$106,230",
  },
  {
    img: img3,
    jobTitle: "Orthotists & Prosthetists",
    jobTitleAlternatives: ["Certified Orthotist (CO)", "Certified Prosthetist (CP)", "Certified Prosthetist and Orthotist (CPO)", "Orthotist"],
    educationRequired: "master's degree or certificate after college usually needed",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling", "Manufacturing", "Retail"],
    salary: "$77,070",
  },
  {
    img: img3,
    jobTitle: "Outdoor Power Equipment & Other Small Engine Mechanics",
    jobTitleAlternatives: ["Mechanic", "Outdoor Power Equipment Service Technician", "Service Technician (Service Tech)", "Small Engine Mechanic"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Retail", "Administration & Support Services", "Arts & Entertainment"],
    salary: "$40,030",
  },
  {
    img: img3,
    jobTitle: "Packaging & Filling Machine Operators & Tenders",
    jobTitleAlternatives: ["Bundler", "Filler Operator", "Machine Operator", "Packaging Operator"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing", "Administration & Support Services"],
    salary: "$36,750",
  },
  {
    img: img3,
    jobTitle: "Packers & Packagers, Hand",
    jobTitleAlternatives: ["Bagger", "Packager", "Packer", "Selector Packer"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Retail", "Manufacturing", "Administration & Support Services", "Transportation & Storage"],
    salary: "$32,920",
  },
  {
    img: img3,
    jobTitle: "Painters, Construction & Maintenance",
    jobTitleAlternatives: ["Facilities Painter", "Industrial Painter", "Maintenance Painter", "Painter"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Construction"],
    salary: "$46,090",
  },
  {
    img: img3,
    jobTitle: "Painting, Coating, & Decorating Workers",
    jobTitleAlternatives: ["Decorator", "Glazer", "Painter", "Pottery Decorator"],
    educationRequired: "No High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing"],
    salary: "$38,270",
  },
  {
    img: img3,
    jobTitle: "Paper Goods Machine Setters, Operators, & Tenders",
    jobTitleAlternatives: ["Cup Room Technician", "Folder Machine Operator", "Paper Machine Backtender", "Paper Machine Operator"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing"],
    salary: "$45,710",
  },
  {
    img: img3,
    jobTitle: "Paperhangers",
    jobTitleAlternatives: ["Bill Poster", "Paper Hanger", "Paperhanger", "Wallpaper Hanger"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Construction"],
    salary: "$44,930",
  },
  {
    img: img3,
    jobTitle: "Paralegals & Legal Assistants",
    jobTitleAlternatives: ["Law Associate", "Legal Assistant", "Paralegal", "Paralegal Specialist"],
    educationRequired: "Associate's Degree",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Government"],
    salary: "$59,200",
  },
  {
    img: img3,
    jobTitle: "Paramedics",
    jobTitleAlternatives: ["First Responder", "Flight Paramedic"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling", "Government"],
    salary: "$49,090",
  },
  {
    img: img3,
    jobTitle: "Park Naturalists",
    jobTitleAlternatives: ["Environmental Education Specialist", "Naturalist", "Park Naturalist", "Park Ranger"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Government", "Service"],
    salary: "$64,460",
  },
  {
    img: img3,
    jobTitle: "Parking Attendants",
    jobTitleAlternatives: ["Parking Attendant", "Parking Lot Attendant", "Valet Attendant", "Valet Parker"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Service", "Retail"],
    salary: "$30,570",
  },
  {
    img: img3,
    jobTitle: "Parking Enforcement Workers",
    jobTitleAlternatives: ["Parking Control Officer", "Parking Enforcement Officer (PEO)", "Parking Enforcer", "Ticket Writer"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Government", "Education"],
    salary: "$41,570",
  },
  {
    img: img3,
    jobTitle: "Parts Salespersons",
    jobTitleAlternatives: ["Parts Clerk", "Parts Salesman", "Parts Salesperson", "Parts Specialist"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Retail", "Wholesale/Commercial Sales"],
    salary: "$35,800",
  },
  {
    img: img3,
    jobTitle: "Passenger Attendants",
    jobTitleAlternatives: ["Bus Aide", "Bus Assistant", "Bus Attendant", "Bus Monitor"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Transportation & Storage", "Government"],
    salary: "$34,630",
  },
  {
    img: img3,
    jobTitle: "Patient Representatives",
    jobTitleAlternatives: ["Case Manager", "Medicaid Service Coordinator (MSC)", "Patient Advocate", "Patient Service Representative"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling"],
    salary: "$44,990",
  },
  {
    img: img3,
    jobTitle: "Patternmakers, Metal & Plastic",
    jobTitleAlternatives: ["Metal Pattern Maker", "Pattern Maker", "Patternmaker", "Wax Molder"],
    educationRequired: "some college or high school diploma/GED usually needed",
    jobOutlook: "Salary:",
    industry: ["Manufacturing"],
    salary: "$54,970",
  },
  {
    img: img3,
    jobTitle: "Patternmakers, Wood",
    jobTitleAlternatives: ["Mold Maker", "Pattern Maker", "Patternmaker", "Wood Pattern Maker"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing"],
    salary: "$44,290",
  },
  {
    img: img3,
    jobTitle: "Paving, Surfacing, & Tamping Equipment Operators",
    jobTitleAlternatives: ["Equipment Operator (EO)", "Paver Operator", "Roller Operator", "Screed Operator"],
    educationRequired: "No High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Construction", "Government"],
    salary: "$47,270",
  },
  {
    img: img3,
    jobTitle: "Payroll & Timekeeping Clerks",
    jobTitleAlternatives: ["Payroll Assistant", "Payroll Clerk", "Payroll Coordinator", "Payroll Specialist"],
    educationRequired: "bachelor's degree or some college usually needed",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Administration & Support Services", "Health & Counseling"],
    salary: "$49,630",
  },
  {
    img: img3,
    jobTitle: "Pediatric Surgeons",
    jobTitleAlternatives: ["General Surgeon", "Physician", "Surgeon", "Thoracic Surgeon"],
    educationRequired: "Doctoral Degree",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling"],
    salary: "$239,200+",
  },
  {
    img: img3,
    jobTitle: "Pediatricians, General",
    jobTitleAlternatives: ["General Pediatrician", "Group Practice Pediatrician", "Medical Doctor (MD)", "Physician"],
    educationRequired: "Doctoral Degree",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling"],
    salary: "$190,350",
  },
  {
    img: img3,
    jobTitle: "Penetration Testers",
    jobTitleAlternatives: [[]],
    educationRequired: "Master's Degree",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Government"],
    salary: "$98,740",
  },
  {
    img: img3,
    jobTitle: "Personal Care Aides",
    jobTitleAlternatives: ["Caregiver", "Home Care Aide", "Personal Care Aide", "Personal Care Attendant (PCA)"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling"],
    salary: "$30,180",
  },
  {
    img: img3,
    jobTitle: "Personal Financial Advisors",
    jobTitleAlternatives: ["Certified Financial Planner (CFP)", "Financial Advisor", "Financial Planner", "Portfolio Manager"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Finance & Insurance"],
    salary: "$95,390",
  },
  {
    img: img3,
    jobTitle: "Pest Control Workers",
    jobTitleAlternatives: ["Exterminator", "Pest Control Technician (Pest Control Tech)", "Pest Technician", "Residential Pest Control Technician"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Administration & Support Services"],
    salary: "$38,310",
  },
  {
    img: img3,
    jobTitle: "Pesticide Handlers, Sprayers, & Applicators, Vegetation",
    jobTitleAlternatives: ["Lawn Specialist", "Lawn Technician", "Licensed Pesticide Applicator", "Spray Technician"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Administration & Support Services", "Wholesale/Commercial Sales", "Farming, Forestry, Fishing, & Hunting"],
    salary: "$41,440",
  },
  {
    img: img3,
    jobTitle: "Petroleum Engineers",
    jobTitleAlternatives: ["Drilling Engineer", "Engineer", "Petroleum Engineer", "Reservoir Engineer"],
    educationRequired: "bachelor's degree or certificate after master's usually needed",
    jobOutlook: "Salary:",
    industry: ["Mining, Oil, & Gas", "Management", "Professional, Science, & Technical"],
    salary: "$131,800",
  },
  {
    img: img3,
    jobTitle: "Petroleum Pump System Operators, Refinery Operators, & Gaugers",
    jobTitleAlternatives: ["Board Operator", "Gauger", "Pumper", "Refinery Operator"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing", "Transportation & Storage", "Mining, Oil, & Gas"],
    salary: "$85,090",
  },
  {
    img: img3,
    jobTitle: "Pharmacists",
    jobTitleAlternatives: ["Clinical Pharmacist", "Hospital Pharmacist", "Pharm D (Pharmacy Doctor)", "Pharmacist in Charge (PIC)"],
    educationRequired: "Doctoral Degree",
    jobOutlook: "Salary:",
    industry: ["Retail", "Health & Counseling"],
    salary: "$132,750",
  },
  {
    img: img3,
    jobTitle: "Pharmacy Aides",
    jobTitleAlternatives: ["Certified Pharmacist Assistant", "Pharmacy Aide", "Pharmacy Assistant", "Pharmacy Clerk"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Retail", "Health & Counseling"],
    salary: "$33,270",
  },
  {
    img: img3,
    jobTitle: "Pharmacy Technicians",
    jobTitleAlternatives: [
      "Certified Pharmacy Technician (CPhT)",
      "Chemotherapy Pharmacy Technician (Chemo Pharmacy Technician)",
      "OR Pharmacy Tech (Operating Room Pharmacy Tech)",
      "RPhT (Registered Pharmacy Technician)",
    ],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Retail", "Health & Counseling"],
    salary: "$37,790",
  },
  {
    img: img3,
    jobTitle: "Philosophy & Religion Teachers, Postsecondary",
    jobTitleAlternatives: ["Assistant Professor", "Philosophy Professor", "Professor", "Religion Professor"],
    educationRequired: "Doctoral Degree",
    jobOutlook: "Salary:",
    industry: ["Education"],
    salary: "$78,780",
  },
  {
    img: img3,
    jobTitle: "Phlebotomists",
    jobTitleAlternatives: ["Lab Liaison Technician", "Mobile Examiner", "Phlebotomist", "Phlebotomy Technician"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling"],
    salary: "$38,530",
  },
  {
    img: img3,
    jobTitle: "Photographers",
    jobTitleAlternatives: ["Commercial Photographer", "Photographer", "Photojournalist", "Portrait Photographer"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical"],
    salary: "$40,170",
  },
  {
    img: img3,
    jobTitle: "Photographic Process Workers & Processing Machine Operators",
    jobTitleAlternatives: ["Photo Lab Technician (Photographic Laboratory Technician)", "Photo Printer", "Photo Specialist", "Photo Technician"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Service", "Retail"],
    salary: "$36,280",
  },
  {
    img: img3,
    jobTitle: "Photonics Engineers",
    jobTitleAlternatives: ["Laser Engineer", "Optical Design Engineer", "Optical Engineer", "Optical Systems Engineer"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Government", "Manufacturing"],
    salary: "$104,600",
  },
  {
    img: img3,
    jobTitle: "Photonics Technicians",
    jobTitleAlternatives: [
      "Fiber Optics Technician",
      "Laser Technician (Laser Tech)",
      "Photonic Laboratory Technician (Photonic Lab Tech)",
      "Photonics Technician",
    ],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Government", "Manufacturing", "Administration & Support Services"],
    salary: "$65,520",
  },
  {
    img: img3,
    jobTitle: "Physical Medicine & Rehabilitation Physicians",
    jobTitleAlternatives: ["MD (Medical Doctor)", "Pain Management Physician", "Physiatrist", "Physician"],
    educationRequired: "Doctoral Degree",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling", "Government"],
    salary: "$223,410",
  },
  {
    img: img3,
    jobTitle: "Physical Therapist Aides",
    jobTitleAlternatives: ["Physical Therapist Aide (PTA)", "Physical Therapy Aide (PTA)", "Rehabilitation Aide", "Restorative Aide (RA)"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling"],
    salary: "$31,410",
  },
  {
    img: img3,
    jobTitle: "Physical Therapist Assistants",
    jobTitleAlternatives: [
      "Certified Physical Therapist Assistant (CPTA)",
      "Licensed Physical Therapist Assistant (LPTA)",
      "Physical Therapist Assistant (PTA)",
      "Physical Therapy Assistant (PTA)",
    ],
    educationRequired: "Associate's Degree",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling"],
    salary: "$62,770",
  },
  {
    img: img3,
    jobTitle: "Physical Therapists",
    jobTitleAlternatives: [
      "Doctor of Physical Therapy (DPT)",
      "Home Care Physical Therapist (Home Care PT)",
      "Inpatient Physical Therapist (Inpatient PT)",
      "Pediatric Physical Therapist (Pediatric PT)",
    ],
    educationRequired: "Doctoral Degree",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling"],
    salary: "$97,720",
  },
  {
    img: img3,
    jobTitle: "Physician Assistants",
    jobTitleAlternatives: [
      "Certified Physician Assistant (PA-C)",
      "Family Practice Physician Assistant",
      "Physician Assistant (PA)",
      "Physician's Assistant",
    ],
    educationRequired: "Master's Degree",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling"],
    salary: "$126,010",
  },
  {
    img: img3,
    jobTitle: "Physicians, Pathologists",
    jobTitleAlternatives: ["Anatomic Pathologist", "Dermatopathologist", "Forensic Pathologist", "Pathologist"],
    educationRequired: "Doctoral Degree",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling"],
    salary: "$239,200+",
  },
  {
    img: img3,
    jobTitle: "Physicists",
    jobTitleAlternatives: ["Health Physicist", "Physicist", "Research Scientist", "Scientist"],
    educationRequired: "Doctoral Degree",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Government", "Education", "Health & Counseling"],
    salary: "$142,850",
  },
  {
    img: img3,
    jobTitle: "Physics Teachers, Postsecondary",
    jobTitleAlternatives: ["Instructor", "Physics Instructor", "Physics Professor", "Professor"],
    educationRequired: "Doctoral Degree",
    jobOutlook: "Salary:",
    industry: ["Education"],
    salary: "$86,550",
  },
  {
    img: img3,
    jobTitle: "Pile Driver Operators",
    jobTitleAlternatives: ["Pile Driver", "Pile Driver Operator", "Pile Driving Operator"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Construction"],
    salary: "$64,310",
  },
  {
    img: img3,
    jobTitle: "Pipelayers",
    jobTitleAlternatives: ["Pipelayer", "Tailman", "Waste Water Worker"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Construction", "Government"],
    salary: "$45,990",
  },
  {
    img: img3,
    jobTitle: "Plasterers & Stucco Masons",
    jobTitleAlternatives: ["Plaster Applicator", "Plaster Mechanic", "Plasterer", "Plasterer Journeyman"],
    educationRequired: "No High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Construction"],
    salary: "$49,730",
  },
  {
    img: img3,
    jobTitle: "Plating Machine Setters, Operators, & Tenders, Metal & Plastic",
    jobTitleAlternatives: ["Anodizer", "Chrome Plater", "Electro Plater", "Plater"],
    educationRequired: "No High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing"],
    salary: "$37,900",
  },
  {
    img: img3,
    jobTitle: "Plumbers, Pipefitters, & Steamfitters",
    jobTitleAlternatives: ["Pipefitter", "Plumber", "Sprinkler Fitter", "Steamfitter"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Construction"],
    salary: "$60,090",
  },
  {
    img: img3,
    jobTitle: "Podiatrists",
    jobTitleAlternatives: ["Doctor of Podiatric Medicine (DPM)", "Doctor Podiatric Medicine (DPM)", "Podiatric Surgeon", "Podiatrist"],
    educationRequired: "Doctoral Degree",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling"],
    salary: "$148,720",
  },
  {
    img: img3,
    jobTitle: "Poets, Lyricists & Creative Writers",
    jobTitleAlternatives: ["Author", "Freelance Writer", "Novelist", "Songwriter"],
    educationRequired: "bachelor's degree or no high school diploma/GED usually needed",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Media & Communication"],
    salary: "$73,150",
  },
  {
    img: img3,
    jobTitle: "Police & Sheriff's Patrol Officers",
    jobTitleAlternatives: ["Deputy", "Deputy Sheriff", "Patrol Officer", "Police Officer"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Government"],
    salary: "$65,790",
  },
  {
    img: img3,
    jobTitle: "Police Identification & Records Officers",
    jobTitleAlternatives: ["Crime Scene Investigator", "Crime Scene Technician", "Criminalist", "Forensic Specialist"],
    educationRequired: "Associate's Degree",
    jobOutlook: "Salary:",
    industry: ["Government"],
    salary: "$86,280",
  },
  {
    img: img3,
    jobTitle: "Political Science Teachers, Postsecondary",
    jobTitleAlternatives: ["Associate Professor", "Political Science Instructor", "Political Science Professor", "Professor"],
    educationRequired: "Doctoral Degree",
    jobOutlook: "Salary:",
    industry: ["Education"],
    salary: "$83,770",
  },
  {
    img: img3,
    jobTitle: "Political Scientists",
    jobTitleAlternatives: [[]],
    educationRequired: "Doctoral Degree",
    jobOutlook: "Salary:",
    industry: ["Government", "Professional, Science, & Technical", "Service"],
    salary: "$128,020",
  },
  {
    img: img3,
    jobTitle: "Postal Service Clerks",
    jobTitleAlternatives: ["Clerk", "Postal Clerk", "Sales and Service Associate (SSA)", "Window Clerk"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Government"],
    salary: "$56,200",
  },
  {
    img: img3,
    jobTitle: "Postal Service Mail Carriers",
    jobTitleAlternatives: ["City Letter Carrier", "Letter Carrier", "Mail Carrier", "Rural Carrier Associate (RCA)"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Government"],
    salary: "$54,250",
  },
  {
    img: img3,
    jobTitle: "Postal Service Mail Sorters, Processors, & Processing Machine Operators",
    jobTitleAlternatives: ["Automation Clerk", "Distribution Clerk", "Mail Handler", "Mail Processor"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Government"],
    salary: "$49,130",
  },
  {
    img: img3,
    jobTitle: "Postmasters & Mail Superintendents",
    jobTitleAlternatives: [
      "Distribution Operation Supervisor (SDO)",
      "Distribution Operations Manager",
      "Postmaster",
      "Remote Encoding Center Manager",
    ],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Government"],
    salary: "$82,760",
  },
  {
    img: img3,
    jobTitle: "Potters, Manufacturing",
    jobTitleAlternatives: ["Glazer", "Jiggerman", "Potter", "Production Potter"],
    educationRequired: "some college or high school diploma/GED usually needed",
    jobOutlook: "Salary:",
    industry: ["Manufacturing"],
    salary: "$39,590",
  },
  {
    img: img3,
    jobTitle: "Pourers & Casters, Metal",
    jobTitleAlternatives: ["Direct Chill Caster (DC Caster)", "Iron Pourer", "Metal Handler", "Pourer"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing"],
    salary: "$45,070",
  },
  {
    img: img3,
    jobTitle: "Power Distributors & Dispatchers",
    jobTitleAlternatives: ["Distribution System Operator", "Power System Operator", "System Operator", "Transmission System Operator"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Utilities", "Government", "Management"],
    salary: "$101,650",
  },
  {
    img: img3,
    jobTitle: "Power Plant Operators",
    jobTitleAlternatives: ["Auxiliary Operator", "Control Operator", "Control Room Operator", "Plant Control Operator"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Utilities", "Government"],
    salary: "$93,060",
  },
  {
    img: img3,
    jobTitle: "Precision Agriculture Technicians",
    jobTitleAlternatives: [
      "Crop Specialist",
      "Independent Crop Consultant",
      "Precision Agriculture Specialist (Precision Ag Specialist)",
      "Precision Farming Coordinator",
    ],
    educationRequired: "bachelor's degree or associate's degree usually needed",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Education", "Farming, Forestry, Fishing, & Hunting"],
    salary: "$41,760",
  },
  {
    img: img3,
    jobTitle: "Prepress Technicians & Workers",
    jobTitleAlternatives: ["Electronic Prepress Operator (EPP Operator)", "Plate Maker", "Prepress Operator", "Prepress Technician"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing"],
    salary: "$43,560",
  },
  {
    img: img3,
    jobTitle: "Preschool Teachers",
    jobTitleAlternatives: ["Montessori Preschool Teacher", "Pre-Kindergarten Teacher (Pre-K Teacher)", "Teacher", "Toddler Teacher"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling", "Education", "Service"],
    salary: "$35,330",
  },
  {
    img: img3,
    jobTitle: "Pressers, Textile, Garment, & Related Materials",
    jobTitleAlternatives: ["Ironing Worker", "Pants Presser", "Presser", "Shirt Presser"],
    educationRequired: "No High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Service"],
    salary: "$29,690",
  },
  {
    img: img3,
    jobTitle: "Preventive Medicine Physicians",
    jobTitleAlternatives: ["Occupational Medicine Physician", "Occupational Physician", "Public Health Officer", "Public Health Physician"],
    educationRequired: "Doctoral Degree",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling", "Government"],
    salary: "$223,410",
  },
  {
    img: img3,
    jobTitle: "Print Binding & Finishing Workers",
    jobTitleAlternatives: ["Binder Operator", "Bindery Operator", "Bindery Worker", "Book Binder"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing"],
    salary: "$36,970",
  },
  {
    img: img3,
    jobTitle: "Printing Press Operators",
    jobTitleAlternatives: ["Press Operator", "Pressman", "Printer", "Printing Press Operator"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing"],
    salary: "$39,350",
  },
  {
    img: img3,
    jobTitle: "Private Detectives & Investigators",
    jobTitleAlternatives: ["Investigator", "Loss Prevention Detective", "Loss Prevention Officer", "Private Investigator"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Administration & Support Services", "Professional, Science, & Technical", "Finance & Insurance", "Government", "Retail"],
    salary: "$52,120",
  },
  {
    img: img3,
    jobTitle: "Probation Officers & Correctional Treatment Specialists",
    jobTitleAlternatives: ["Correctional Counselor", "Juvenile Probation Officer", "Parole Officer (PO)", "Probation Officer"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Government"],
    salary: "$59,860",
  },
  {
    img: img3,
    jobTitle: "Procurement Clerks",
    jobTitleAlternatives: ["Buyer", "Procurement Specialist", "Purchasing Clerk", "Purchasing Specialist"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Government", "Manufacturing", "Wholesale/Commercial Sales", "Health & Counseling"],
    salary: "$45,240",
  },
  {
    img: img3,
    jobTitle: "Producers & Directors",
    jobTitleAlternatives: ["Artistic Director", "Director", "News Producer", "Producer"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Media & Communication", "Arts & Entertainment"],
    salary: "$85,320",
  },
  {
    img: img3,
    jobTitle: "Production, Planning, & Expediting Clerks",
    jobTitleAlternatives: ["Expeditor", "Materials Planner", "Production Planner", "Production Scheduler"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing", "Health & Counseling"],
    salary: "$50,630",
  },
  {
    img: img3,
    jobTitle: "Project Management Specialists",
    jobTitleAlternatives: [[]],
    educationRequired: "Master's Degree",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Construction"],
    salary: "$95,370",
  },
  {
    img: img3,
    jobTitle: "Proofreaders & Copy Markers",
    jobTitleAlternatives: ["Copy Editor", "News Copy Editor", "Proofreader", "Typesetter"],
    educationRequired: "bachelor's degree or associate's degree usually needed",
    jobOutlook: "Salary:",
    industry: ["Media & Communication", "Professional, Science, & Technical", "Administration & Support Services"],
    salary: "$45,410",
  },
  {
    img: img3,
    jobTitle: "Property, Real Estate, & Community Association Managers",
    jobTitleAlternatives: ["Apartment Manager", "Community Manager", "Property Manager", "Resident Manager"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Real Estate & Rentals"],
    salary: "$60,670",
  },
  {
    img: img3,
    jobTitle: "Prosthodontists",
    jobTitleAlternatives: ["Doctor of Dental Surgery (DDS)", "Maxillofacial Prosthodontist", "Prosthodontist", "Removable Prosthodontist"],
    educationRequired: "Doctoral Degree",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling"],
    salary: "$159,530",
  },
  {
    img: img3,
    jobTitle: "Psychiatric Aides",
    jobTitleAlternatives: ["Mental Health Worker (MHW)", "Psychiatric Aide", "Psychiatric Nursing Aide", "Therapeutic Program Worker (TPW)"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling", "Government"],
    salary: "$37,160",
  },
  {
    img: img3,
    jobTitle: "Psychiatric Technicians",
    jobTitleAlternatives: ["Mental Health Associate", "Mental Health Technician (MHT)", "Mental Health Worker", "Psychiatric Technician (PT)"],
    educationRequired: "high school diploma/GED or master's degree usually needed",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling"],
    salary: "$37,380",
  },
  {
    img: img3,
    jobTitle: "Psychiatrists",
    jobTitleAlternatives: ["Child Psychiatrist", "Outpatient Psychiatrist", "Psychiatrist", "Staff Psychiatrist"],
    educationRequired: "Doctoral Degree",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling", "Government"],
    salary: "$226,880",
  },
  {
    img: img3,
    jobTitle: "Psychology Teachers, Postsecondary",
    jobTitleAlternatives: ["Instructor", "Professor", "Psychology Instructor", "Psychology Professor"],
    educationRequired: "Doctoral Degree",
    jobOutlook: "Salary:",
    industry: ["Education"],
    salary: "$78,810",
  },
  {
    img: img3,
    jobTitle: "Psychology Teachers, Postsecondary",
    jobTitleAlternatives: ["Instructor", "Professor", "Psychology Instructor", "Psychology Professor"],
    educationRequired: "Doctoral Degree",
    jobOutlook: "Salary:",
    industry: ["Education"],
    salary: "$78,810",
  },
  {
    img: img3,
    jobTitle: "Public Relations Specialists",
    jobTitleAlternatives: [
      "Communications Specialist",
      "Public Affairs Specialist",
      "Public Information Officer",
      "Public Relations Specialist (PR Specialist)",
    ],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Service", "Professional, Science, & Technical", "Education", "Government"],
    salary: "$67,440",
  },
  {
    img: img3,
    jobTitle: "Public Safety Telecommunicators",
    jobTitleAlternatives: ["Communications Officer", "Communications Operator", "Dispatcher", "Public Safety Dispatcher"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Government"],
    salary: "$46,900",
  },
  {
    img: img3,
    jobTitle: "Pump Operators",
    jobTitleAlternatives: ["Outside Operator", "Pipeline Operator", "Pumper", "Tank Farm Operator"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Mining, Oil, & Gas", "Construction", "Transportation & Storage", "Wholesale/Commercial Sales"],
    salary: "$55,860",
  },
  {
    img: img3,
    jobTitle: "Purchasing Agents",
    jobTitleAlternatives: ["Buyer", "Procurement Official", "Procurement Specialist", "Purchasing Agent"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Manufacturing", "Wholesale/Commercial Sales", "Government"],
    salary: "$67,620",
  },
  {
    img: img3,
    jobTitle: "Purchasing Managers",
    jobTitleAlternatives: ["Materials Manager", "Procurement Director", "Procurement Manager", "Purchasing Director"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Manufacturing", "Management", "Government", "Wholesale/Commercial Sales"],
    salary: "$131,350",
  },
  {
    img: img3,
    jobTitle: "Quality Control Analysts",
    jobTitleAlternatives: [
      "Lab Technician (Lab Tech)",
      "QA Auditor (Quality Assurance Auditor)",
      "QA Tech (Quality Assurance Technician)",
      "Quality Control Technician (QC Tech)",
    ],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Education", "Professional, Science, & Technical", "Administration & Support Services", "Government"],
    salary: "$50,290",
  },
  {
    img: img3,
    jobTitle: "Quality Control Systems Managers",
    jobTitleAlternatives: [
      "Quality Assurance Director (QA Director)",
      "Quality Assurance Manager (QA Manager)",
      "Quality Control Manager (QC Manager)",
      "Quality Manager",
    ],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Manufacturing"],
    salary: "$107,560",
  },
  {
    img: img3,
    jobTitle: "Radiation Therapists",
    jobTitleAlternatives: ["Dosimetrist", "Radiation Therapist (RT)", "Registered Radiation Therapist", "Staff Radiation Therapist"],
    educationRequired: "Associate's Degree",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling"],
    salary: "$89,530",
  },
  {
    img: img3,
    jobTitle: "Radio Frequency Identification Device Specialists",
    jobTitleAlternatives: [
      "Deployment Engineer",
      "RFID Engineer (Radio Frequency Identification Device Engineer)",
      "RFID Systems Engineer (Radio Frequency Identification Device Systems Engineer)",
      "Technical Support Engineer",
    ],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Manufacturing", "Professional, Science, & Technical", "Media & Communication", "Government"],
    salary: "$108,170",
  },
  {
    img: img3,
    jobTitle: "Radio, Cellular, & Tower Equipment Installers & Repairers",
    jobTitleAlternatives: [
      "Radio Frequency Technician (RF Tech)",
      "Radio Technician (Radio Tech)",
      "Tower Technician (Tower Tech)",
      "Two-Way Radio Technician (Two-Way Radio Tech)",
    ],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Media & Communication", "Construction", "Retail", "Government"],
    salary: "$59,720",
  },
  {
    img: img3,
    jobTitle: "Radiologic Technologists & Technicians",
    jobTitleAlternatives: [
      "Computed Tomography Technologist (CT Tech)",
      "Radiographer",
      "Radiologic Technologist (RT)",
      "X-Ray Technologist (X-Ray Tech)",
    ],
    educationRequired: "associate's degree or certificate after high school usually needed",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling"],
    salary: "$65,140",
  },
  {
    img: img3,
    jobTitle: "Radiologists",
    jobTitleAlternatives: ["Diagnostic Radiologist", "Nuclear Medicine Physician", "Nuclear Medicine Specialist", "Radiologist"],
    educationRequired: "post-doctoral training or bachelor's degree usually needed",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling"],
    salary: "$239,200+",
  },
  {
    img: img3,
    jobTitle: "Rail Car Repairers",
    jobTitleAlternatives: ["Rail Car Mechanic", "Rail Car Repairer", "Rail Car Repairman", "Rail Car Welder"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Transportation & Storage", "Government"],
    salary: "$62,510",
  },
  {
    img: img3,
    jobTitle: "Rail Yard Engineers, Dinkey Operators, & Hostlers",
    jobTitleAlternatives: ["Engineer", "Railcar Switcher", "Railroad Engineer", "Switchman"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Transportation & Storage"],
    salary: "$56,340",
  },
  {
    img: img3,
    jobTitle: "Rail-Track Laying & Maintenance Equipment Operators",
    jobTitleAlternatives: ["Track Inspector", "Track Laborer", "Track Maintainer", "Track Repairer"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Transportation & Storage", "Government", "Construction"],
    salary: "$63,230",
  },
  {
    img: img3,
    jobTitle: "Railroad Brake, Signal, & Switch Operators & Locomotive Firers",
    jobTitleAlternatives: ["Brakeman", "Locomotive Switch Operator", "Railroad Switchman", "Trainman"],
    educationRequired: "Associate's Degree",
    jobOutlook: "Salary:",
    industry: ["Transportation & Storage"],
    salary: "$67,540",
  },
  {
    img: img3,
    jobTitle: "Railroad Conductors & Yardmasters",
    jobTitleAlternatives: ["Conductor", "Freight Conductor", "Railroad Conductor", "Yardmaster"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Transportation & Storage", "Government"],
    salary: "$68,180",
  },
  {
    img: img3,
    jobTitle: "Range Managers",
    jobTitleAlternatives: ["Natural Resource Specialist", "Range Technician", "Rangeland Management Specialist", "Resource Manager"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Government", "Service"],
    salary: "$64,460",
  },
  {
    img: img3,
    jobTitle: "Real Estate Brokers",
    jobTitleAlternatives: ["Broker", "Managing Broker", "Real Estate Broker", "Realtor"],
    educationRequired: "bachelor's degree or some college usually needed",
    jobOutlook: "Salary:",
    industry: ["Real Estate & Rentals"],
    salary: "$62,190",
  },
  {
    img: img3,
    jobTitle: "Real Estate Sales Agents",
    jobTitleAlternatives: ["Real Estate Agent", "Realtor", "Realtor Associate", "Sales Agent"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Real Estate & Rentals"],
    salary: "$49,980",
  },
  {
    img: img3,
    jobTitle: "Receptionists & Information Clerks",
    jobTitleAlternatives: ["Clerk Specialist", "Front Desk Receptionist", "Office Assistant", "Receptionist"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling", "Professional, Science, & Technical", "Service"],
    salary: "$33,960",
  },
  {
    img: img3,
    jobTitle: "Recreation & Fitness Studies Teachers, Postsecondary",
    jobTitleAlternatives: [
      "Health and Physical Education Professor (HPE Professor)",
      "Instructor",
      "Physical Education Professor (PE Professor)",
      "Professor",
    ],
    educationRequired: "Doctoral Degree",
    jobOutlook: "Salary:",
    industry: ["Education"],
    salary: "$72,650",
  },
  {
    img: img3,
    jobTitle: "Recreation Workers",
    jobTitleAlternatives: ["Activities Director", "Activity Aide", "Activity Director", "Recreation Supervisor"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Government", "Health & Counseling", "Arts & Entertainment", "Service"],
    salary: "$31,680",
  },
  {
    img: img3,
    jobTitle: "Recreational Therapists",
    jobTitleAlternatives: [
      "Certified Therapeutic Recreation Specialist (CTRS)",
      "Recreation Therapist",
      "Recreational Therapist",
      "Rehabilitation Therapist",
    ],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling", "Government"],
    salary: "$51,330",
  },
  {
    img: img3,
    jobTitle: "Recreational Vehicle Service Technicians",
    jobTitleAlternatives: [
      "Master Certified RV Technician (Master Certified Recreational Vehicle Technician)",
      "RV Repair Technician (Recreational Vehicle Repair Technician)",
      "RV Service Technician (Recreational Vehicle Service Technician)",
      "RV Technician (Recreational Vehicle Technician)",
    ],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Retail", "Service"],
    salary: "$45,030",
  },
  {
    img: img3,
    jobTitle: "Recycling & Reclamation Workers",
    jobTitleAlternatives: ["Non-Ferrous Material Handler", "Sort Line Worker", "Sorter", "Transfer Station Operator"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Transportation & Storage", "Administration & Support Services", "Manufacturing", "Wholesale/Commercial Sales"],
    salary: "$36,110",
  },
  {
    img: img3,
    jobTitle: "Recycling Coordinators",
    jobTitleAlternatives: ["Heavy Equipment Supervisor", "Route Supervisor", "Solid Waste Division Supervisor", "Waste Reduction Coordinator"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Transportation & Storage", "Retail", "Wholesale/Commercial Sales"],
    salary: "$57,860",
  },
  {
    img: img3,
    jobTitle: "Refractory Materials Repairers",
    jobTitleAlternatives: ["Cupola Repairer", "Ladle Liner", "Refractory Bricklayer", "Refractory Technician"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing", "Construction"],
    salary: "$50,550",
  },
  {
    img: img3,
    jobTitle: "Refuse & Recyclable Material Collectors",
    jobTitleAlternatives: ["Garbage Man", "Rolloff Truck Driver", "Sanitation Laborer", "Truck Driver"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Administration & Support Services", "Government"],
    salary: "$43,540",
  },
  {
    img: img3,
    jobTitle: "Registered Nurses",
    jobTitleAlternatives: [
      "Charge Nurse",
      "Emergency Department RN (Emergency Department Registered Nurse)",
      "Operating Room Registered Nurse (OR RN)",
      "Staff Nurse",
    ],
    educationRequired: "bachelor's degree or certificate after high school usually needed",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling"],
    salary: "$81,220",
  },
  {
    img: img3,
    jobTitle: "Regulatory Affairs Managers",
    jobTitleAlternatives: [
      "Global Regulatory Affairs Director (Global RA Director)",
      "Global Regulatory Affairs Manager (Global RA Manager)",
      "Regulatory Affairs Director (RA Director)",
      "Regulatory Affairs Manager (RA Manager)",
    ],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Government"],
    salary: "$128,620",
  },
  {
    img: img3,
    jobTitle: "Regulatory Affairs Specialists",
    jobTitleAlternatives: [
      "Regulatory Affairs Associate (RA Associate)",
      "Regulatory Affairs Consultant (RA Consultant)",
      "Regulatory Affairs Specialist (RA Specialist)",
      "Regulatory Engineer",
    ],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Government", "Finance & Insurance"],
    salary: "$71,690",
  },
  {
    img: img3,
    jobTitle: "Rehabilitation Counselors",
    jobTitleAlternatives: ["Employment Specialist", "Job Coach", "Rehabilitation Counselor", "Vocational Rehabilitation Counselor (VRC)"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling", "Government"],
    salary: "$39,990",
  },
  {
    img: img3,
    jobTitle: "Reinforcing Iron & Rebar Workers",
    jobTitleAlternatives: ["Iron Installer", "Iron Worker", "Ironworker", "Rodbuster"],
    educationRequired: "No High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Construction"],
    salary: "$51,070",
  },
  {
    img: img3,
    jobTitle: "Remote Sensing Scientists & Technologists",
    jobTitleAlternatives: ["Geospatial Intelligence Analyst", "Remote Sensing Analyst", "Scientist", "Sensor Specialist"],
    educationRequired: "Master's Degree",
    jobOutlook: "Salary:",
    industry: ["Government", "Education", "Professional, Science, & Technical", "Farming, Forestry, Fishing, & Hunting"],
    salary: "$107,970",
  },
  {
    img: img3,
    jobTitle: "Remote Sensing Technicians",
    jobTitleAlternatives: ["Digital Cartographic Technician", "Geospatial Extractor", "Meteorologist Liaison", "Research Associate"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Education", "Professional, Science, & Technical", "Administration & Support Services", "Government"],
    salary: "$50,290",
  },
  {
    img: img3,
    jobTitle: "Reservation & Transportation Ticket Agents & Travel Clerks",
    jobTitleAlternatives: ["Customer Service Agent", "Reservation Agent", "Reservations Agent", "Ticket Agent"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Transportation & Storage", "Administration & Support Services"],
    salary: "$39,160",
  },
  {
    img: img3,
    jobTitle: "Residential Advisors",
    jobTitleAlternatives: ["Residence Hall Director", "Residence Life Director", "Resident Assistant", "Resident Director"],
    educationRequired: "some college or bachelor's degree usually needed",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling", "Education"],
    salary: "$35,720",
  },
  {
    img: img3,
    jobTitle: "Respiratory Therapists",
    jobTitleAlternatives: [
      "Certified Respiratory Therapist (CRT)",
      "Registered Respiratory Therapist (RRT)",
      "Respiratory Care Practitioner (RCP)",
      "Respiratory Therapist (RT)",
    ],
    educationRequired: "Associate's Degree",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling"],
    salary: "$70,540",
  },
  {
    img: img3,
    jobTitle: "Retail Loss Prevention Specialists",
    jobTitleAlternatives: ["Loss Prevention Agent", "Loss Prevention Associate (LPA)", "Loss Prevention Detective", "Loss Prevention Officer"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Education", "Government", "Administration & Support Services"],
    salary: "$36,460",
  },
  {
    img: img3,
    jobTitle: "Retail Salespersons",
    jobTitleAlternatives: ["Sales Associate", "Sales Clerk", "Sales Consultant", "Sales Person"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Retail"],
    salary: "$30,600",
  },
  {
    img: img3,
    jobTitle: "Riggers",
    jobTitleAlternatives: ["Machinery Erector", "Machinery Mover", "Motor Rigger", "Rigger"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Construction", "Manufacturing", "Government"],
    salary: "$54,680",
  },
  {
    img: img3,
    jobTitle: "Robotics Engineers",
    jobTitleAlternatives: ["Automation Engineer", "Autonomous Vehicle Design Engineer", "Design Engineer", "Factory Automations Engineer"],
    educationRequired: "bachelor's degree or associate's degree usually needed",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Government", "Manufacturing"],
    salary: "$104,600",
  },
  {
    img: img3,
    jobTitle: "Robotics Technicians",
    jobTitleAlternatives: [
      "Automation Technician",
      "Electrical and Instrumentation Technician (E and I Technician)",
      "Instrument Specialist",
      "Process Control Technician",
    ],
    educationRequired: "associate's degree or certificate after high school usually needed",
    jobOutlook: "Salary:",
    industry: ["Manufacturing", "Professional, Science, & Technical"],
    salary: "$60,570",
  },
  {
    img: img3,
    jobTitle: "Rock Splitters, Quarry",
    jobTitleAlternatives: ["Driller", "Quarry Worker", "Rock Splitter", "Stone Splitter"],
    educationRequired: "No High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Mining, Oil, & Gas", "Manufacturing"],
    salary: "$46,010",
  },
  {
    img: img3,
    jobTitle: "Rolling Machine Setters, Operators, & Tenders, Metal & Plastic",
    jobTitleAlternatives: ["Mill Operator", "Rolling Mill Operator", "Roughing Mill Operator", "Tube Mill Operator"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing"],
    salary: "$46,310",
  },
  {
    img: img3,
    jobTitle: "Roof Bolters, Mining",
    jobTitleAlternatives: ["Bolter", "Roof Bolter", "Roof Bolter Operator", "Underground Roof Bolter"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Mining, Oil, & Gas"],
    salary: "$60,210",
  },
  {
    img: img3,
    jobTitle: "Roofers",
    jobTitleAlternatives: ["Roof Mechanic", "Roof Service Technician", "Roofer", "Roofing Technician"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Construction"],
    salary: "$47,920",
  },
  {
    img: img3,
    jobTitle: "Rotary Drill Operators, Oil & Gas",
    jobTitleAlternatives: ["Daylight Driller", "Drill Operator", "Driller", "Tool Pusher"],
    educationRequired: "No High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Mining, Oil, & Gas"],
    salary: "$55,260",
  },
  {
    img: img3,
    jobTitle: "Roustabouts, Oil & Gas",
    jobTitleAlternatives: ["Floor Hand", "Roustabout", "Roustabout Hand", "Roustabout Pusher"],
    educationRequired: "No High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Mining, Oil, & Gas"],
    salary: "$43,590",
  },
  {
    img: img3,
    jobTitle: "Sailors & Marine Oilers",
    jobTitleAlternatives: ["Able Bodied Seaman (AB Seaman)", "Able Seaman", "Deck Hand", "Deckhand"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Transportation & Storage", "Government"],
    salary: "$47,490",
  },
  {
    img: img3,
    jobTitle: "Sales Engineers",
    jobTitleAlternatives: ["Business Development Engineer", "Product Sales Engineer", "Sales Engineer", "Technical Marketing Engineer"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Wholesale/Commercial Sales", "Manufacturing", "Media & Communication"],
    salary: "$108,530",
  },
  {
    img: img3,
    jobTitle: "Sales Managers",
    jobTitleAlternatives: ["District Sales Manager", "Sales Director", "Sales Manager", "Sales Vice President (Sales VP)"],
    educationRequired: "bachelor's degree or associate's degree usually needed",
    jobOutlook: "Salary:",
    industry: ["Wholesale/Commercial Sales", "Retail", "Professional, Science, & Technical", "Finance & Insurance", "Manufacturing"],
    salary: "$130,600",
  },
  {
    img: img3,
    jobTitle: "Sales Representatives of Services",
    jobTitleAlternatives: [[]],
    educationRequired: "Associate's Degree",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Media & Communication", "Administration & Support Services", "Retail"],
    salary: "$62,400",
  },
  {
    img: img3,
    jobTitle: "Sales Representatives, Wholesale & Manufacturing",
    jobTitleAlternatives: ["Outside Sales Representative", "Sales Consultant", "Sales Representative (Sales Rep)", "Salesman"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Wholesale/Commercial Sales", "Manufacturing"],
    salary: "$63,230",
  },
  {
    img: img3,
    jobTitle: "Sales Representatives, Wholesale & Manufacturing, Technical & Scientific Products",
    jobTitleAlternatives: ["Inside Sales Representative", "Marketing Representative", "Sales Representative"],
    educationRequired: "bachelor's degree or some college usually needed",
    jobOutlook: "Salary:",
    industry: ["Wholesale/Commercial Sales", "Manufacturing", "Professional, Science, & Technical", "Media & Communication"],
    salary: "$97,710",
  },
  {
    img: img3,
    jobTitle: "Sawing Machine Setters, Operators, & Tenders, Wood",
    jobTitleAlternatives: ["Resaw Operator", "Rip Saw Operator", "Saw Operator", "Sawyer"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing"],
    salary: "$36,270",
  },
  {
    img: img3,
    jobTitle: "School Bus Monitors",
    jobTitleAlternatives: [[]],
    educationRequired: "Associate's Degree",
    jobOutlook: "Salary:",
    industry: ["Education", "Transportation & Storage", "Government"],
    salary: "$29,880",
  },
  {
    img: img3,
    jobTitle: "School Psychologists",
    jobTitleAlternatives: ["Bilingual School Psychologist", "Consulting Psychologist", "Psychologist", "School Psychologist"],
    educationRequired: "certificate after master's or master's degree usually needed",
    jobOutlook: "Salary:",
    industry: ["Education"],
    salary: "$81,500",
  },
  {
    img: img3,
    jobTitle: "Search Marketing Strategists",
    jobTitleAlternatives: [
      "Digital Media Planner",
      "Online Marketing Consultant",
      "Paid Search Strategist",
      "SEO Strategist (Search Engine Optimization Strategist)",
    ],
    educationRequired: "bachelor's degree or associate's degree usually needed",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Media & Communication", "Finance & Insurance"],
    salary: "$68,230",
  },
  {
    img: img3,
    jobTitle: "Secondary School Teachers",
    jobTitleAlternatives: ["English Teacher", "Mathematics Instructor (Math Instructor)", "Social Studies Teacher", "Teacher"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Education"],
    salary: "$62,360",
  },
  {
    img: img3,
    jobTitle: "Secretaries & Administrative Assistants",
    jobTitleAlternatives: [
      "Administrative Assistant (Admin Assistant)",
      "Administrative Secretary (Admin Secretary)",
      "Office Assistant",
      "Secretary",
    ],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Education", "Health & Counseling", "Service"],
    salary: "$41,000",
  },
  {
    img: img3,
    jobTitle: "Securities, Commodities, & Financial Services Sales Agents",
    jobTitleAlternatives: ["Broker", "Equity Trader", "Financial Advisor", "Financial Consultant"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Finance & Insurance"],
    salary: "$67,480",
  },
  {
    img: img3,
    jobTitle: "Security & Fire Alarm Systems Installers",
    jobTitleAlternatives: ["Alarm Technician", "Fire Alarm Technician", "Installation Technician", "Service Technician"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Administration & Support Services", "Construction"],
    salary: "$50,130",
  },
  {
    img: img3,
    jobTitle: "Security Guards",
    jobTitleAlternatives: ["Safety and Security Officer", "Security Agent", "Security Guard", "Security Officer"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Administration & Support Services"],
    salary: "$34,750",
  },
  {
    img: img3,
    jobTitle: "Security Management Specialists",
    jobTitleAlternatives: ["Physical Security Engineer", "Physical Security Specialist", "Security Analyst", "Security Consultant"],
    educationRequired: "Master's Degree",
    jobOutlook: "Salary:",
    industry: ["Government", "Professional, Science, & Technical", "Education"],
    salary: "$75,990",
  },
  {
    img: img3,
    jobTitle: "Security Managers",
    jobTitleAlternatives: ["Corporate Physical Security Supervisor", "Corporate Security Manager", "Security Director", "Security Manager"],
    educationRequired: "Master's Degree",
    jobOutlook: "Salary:",
    industry: ["Education", "Government", "Health & Counseling", "Manufacturing"],
    salary: "$99,030",
  },
  {
    img: img3,
    jobTitle: "Segmental Pavers",
    jobTitleAlternatives: ["Cutter", "Paver", "Paver Installer", "Paving Stone Installer"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Construction"],
    salary: "$44,890",
  },
  {
    img: img3,
    jobTitle: "Self-Enrichment Teachers",
    jobTitleAlternatives: ["Dance Instructor", "Instructor", "Martial Arts Instructor", "Teacher"],
    educationRequired: "master's degree or certificate after high school usually needed",
    jobOutlook: "Salary:",
    industry: ["Education", "Service"],
    salary: "$44,110",
  },
  {
    img: img3,
    jobTitle: "Semiconductor Processing Technicians",
    jobTitleAlternatives: ["Diffusion Operator", "Engineering Technician", "Process Technician", "Wafer Fabrication Operator"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing"],
    salary: "$44,690",
  },
  {
    img: img3,
    jobTitle: "Separating, Filtering, Clarifying, Precipitating, & Still Machine Setters, Operators, & Tenders",
    jobTitleAlternatives: ["Brewer", "Cellar Worker", "Machine Tender", "Pulper Operator"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing"],
    salary: "$46,250",
  },
  {
    img: img3,
    jobTitle: "Septic Tank Servicers & Sewer Pipe Cleaners",
    jobTitleAlternatives: ["Drain Cleaner", "Septic Pump Truck Driver", "Septic Tank Service Technician", "Service Technician"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Administration & Support Services", "Government", "Construction"],
    salary: "$45,610",
  },
  {
    img: img3,
    jobTitle: "Service Unit Operators, Oil & Gas",
    jobTitleAlternatives: ["Pulling Unit Operator", "Rig Operator", "Service Operator", "Service Rig Operator"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Mining, Oil, & Gas"],
    salary: "$50,140",
  },
  {
    img: img3,
    jobTitle: "Set & Exhibit Designers",
    jobTitleAlternatives: ["Display Coordinator", "Exhibit Designer", "Scenic Designer", "Set Designer"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Media & Communication"],
    salary: "$59,990",
  },
  {
    img: img3,
    jobTitle: "Sewers, Hand",
    jobTitleAlternatives: ["Custom Clothier", "Designer", "Dressmaker", "Seamstress"],
    educationRequired: "no high school diploma/GED or certificate after high school usually needed",
    jobOutlook: "Salary:",
    industry: ["Manufacturing", "Retail"],
    salary: "$31,530",
  },
  {
    img: img3,
    jobTitle: "Sewing Machine Operators",
    jobTitleAlternatives: ["Sample Maker", "Seamstress", "Sewer", "Sewing Machine Operator"],
    educationRequired: "No High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing"],
    salary: "$31,740",
  },
  {
    img: img3,
    jobTitle: "Shampooers",
    jobTitleAlternatives: ["Shampoo Assistant", "Shampoo Technician", "Shampooer", "Stylist Assistant"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Service"],
    salary: "$27,860",
  },
  {
    img: img3,
    jobTitle: "Sheet Metal Workers",
    jobTitleAlternatives: [
      "HVAC Sheet Metal Installer (Heating",
      "Ventilation",
      "and Air Conditioning Sheet Metal Installer); Sheet Metal Fabricator; Sheet Metal Mechanic; Sheet Metal Worker",
    ],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Construction", "Manufacturing"],
    salary: "$55,350",
  },
  {
    img: img3,
    jobTitle: "Ship Engineers",
    jobTitleAlternatives: ["Engineer", "Ferry Engineer", "Port Engineer", "Tug Boat Engineer"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Transportation & Storage", "Government"],
    salary: "$87,300",
  },
  {
    img: img3,
    jobTitle: "Shipping, Receiving, & Inventory Clerks",
    jobTitleAlternatives: ["Receiver", "Receiving Clerk", "Shipper", "Shipping Clerk"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing", "Retail", "Wholesale/Commercial Sales", "Transportation & Storage"],
    salary: "$37,760",
  },
  {
    img: img3,
    jobTitle: "Shoe & Leather Workers & Repairers",
    jobTitleAlternatives: ["Boot Maker", "Cutter", "Shoe Maker", "Shoe Repairer"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing", "Retail"],
    salary: "$32,460",
  },
  {
    img: img3,
    jobTitle: "Shoe Machine Operators & Tenders",
    jobTitleAlternatives: ["Boot Maker", "Cutter", "Side Laster", "Stitcher"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing"],
    salary: "$33,060",
  },
  {
    img: img3,
    jobTitle: "Shuttle Drivers & Chauffeurs",
    jobTitleAlternatives: ["Chauffeur", "Driver", "Shuttle Bus Driver", "Shuttle Driver"],
    educationRequired: "Associate's Degree",
    jobOutlook: "Salary:",
    industry: ["Transportation & Storage", "Health & Counseling"],
    salary: "$32,800",
  },
  {
    img: img3,
    jobTitle: "Signal & Track Switch Repairers",
    jobTitleAlternatives: ["Signal Inspector", "Signal Maintainer", "Signalman", "Train Control Electronic Technician"],
    educationRequired: "No High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Transportation & Storage", "Government"],
    salary: "$81,300",
  },
  {
    img: img3,
    jobTitle: "Skincare Specialists",
    jobTitleAlternatives: ["Aesthetician", "Esthetician", "Medical Esthetician", "Skin Care Specialist"],
    educationRequired: "certificate after high school usually needed",
    jobOutlook: "Salary:",
    industry: ["Service", "Health & Counseling"],
    salary: "$38,060",
  },
  {
    img: img3,
    jobTitle: "Slaughterers & Meat Packers",
    jobTitleAlternatives: ["Meat Packer", "Meat Processor", "Meat Trimmer", "Meat Wrapper"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing"],
    salary: "$35,240",
  },
  {
    img: img3,
    jobTitle: "Social & Community Service Managers",
    jobTitleAlternatives: [
      "Child Welfare Services Director",
      "Social Services Director",
      "Transitional Care Director",
      "Vocational Rehabilitation Administrator",
    ],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling", "Government", "Service"],
    salary: "$74,240",
  },
  {
    img: img3,
    jobTitle: "Social & Human Service Assistants",
    jobTitleAlternatives: ["Advocate", "Clinical Assistant", "Social Work Associate", "Social Worker Assistant"],
    educationRequired: "bachelor's degree or associate's degree usually needed",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling", "Government"],
    salary: "$38,520",
  },
  {
    img: img3,
    jobTitle: "Social Science Research Assistants",
    jobTitleAlternatives: ["Graduate Research Assistant", "Research Assistant", "Research Associate", "Social Research Assistant"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Education", "Professional, Science, & Technical"],
    salary: "$50,470",
  },
  {
    img: img3,
    jobTitle: "Social Work Teachers, Postsecondary",
    jobTitleAlternatives: ["Assistant Professor", "Professor", "Social Work Associate Professor", "Social Work Professor"],
    educationRequired: "Doctoral Degree",
    jobOutlook: "Salary:",
    industry: ["Education"],
    salary: "$66,510",
  },
  {
    img: img3,
    jobTitle: "Sociologists",
    jobTitleAlternatives: ["Research Associate", "Research Scientist", "Research Specialist", "Social Scientist"],
    educationRequired: "Doctoral Degree",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Government", "Education"],
    salary: "$98,590",
  },
  {
    img: img3,
    jobTitle: "Sociology Teachers, Postsecondary",
    jobTitleAlternatives: ["Assistant Professor", "Professor", "Sociology Instructor", "Sociology Professor"],
    educationRequired: "Doctoral Degree",
    jobOutlook: "Salary:",
    industry: ["Education"],
    salary: "$78,970",
  },
  {
    img: img3,
    jobTitle: "Software Developers",
    jobTitleAlternatives: ["Application Developer", "Application Integration Engineer", "Software Architect", "Software Developer"],
    educationRequired: "Master's Degree",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Media & Communication", "Finance & Insurance"],
    salary: "$127,260",
  },
  {
    img: img3,
    jobTitle: "Software Quality Assurance Analysts & Testers",
    jobTitleAlternatives: [
      "Quality Assurance Analyst (QA Analyst)",
      "Software Quality Assurance Engineer (SQA Engineer)",
      "Software Quality Engineer",
      "Software Test Engineer",
    ],
    educationRequired: "bachelor's degree or associate's degree usually needed",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Media & Communication", "Finance & Insurance"],
    salary: "$99,620",
  },
  {
    img: img3,
    jobTitle: "Soil & Plant Scientists",
    jobTitleAlternatives: ["Agronomist", "Research Scientist", "Research Soil Scientist", "Scientist"],
    educationRequired: "Master's Degree",
    jobOutlook: "Salary:",
    industry: [
      "Professional, Science, & Technical",
      "Education",
      "Government",
      "Farming, Forestry, Fishing, & Hunting",
      "Wholesale/Commercial Sales",
    ],
    salary: "$65,730",
  },
  {
    img: img3,
    jobTitle: "Solar Energy Installation Managers",
    jobTitleAlternatives: [
      "Installation Manager",
      "Solar Energy Installation Manager",
      "Solar Installation Manager",
      "Solar Installation Supervisor",
    ],
    educationRequired: "certificate after high school or some college usually needed",
    jobOutlook: "Salary:",
    industry: ["Construction"],
    salary: "$74,080",
  },
  {
    img: img3,
    jobTitle: "Solar Energy Systems Engineers",
    jobTitleAlternatives: ["Consulting Engineer", "Distributed Energy Systems Consultant", "Research Engineer", "Solar Engineer"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Government", "Manufacturing"],
    salary: "$104,600",
  },
  {
    img: img3,
    jobTitle: "Solar Photovoltaic Installers",
    jobTitleAlternatives: [
      "Installer",
      "Photovoltaic Installer (PV Installer)",
      "Solar Photovoltaic Installer (Solar PV Installer)",
      "Solar Technician",
    ],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Construction"],
    salary: "$45,230",
  },
  {
    img: img3,
    jobTitle: "Solar Sales Representatives & Assessors",
    jobTitleAlternatives: ["Energy Consultant", "Sales Associate", "Sales Representative", "Solar Sales Representative"],
    educationRequired: "some college or bachelor's degree usually needed",
    jobOutlook: "Salary:",
    industry: ["Wholesale/Commercial Sales", "Manufacturing", "Professional, Science, & Technical", "Media & Communication"],
    salary: "$97,710",
  },
  {
    img: img3,
    jobTitle: "Solar Thermal Installers & Technicians",
    jobTitleAlternatives: ["Installer", "Solar Installer", "Solar Maintenance Technician", "Solar Technician"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Construction"],
    salary: "$60,090",
  },
  {
    img: img3,
    jobTitle: "Sound Engineering Technicians",
    jobTitleAlternatives: ["Audio Engineer", "Mixer", "Recording Engineer", "Sound Engineer"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Media & Communication"],
    salary: "$60,670",
  },
  {
    img: img3,
    jobTitle: "Spa Managers",
    jobTitleAlternatives: ["Salon Manager", "Spa and Guest Experience Director", "Spa Director", "Spa Manager"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Service"],
    salary: "$56,090",
  },
  {
    img: img3,
    jobTitle: "Special Education Teachers, Elementary School",
    jobTitleAlternatives: [
      "Severe Emotional Disorders Elementary Teacher (SED Elementary Teacher)",
      "Special Education Inclusion Teacher",
      "Special Education Resource Teacher",
      "Special Education Teacher",
    ],
    educationRequired: "Master's Degree",
    jobOutlook: "Salary:",
    industry: ["Education"],
    salary: "$62,390",
  },
  {
    img: img3,
    jobTitle: "Special Education Teachers, Kindergarten",
    jobTitleAlternatives: [
      "Emotional Disabilities Teacher",
      "Learning Support Teacher",
      "Resource Program Teacher",
      "Special Education Inclusion Teacher",
    ],
    educationRequired: "Master's Degree",
    jobOutlook: "Salary:",
    industry: ["Education"],
    salary: "$62,390",
  },
  {
    img: img3,
    jobTitle: "Special Education Teachers, Middle School",
    jobTitleAlternatives: ["Intervention Specialist", "Middle School Special Education Teacher", "Special Education Resource Teacher", "Teacher"],
    educationRequired: "Master's Degree",
    jobOutlook: "Salary:",
    industry: ["Education"],
    salary: "$62,990",
  },
  {
    img: img3,
    jobTitle: "Special Education Teachers, Preschool",
    jobTitleAlternatives: ["Early Childhood Special Education Teacher (ECSE Teacher)", "Resource Teacher", "Special Education Teacher", "Teacher"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Education", "Health & Counseling"],
    salary: "$62,240",
  },
  {
    img: img3,
    jobTitle: "Special Education Teachers, Secondary School",
    jobTitleAlternatives: ["High School Special Education Teacher", "Special Education Resource Teacher", "Special Education Teacher", "Teacher"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Education"],
    salary: "$63,560",
  },
  {
    img: img3,
    jobTitle: "Special Effects Artists & Animators",
    jobTitleAlternatives: ["Animator", "Artist", "Graphic Artist", "Illustrator"],
    educationRequired: "bachelor's degree or some college usually needed",
    jobOutlook: "Salary:",
    industry: ["Media & Communication", "Professional, Science, & Technical"],
    salary: "$98,950",
  },
  {
    img: img3,
    jobTitle: "Speech-Language Pathologists",
    jobTitleAlternatives: [
      "Bilingual Speech-Language Pathologist (Bilingual SLP)",
      "Speech and Language Specialist",
      "Speech Pathologist",
      "Speech-Language Pathologist (SLP)",
    ],
    educationRequired: "Master's Degree",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling", "Education"],
    salary: "$84,140",
  },
  {
    img: img3,
    jobTitle: "Speech-Language Pathology Assistants",
    jobTitleAlternatives: [
      "Communication Assistant",
      "Speech Pathologist Assistant",
      "Speech-Language Pathologist Assistant (SLPA)",
      "Speech-Language Pathology Assistant (SLPA)",
    ],
    educationRequired: "Associate's Degree",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling", "Government", "Education"],
    salary: "$40,420",
  },
  {
    img: img3,
    jobTitle: "Sports Medicine Physicians",
    jobTitleAlternatives: ["Athletic Team Physician", "Physician", "Sports Medicine Physician", "Team Physician"],
    educationRequired: "Doctoral Degree",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling", "Government"],
    salary: "$223,410",
  },
  {
    img: img3,
    jobTitle: "Stationary Engineers & Boiler Operators",
    jobTitleAlternatives: ["Boiler Operator", "Boiler Technician", "Building Engineer", "Stationary Engineer"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Government", "Education", "Health & Counseling", "Manufacturing"],
    salary: "$67,120",
  },
  {
    img: img3,
    jobTitle: "Statistical Assistants",
    jobTitleAlternatives: ["Actuarial Analyst", "Actuarial Assistant", "Actuarial Technician", "Research Assistant"],
    educationRequired: "bachelor's degree or some college usually needed",
    jobOutlook: "Salary:",
    industry: ["Education", "Finance & Insurance", "Professional, Science, & Technical", "Government"],
    salary: "$48,880",
  },
  {
    img: img3,
    jobTitle: "Statisticians",
    jobTitleAlternatives: ["Mathematical Statistician", "Statistical Analyst", "Statistical Reporting Analyst", "Statistician"],
    educationRequired: "Master's Degree",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Government", "Education"],
    salary: "$98,920",
  },
  {
    img: img3,
    jobTitle: "Stockers & Order Fillers",
    jobTitleAlternatives: ["Checker Stocker", "Order Filler", "Stock Clerk", "Stocker"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Retail", "Transportation & Storage", "Wholesale/Commercial Sales"],
    salary: "$34,220",
  },
  {
    img: img3,
    jobTitle: "Stone Cutters & Carvers, Manufacturing",
    jobTitleAlternatives: ["Carver", "Sandblaster", "Stone Carver", "Stone Cutter"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing"],
    salary: "$39,590",
  },
  {
    img: img3,
    jobTitle: "Stonemasons",
    jobTitleAlternatives: ["Mason", "Stone Derrickman", "Stone Mason", "Stone Setter"],
    educationRequired: "No High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Construction"],
    salary: "$50,210",
  },
  {
    img: img3,
    jobTitle: "Structural Iron & Steel Workers",
    jobTitleAlternatives: ["Fitter", "Iron Worker", "Ironworker", "Steel Worker"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Construction"],
    salary: "$60,500",
  },
  {
    img: img3,
    jobTitle: "Structural Metal Fabricators & Fitters",
    jobTitleAlternatives: ["Fabricator", "Fitter", "Layout Man", "Ship Fitter"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing"],
    salary: "$47,200",
  },
  {
    img: img3,
    jobTitle: "Substance Abuse & Behavioral Disorder Counselors",
    jobTitleAlternatives: [
      "Addictions Counselor",
      "Chemical Dependency Counselor (CD Counselor)",
      "Counselor",
      "Substance Abuse Counselor (SA Counselor)",
    ],
    educationRequired: "Master's Degree",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling"],
    salary: "$49,710",
  },
  {
    img: img3,
    jobTitle: "Substitute Teachers, Short-Term",
    jobTitleAlternatives: [[]],
    educationRequired: "Master's Degree",
    jobOutlook: "Salary:",
    industry: ["Education"],
    salary: "$35,250",
  },
  {
    img: img3,
    jobTitle: "Subway & Streetcar Operators",
    jobTitleAlternatives: ["Light Rail Operator", "Rail Operator", "Rapid Transit Operator (RTO)", "Train Operator"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Government"],
    salary: "$88,260",
  },
  {
    img: img3,
    jobTitle: "Supply Chain Managers",
    jobTitleAlternatives: ["Global Supply Chain Director", "Material Requirements Planning Manager", "Supply Chain Director", "Supply Chain Manager"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Transportation & Storage", "Wholesale/Commercial Sales", "Manufacturing", "Government"],
    salary: "$98,560",
  },
  {
    img: img3,
    jobTitle: "Surgical Assistants",
    jobTitleAlternatives: [
      "Certified Surgical First Assistant (CSFA)",
      "Registered Nurse First Assistant (RNFA)",
      "Surgical First Assistant",
      "Surgical Technician (Surgical Tech)",
    ],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling"],
    salary: "$57,290",
  },
  {
    img: img3,
    jobTitle: "Surgical Technologists",
    jobTitleAlternatives: [
      "Certified Surgical Technologist (CST)",
      "Operating Room Technician (OR Tech)",
      "Surgical Technician",
      "Surgical Technologist (Surgical Tech)",
    ],
    educationRequired: "associate's degree or certificate after high school usually needed",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling"],
    salary: "$55,960",
  },
  {
    img: img3,
    jobTitle: "Survey Researchers",
    jobTitleAlternatives: ["Data Analyst", "Field Interviewer", "Research Associate", "Research Fellow"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Education"],
    salary: "$60,410",
  },
  {
    img: img3,
    jobTitle: "Surveying & Mapping Technicians",
    jobTitleAlternatives: ["Engineering Technician", "Mapping Technician", "Photogrammetric Technician", "Survey Technician"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Government"],
    salary: "$47,180",
  },
  {
    img: img3,
    jobTitle: "Surveyors",
    jobTitleAlternatives: ["County Surveyor", "Land Surveyor", "Registered Land Surveyor", "Surveyor"],
    educationRequired: "bachelor's degree or associate's degree usually needed",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical"],
    salary: "$63,080",
  },
  {
    img: img3,
    jobTitle: "Sustainability Specialists",
    jobTitleAlternatives: ["Sustainability Consultant", "Sustainability Coordinator", "Sustainability Specialist", "Sustainable Design Coordinator"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Government", "Professional, Science, & Technical", "Education"],
    salary: "$75,990",
  },
  {
    img: img3,
    jobTitle: "Switchboard Operators, Including Answering Service",
    jobTitleAlternatives: [
      "CBX Operator (Computerized Branch Exchange Operator)",
      "Communications Specialist",
      "PBX Operator (Private Branch Exchange Operator)",
      "Switchboard Operator (SB Operator)",
    ],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling", "Administration & Support Services"],
    salary: "$34,670",
  },
  {
    img: img3,
    jobTitle: "Tailors, Dressmakers, & Custom Sewers",
    jobTitleAlternatives: ["Alterations Sewer", "Dressmaker", "Seamstress", "Tailor"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Retail", "Service"],
    salary: "$34,280",
  },
  {
    img: img3,
    jobTitle: "Talent Directors",
    jobTitleAlternatives: ["Artistic Director", "Casting Agent", "Casting Coordinator", "Casting Director"],
    educationRequired: "bachelor's degree or associate's degree usually needed",
    jobOutlook: "Salary:",
    industry: ["Media & Communication", "Arts & Entertainment"],
    salary: "$85,320",
  },
  {
    img: img3,
    jobTitle: "Tank Car, Truck, & Ship Loaders",
    jobTitleAlternatives: ["Loader", "Loader Operator", "Tankerman", "Truck Loader"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Transportation & Storage", "Manufacturing", "Wholesale/Commercial Sales"],
    salary: "$53,930",
  },
  {
    img: img3,
    jobTitle: "Tapers",
    jobTitleAlternatives: ["Drywall Finisher", "Drywall Taper", "Finisher", "Taper"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Construction"],
    salary: "$62,360",
  },
  {
    img: img3,
    jobTitle: "Tax Examiners & Collectors, & Revenue Agents",
    jobTitleAlternatives: ["Revenue Agent", "Tax Collector", "Tax Compliance Officer", "Tax Examiner"],
    educationRequired: "bachelor's degree or associate's degree usually needed",
    jobOutlook: "Salary:",
    industry: ["Government"],
    salary: "$57,950",
  },
  {
    img: img3,
    jobTitle: "Tax Preparers",
    jobTitleAlternatives: ["Enrolled Agent", "Tax Advisor", "Tax Consultant", "Tax Preparer"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical"],
    salary: "$48,250",
  },
  {
    img: img3,
    jobTitle: "Taxi Drivers",
    jobTitleAlternatives: ["Cab Driver", "Taxi Cab Driver", "Taxi Driver"],
    educationRequired: "Associate's Degree",
    jobOutlook: "Salary:",
    industry: [],
    salary: "$30,670",
  },
  {
    img: img3,
    jobTitle: "Teaching Assistants, Postsecondary",
    jobTitleAlternatives: ["Graduate Assistant", "Graduate Teaching Assistant (GTA)", "Research Assistant (RA)", "Teaching Assistant (TA)"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Education"],
    salary: "$38,050",
  },
  {
    img: img3,
    jobTitle: "Teaching Assistants, Preschool, Elementary, Middle, & Secondary School",
    jobTitleAlternatives: ["Instructional Assistant", "Paraeducator", "Paraprofessional", "Teacher Assistant"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Education", "Health & Counseling"],
    salary: "$30,920",
  },
  {
    img: img3,
    jobTitle: "Teaching Assistants, Special Education",
    jobTitleAlternatives: ["Special Education Aide", "Special Education Paraprofessional", "Special Education Teacher Assistant"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Education", "Health & Counseling"],
    salary: "$30,920",
  },
  {
    img: img3,
    jobTitle: "Team Assemblers",
    jobTitleAlternatives: ["Assembler", "Assembly Line Machine Operator", "Assembly Line Worker", "Assembly Technician"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing", "Administration & Support Services"],
    salary: "$37,280",
  },
  {
    img: img3,
    jobTitle: "Technical Writers",
    jobTitleAlternatives: ["Documentation Specialist", "Information Developer", "Technical Communicator", "Technical Writer"],
    educationRequired: "Associate's Degree",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Manufacturing", "Media & Communication"],
    salary: "$79,960",
  },
  {
    img: img3,
    jobTitle: "Telecommunications Engineering Specialists",
    jobTitleAlternatives: ["Communications Engineer", "Engineer", "Infrastructure Engineer", "Telecommunications Consultant (Telecom Consultant)"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Media & Communication"],
    salary: "$126,900",
  },
  {
    img: img3,
    jobTitle: "Telecommunications Equipment Installers & Repairers",
    jobTitleAlternatives: ["Central Office Technician", "Install and Repair Technician", "Service Technician", "Telecommunications Technician"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Media & Communication", "Construction"],
    salary: "$59,960",
  },
  {
    img: img3,
    jobTitle: "Telecommunications Line Installers & Repairers",
    jobTitleAlternatives: ["Combination Technician", "Installation and Repair Technician (I and R Technician)", "Lineman", "Service Technician"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Media & Communication", "Construction"],
    salary: "$60,580",
  },
  {
    img: img3,
    jobTitle: "Telemarketers",
    jobTitleAlternatives: [
      "Telemarketer",
      "Telephone Sales Representative (TSR)",
      "Telephone Service Representative (TSR)",
      "Telesales Representative",
    ],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Administration & Support Services"],
    salary: "$31,030",
  },
  {
    img: img3,
    jobTitle: "Telephone Operators",
    jobTitleAlternatives: ["Directory Assistance Operator", "Information Specialist", "Long Distance Operator (LD Operator)", "Telephone Operator"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling", "Media & Communication", "Hotel & Food"],
    salary: "$38,330",
  },
  {
    img: img3,
    jobTitle: "Tellers",
    jobTitleAlternatives: ["Bank Teller", "Financial Services Representative (FSR)", "Member Services Representative", "Teller"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Finance & Insurance"],
    salary: "$36,380",
  },
  {
    img: img3,
    jobTitle: "Terrazzo Workers & Finishers",
    jobTitleAlternatives: ["Terrazzo Finisher", "Terrazzo Installer", "Terrazzo Tile Setter", "Terrazzo Worker"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Construction"],
    salary: "$52,330",
  },
  {
    img: img3,
    jobTitle: "Textile Bleaching & Dyeing Machine Operators & Tenders",
    jobTitleAlternatives: ["Drug Room Operator", "Dye Machine Operator", "Dye Operator", "Machine Operator"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing"],
    salary: "$32,680",
  },
  {
    img: img3,
    jobTitle: "Textile Cutting Machine Setters, Operators, & Tenders",
    jobTitleAlternatives: ["Cutter", "Cutter Operator", "Fabric Cutter", "Spreader"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing"],
    salary: "$34,420",
  },
  {
    img: img3,
    jobTitle: "Textile Knitting & Weaving Machine Setters, Operators, & Tenders",
    jobTitleAlternatives: ["Knitter", "Knitting Machine Operator", "Tufting Machine Operator", "Weaver"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing"],
    salary: "$35,750",
  },
  {
    img: img3,
    jobTitle: "Textile Winding, Twisting, & Drawing Out Machine Setters, Operators, & Tenders",
    jobTitleAlternatives: ["Spinner", "Twister Operator", "Winder Operator", "Winder Tender"],
    educationRequired: "No High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing"],
    salary: "$33,720",
  },
  {
    img: img3,
    jobTitle: "Tile & Stone Setters",
    jobTitleAlternatives: ["Tile and Marble Installer", "Tile Installer", "Tile Mechanic", "Tile Setter"],
    educationRequired: "No High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Construction", "Manufacturing"],
    salary: "$48,340",
  },
  {
    img: img3,
    jobTitle: "Timing Device Assemblers & Adjusters",
    jobTitleAlternatives: ["Calibration Specialist", "Clockmaker", "Watch Technician", "Watchmaker"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing"],
    salary: "$42,290",
  },
  {
    img: img3,
    jobTitle: "Tire Builders",
    jobTitleAlternatives: ["Retread Technician", "Tire Builder", "Tire Retreader", "Tire Technician"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing"],
    salary: "$51,650",
  },
  {
    img: img3,
    jobTitle: "Tire Repairers & Changers",
    jobTitleAlternatives: ["Service Technician", "Tire Changer", "Tire Shop Mechanic", "Tire Technician"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Retail"],
    salary: "$34,240",
  },
  {
    img: img3,
    jobTitle: "Title Examiners, Abstractors, & Searchers",
    jobTitleAlternatives: ["Abstractor", "Title Examiner", "Title Officer", "Title Searcher"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Finance & Insurance", "Professional, Science, & Technical"],
    salary: "$50,490",
  },
  {
    img: img3,
    jobTitle: "Tool & Die Makers",
    jobTitleAlternatives: ["Tool and Die Machinist", "Tool and Die Maker", "Tool and Fixture Specialist", "Tool Maker"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing"],
    salary: "$59,800",
  },
  {
    img: img3,
    jobTitle: "Tool Grinders, Filers, & Sharpeners",
    jobTitleAlternatives: ["Grinder", "Grinder Operator", "Saw Filer", "Tool Grinder"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing", "Service", "Wholesale/Commercial Sales"],
    salary: "$41,940",
  },
  {
    img: img3,
    jobTitle: "Tour Guides & Escorts",
    jobTitleAlternatives: ["Docent", "Historical Interpreter", "Museum Guide", "Tour Guide"],
    educationRequired: "Associate's Degree",
    jobOutlook: "Salary:",
    industry: ["Arts & Entertainment", "Administration & Support Services"],
    salary: "$34,440",
  },
  {
    img: img3,
    jobTitle: "Traffic Technicians",
    jobTitleAlternatives: ["Traffic Control Technician", "Traffic Signal Technician (TST)", "Traffic Technician", "Transportation Technician"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Government", "Professional, Science, & Technical"],
    salary: "$50,050",
  },
  {
    img: img3,
    jobTitle: "Training & Development Managers",
    jobTitleAlternatives: ["Education and Development Manager", "Education Director", "Training Director", "Training Manager"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Management", "Education", "Finance & Insurance"],
    salary: "$120,000",
  },
  {
    img: img3,
    jobTitle: "Training & Development Specialists",
    jobTitleAlternatives: [
      "Corporate Trainer",
      "Job Training Specialist",
      "Learning and Development Specialist (L and D Specialist)",
      "Training Specialist",
    ],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Health & Counseling", "Education"],
    salary: "$63,080",
  },
  {
    img: img3,
    jobTitle: "Transit & Railroad Police",
    jobTitleAlternatives: ["Patrolman", "Railroad Police", "Railroad Police Officer", "Transit Police Officer"],
    educationRequired: "bachelor's degree or some college usually needed",
    jobOutlook: "Salary:",
    industry: ["Government"],
    salary: "$69,150",
  },
  {
    img: img3,
    jobTitle: "Transportation Engineers",
    jobTitleAlternatives: ["Engineer", "Project Engineer", "Traffic Operations Engineer", "Transportation Engineer"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Government", "Construction"],
    salary: "$89,940",
  },
  {
    img: img3,
    jobTitle: "Transportation Inspectors",
    jobTitleAlternatives: ["Marine Cargo Surveyor", "Marine Surveyor", "Petroleum Inspector", "Surveyor"],
    educationRequired: "Master's Degree",
    jobOutlook: "Salary:",
    industry: ["Government", "Transportation & Storage"],
    salary: "$79,570",
  },
  {
    img: img3,
    jobTitle: "Transportation Planners",
    jobTitleAlternatives: ["Planner", "Transportation Analyst", "Transportation Planner"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Government", "Professional, Science, & Technical", "Education"],
    salary: "$88,900",
  },
  {
    img: img3,
    jobTitle: "Transportation Security Screeners",
    jobTitleAlternatives: ["Security Screener", "Transportation Security Officer (TSO)"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Government"],
    salary: "$47,710",
  },
  {
    img: img3,
    jobTitle: "Transportation Vehicle, Equipment & Systems Inspectors",
    jobTitleAlternatives: ["Carman", "Inspector", "Smog Technician", "Transit Vehicle Inspector"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Government", "Transportation & Storage"],
    salary: "$79,570",
  },
  {
    img: img3,
    jobTitle: "Transportation, Storage, & Distribution Managers",
    jobTitleAlternatives: ["Distribution Center Manager", "Fleet Manager", "Logistics Operations Manager", "Supply Chain Logistics Manager"],
    educationRequired: "Master's Degree",
    jobOutlook: "Salary:",
    industry: ["Transportation & Storage", "Wholesale/Commercial Sales", "Manufacturing", "Government"],
    salary: "$98,560",
  },
  {
    img: img3,
    jobTitle: "Travel Agents",
    jobTitleAlternatives: ["Destination Specialist", "Travel Agent", "Travel Consultant", "Travel Counselor"],
    educationRequired: "Associate's Degree",
    jobOutlook: "Salary:",
    industry: ["Administration & Support Services"],
    salary: "$46,400",
  },
  {
    img: img3,
    jobTitle: "Travel Guides",
    jobTitleAlternatives: ["Guide", "Tour Escort", "Tour Manager", "Travel Consultant"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Arts & Entertainment", "Administration & Support Services"],
    salary: "$34,440",
  },
  {
    img: img3,
    jobTitle: "Treasurers & Controllers",
    jobTitleAlternatives: ["Comptroller", "Controller", "Corporate Treasurer", "Treasurer"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Finance & Insurance", "Professional, Science, & Technical", "Management"],
    salary: "$139,790",
  },
  {
    img: img3,
    jobTitle: "Tree Trimmers & Pruners",
    jobTitleAlternatives: ["Arborist", "Groundsman", "Tree Climber", "Tree Trimmer"],
    educationRequired: "No High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Administration & Support Services"],
    salary: "$47,080",
  },
  {
    img: img3,
    jobTitle: "Tutors",
    jobTitleAlternatives: ["Academic Coach", "Academic Guidance Specialist", "Professional Tutor", "Tutor"],
    educationRequired: "bachelor's degree or some college usually needed",
    jobOutlook: "Salary:",
    industry: ["Education"],
    salary: "$36,680",
  },
  {
    img: img3,
    jobTitle: "Umpires, Referees, & Other Sports Officials",
    jobTitleAlternatives: ["Horse Show Judge", "Major League Baseball Umpire (MLB Umpire)", "Referee", "Sports Official"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Arts & Entertainment", "Government"],
    salary: "$36,010",
  },
  {
    img: img3,
    jobTitle: "Upholsterers",
    jobTitleAlternatives: ["Box Spring Upholsterer", "Upholstered Goods Crafter", "Upholsterer", "Upholstery Cutter"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing", "Service"],
    salary: "$39,600",
  },
  {
    img: img3,
    jobTitle: "Urban & Regional Planners",
    jobTitleAlternatives: ["Community Development Planner", "Planner", "Planning Consultant", "Planning Technician"],
    educationRequired: "Master's Degree",
    jobOutlook: "Salary:",
    industry: ["Government", "Professional, Science, & Technical"],
    salary: "$79,540",
  },
  {
    img: img3,
    jobTitle: "Urologists",
    jobTitleAlternatives: ["Acute Care Physician", "MD (Medical Doctor)", "Physician", "Urologist"],
    educationRequired: "Doctoral Degree",
    jobOutlook: "Salary:",
    industry: ["Health & Counseling", "Government"],
    salary: "$223,410",
  },
  {
    img: img3,
    jobTitle: "Ushers, Lobby Attendants, & Ticket Takers",
    jobTitleAlternatives: ["Lobby Attendant", "Ticket Taker", "Usher", "Visitor Services Representative"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Media & Communication", "Arts & Entertainment", "Administration & Support Services"],
    salary: "$27,650",
  },
  {
    img: img3,
    jobTitle: "Validation Engineers",
    jobTitleAlternatives: ["Quality Assurance Engineer", "Quality Engineer", "Quality Management Systems Engineer", "Supplier Quality Engineer"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Manufacturing", "Professional, Science, & Technical"],
    salary: "$96,350",
  },
  {
    img: img3,
    jobTitle: "Veterinarians",
    jobTitleAlternatives: [
      "Doctor of Veterinary Medicine (DVM)",
      "Emergency Veterinarian (Emergency Vet)",
      "Small Animal Veterinarian (Small Animal Vet)",
      "Veterinary Medicine Doctor (DVM)",
    ],
    educationRequired: "Doctoral Degree",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical"],
    salary: "$103,260",
  },
  {
    img: img3,
    jobTitle: "Veterinary Assistants & Laboratory Animal Caretakers",
    jobTitleAlternatives: ["Animal Care Provider", "Animal Caregiver", "Certified Veterinary Assistant", "Veterinarian Assistant (Vet Assistant)"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical"],
    salary: "$34,740",
  },
  {
    img: img3,
    jobTitle: "Veterinary Technologists & Technicians",
    jobTitleAlternatives: [
      "Certified Veterinary Technician (CVT)",
      "Licensed Veterinary Technician (LVT)",
      "Registered Veterinary Technician (RVT)",
      "Veterinary Technician (Vet Tech)",
    ],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical"],
    salary: "$38,240",
  },
  {
    img: img3,
    jobTitle: "Video Game Designers",
    jobTitleAlternatives: ["Design Director", "Designer", "Game Designer", "World Designer"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Media & Communication"],
    salary: "$83,240",
  },
  {
    img: img3,
    jobTitle: "Waiters & Waitresses",
    jobTitleAlternatives: ["Food Server", "Server", "Waiter", "Waitress"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Hotel & Food"],
    salary: "$29,120",
  },
  {
    img: img3,
    jobTitle: "Watch & Clock Repairers",
    jobTitleAlternatives: ["Clock Repairer", "Watch and Clock Repairer", "Watch Estimator", "Watch Technician (Watch Tech)"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Retail", "Service"],
    salary: "$48,370",
  },
  {
    img: img3,
    jobTitle: "Water & Wastewater Treatment Plant & System Operators",
    jobTitleAlternatives: [
      "Process Operator (Process Op)",
      "Wastewater Operator (WW Operator)",
      "Water Plant Operator",
      "Water Treatment Plant Operator",
    ],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Government", "Utilities"],
    salary: "$51,600",
  },
  {
    img: img3,
    jobTitle: "Water Resource Specialists",
    jobTitleAlternatives: ["Water Resources Planner"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Government"],
    salary: "$144,440",
  },
  {
    img: img3,
    jobTitle: "Water/Wastewater Engineers",
    jobTitleAlternatives: ["Consulting Engineer", "County Engineer", "Engineer", "Project Development Engineer"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Government", "Construction"],
    salary: "$89,940",
  },
  {
    img: img3,
    jobTitle: "Weatherization Installers & Technicians",
    jobTitleAlternatives: ["Field Technician", "Weatherization Installer", "Weatherization Technician", "Weatherization Worker"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Construction"],
    salary: "$44,890",
  },
  {
    img: img3,
    jobTitle: "Web & Digital Interface Designers",
    jobTitleAlternatives: ["Web Architect", "Web Design Specialist", "Web Designer", "Webmaster"],
    educationRequired: "Master's Degree",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Media & Communication"],
    salary: "$83,240",
  },
  {
    img: img3,
    jobTitle: "Web Administrators",
    jobTitleAlternatives: ["Corporate Webmaster", "Web Site Manager", "Web Technologies Administrator", "Webmaster"],
    educationRequired: "bachelor's degree or some college usually needed",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Government"],
    salary: "$98,740",
  },
  {
    img: img3,
    jobTitle: "Web Developers",
    jobTitleAlternatives: ["Web Architect", "Web Designer", "Web Developer", "Webmaster"],
    educationRequired: "bachelor's degree or certificate after high school usually needed",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Media & Communication"],
    salary: "$78,580",
  },
  {
    img: img3,
    jobTitle: "Weighers, Measurers, Checkers, & Samplers, Recordkeeping",
    jobTitleAlternatives: ["Fluid Operator", "Inventory Specialist", "Quality Assurance Inspector (QA Inspector)", "Temperature Taker"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Transportation & Storage", "Manufacturing", "Administration & Support Services", "Wholesale/Commercial Sales", "Retail"],
    salary: "$39,950",
  },
  {
    img: img3,
    jobTitle: "Welders, Cutters, Solderers, & Brazers",
    jobTitleAlternatives: ["Brazer", "Solderer", "Welder", "Wirer"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing", "Construction"],
    salary: "$47,540",
  },
  {
    img: img3,
    jobTitle: "Welding, Soldering, & Brazing Machine Setters, Operators, & Tenders",
    jobTitleAlternatives: ["Finishing Technician", "Mig Welder", "Robot Operator", "Spot Welder"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing"],
    salary: "$44,920",
  },
  {
    img: img3,
    jobTitle: "Wellhead Pumpers",
    jobTitleAlternatives: ["Field Operator", "Lease Operator", "Pumper", "Well Tender"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Mining, Oil, & Gas"],
    salary: "$63,280",
  },
  {
    img: img3,
    jobTitle: "Wholesale & Retail Buyers",
    jobTitleAlternatives: ["Buyer", "Grocery Buyer", "Procurement Specialist", "Trader"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Manufacturing", "Wholesale/Commercial Sales", "Government"],
    salary: "$67,620",
  },
  {
    img: img3,
    jobTitle: "Wind Energy Development Managers",
    jobTitleAlternatives: ["Business Development Director", "Business Development Manager", "Development Director", "Development Manager"],
    educationRequired: "bachelor's degree or associate's degree usually needed",
    jobOutlook: "Salary:",
    industry: ["Government"],
    salary: "$128,620",
  },
  {
    img: img3,
    jobTitle: "Wind Energy Engineers",
    jobTitleAlternatives: ["Engineer", "Project Engineer", "Utility Engineer", "Wind Energy Consultant"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Government", "Manufacturing"],
    salary: "$104,600",
  },
  {
    img: img3,
    jobTitle: "Wind Energy Operations Managers",
    jobTitleAlternatives: ["Site Manager", "Wind Operations Supervisor", "Wind Plant Manager", "Wind Site Manager"],
    educationRequired: "Associate's Degree",
    jobOutlook: "Salary:",
    industry: ["Government"],
    salary: "$128,620",
  },
  {
    img: img3,
    jobTitle: "Wind Turbine Service Technicians",
    jobTitleAlternatives: ["Field Service Technician", "Wind Farm Support Specialist", "Wind Technician", "Wind Turbine Technician"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Utilities", "Service", "Construction"],
    salary: "$57,320",
  },
  {
    img: img3,
    jobTitle: "Woodworking Machine Setters, Operators, & Tenders",
    jobTitleAlternatives: ["Cabinet Maker", "Machine Operator", "Sander", "Sander Operator"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Manufacturing"],
    salary: "$36,830",
  },
  {
    img: img3,
    jobTitle: "Word Processors & Typists",
    jobTitleAlternatives: ["Clerk Typist", "Keyboard Specialist", "Typist", "Word Processor"],
    educationRequired: "High School Diploma",
    jobOutlook: "Salary:",
    industry: ["Government", "Education"],
    salary: "$44,330",
  },
  {
    img: img3,
    jobTitle: "Writers & Authors",
    jobTitleAlternatives: ["Advertisement Agency Copywriter (Ad Agency Copywriter)", "Advertising Copywriter", "Copywriter", "Freelance Copywriter"],
    educationRequired: "bachelor's degree or some college usually needed",
    jobOutlook: "Salary:",
    industry: ["Professional, Science, & Technical", "Media & Communication"],
    salary: "$73,150",
  },
  {
    img: img3,
    jobTitle: "Zoologists & Wildlife Biologists",
    jobTitleAlternatives: ["Fish and Wildlife Biologist", "Fisheries Biologist", "Wildlife Biologist", "Zoologist"],
    educationRequired: "Bachelor's Degree",
    jobOutlook: "Salary:",
    industry: ["Government", "Professional, Science, & Technical"],
    salary: "$67,430",
  },
];

export default allCareers;
